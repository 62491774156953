<template>
  <v-layout pt-3>
    <v-flex xs2 md3/>
    <v-flex xs8 md6 class="mysymptoms-main-container">
      <!--    <v-flex xs8 md6>-->
      <div>
        <!--        <v-card>-->
        <v-card-title>
          <h1 class="pt-3">Feedback</h1>
        </v-card-title>
        <v-card-text>

          <p>
            It’s not been long since we launched mySymptoms Clinic, and we’re working hard on improvements. Your
            feedback helps us focus on what’s important, so please let us know your thoughts.
          </p>
          <ul>
            <li>
              Most importantly, does mySymptoms Clinic meet your expectations? If not, please let us know how it falls
              short.
            </li>
            <li>
              Are there any new features that you would like to see in mySymptoms Clinic?
            </li>
            <li>
              Or just more generally, how are you getting on using mySymptoms Clinic?
            </li>
          </ul>
          <p>
            Please email us at <a href="mailto:clinic@mysymptoms.net?subject=Feedback/New Features"
                                  target="_blank">clinic@mysymptoms.net</a> or use the form below.
          </p>
          <p>
            Thanks for your feedback!
          </p>
          <p>
            Damian, Darren & the mySymptoms team
          </p>
          <v-form ref="form" v-model="formValid">
            <v-textarea
                label="comments etc ..."
                persistant-hint
                v-model="feedbackText"
                box
                :rules="[lenRule]"
            >

            </v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              flat
              @click="submitFeedback()"
          >
            Submit
          </v-btn>
        </v-card-actions>
        <!--        </v-card>-->
      </div>
    </v-flex>
    <v-flex xs2 md3/>
  </v-layout>

</template>

<script>
import {len2048Rule} from "../utils/rules";
import MicroSurveyService from "../utils/micro-survey-service";
import Logger from "../utils/logger-utils";

export default {
  name: "ContactUs",
  data() {
    return {
      formValid: false,
      feedbackText: ''
    }
  },
  methods: {
    lenRule: len2048Rule,
    submitFeedback() {

      const quId = process.env.VUE_APP_SURVEY_QU_ID_FOR_FEEDBACK_FORM
      if (quId === undefined) {
        Logger.error("VUE_APP_SURVEY_QU_ID_FOR_FEEDBACK_FORM not defined", {})
      } else {
        if (this.formValid) {

          MicroSurveyService.submitAnswer(
              this.$store.state.username,
              quId,
              "Feedback Form",
              this.feedbackText
          ).then(() => {
            Logger.info("Feedback submitted - thank you!")
            this.feedbackText = ""
          }).catch(err => {
            Logger.error(err, {})
          })
        }
      }
    }
  }
}
</script>

<style scoped>
td {
  padding-right: 10px;
  padding-bottom: 5px;
  vertical-align: top;
}
</style>
