<template>
  <v-layout pt-3>
    <v-layout row justify-center>
      <v-dialog v-model="docListDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Updated Policy Documents</span>
          </v-card-title>
          <v-card-text>
            The following policy documents have been updated. Please read and accept to continue:
          </v-card-text>
          <v-card-text>
            <v-data-table
                :items="docsToAccept"
                :headers="headers"
                :hide-actions=true

            >
              <template v-slot:items="props">
                <tr @click="showPolicyDoc(props.item.docName)" style="cursor: pointer">
                  <td>{{ docTypeToName(props.item.docType) }}</td>
                  <td>{{ props.item.version }}</td>
                  <td>{{ formatDayForDisplay(props.item.effectiveFrom) }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="accept">I Accept</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
<!--    note the v-if here is a bit of hack to make sure the scroll bar is reset each time the dialog is opened-->
    <v-dialog
        v-if="docViewerDialog"
        v-model="docViewerDialog"
        width="750"
        persistent
    >
      <v-card>
        <v-card-text v-html="docBody">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              flat
              @click="docViewerDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment'
import AccountService from '../utils/account-service'
import Logger from '../utils/logger-utils'
import Session from '../utils/session'
import _ from 'lodash'
import {
  CookiePolicyDocType,
  policyDocService,
  PrivacyPolicyDocType,
  TermsOfUseDocType
} from '../utils/policy_doc_service'

export default {
  name: 'AcceptPolicyDocs',
  data() {
    return {
      docListDialog: true,
      docViewerDialog: false,
      docBody: "",
      headers:
          [
            {text: 'Document Name', sortable: false},
            {text: 'Version', sortable: false},
            {text: 'Effective From', sortable: false},
          ]
    }
  },
  props: {
    // docsToAccept = [
    //   {
    //     "id": 2,
    //     "docType": "terms",
    //     "docURL": "someurl",
    //     "version": 2,
    //     "effectiveFrom": "2020-06-19"
    //   }
    // ],
    docsToAccept: {
      type: Array,
      required: true
    },
    redirectPath: {
      type: String,
      required: true
    }
  },

  created() {
    const i = 1;
  },

  methods: {
    showPolicyDoc(docName) {
      const i = 1
      // this.docListDialog = false
      policyDocService.getDocContent(docName).then(res => {
        this.docBody = res.data
      })
      this.docViewerDialog = true
      // if the dialog has previously been opened, need to reset the scroll position
      // https://github.com/vuetifyjs/vuetify/issues/1687
    },
    docTypeToName(docType) {
      switch (docType) {
        case TermsOfUseDocType :
          return 'Terms of Use'
        case PrivacyPolicyDocType :
          return 'Privacy Policy'
        case CookiePolicyDocType :
          return 'Cookie Policy'
        default:
          return 'unknown'
      }
    },

    formatDayForDisplay(day) {
      return moment(day).format("ddd D MMM YYYY")

    },

    accept() {
      const now = moment().toISOString()
      this.dialog = false
      const acceptedDocs = _.map(this.docsToAccept, x => {
        const ret = {
          policyDocId: x.id,
          username: this.$store.state.username,
          acceptedAt: now,
        }
        return ret
      })
      AccountService.acceptPolicyDocs(this.$store.state.username, acceptedDocs).then(() => {
        this.$router.push({path: this.redirectPath})
      }).catch(err => {
        Logger.error(err)
        Session.signOut()
        this.$router.push({path: '/'})
      })
    },

    cancel() {
      Session.signOut()
    }
  }
};
</script>
