<template>
  <div class="mysymptoms-main-container">
    <v-layout>
      <v-flex xs8>
        <h1 class='pt-3'>Patient Diary: {{ userDisplayName }}</h1>
      </v-flex>
    </v-layout>
    <div>
      <v-tabs
          v-if="true"
          v-model="activeTab"
          @change="onTabChange"
      >
        <v-tab :key="1">Diary V2 <span class="beta_badge">Beta</span></v-tab>
        <v-tab :key="2">Diary</v-tab>
        <v-tab :key="3">Charts</v-tab>
        <v-tabs-items v-model="activeTab">
          <v-tab-item :key="1">
            <DiaryV2Component
                ref="diary_v2"
                v-bind:patient-username.sync='patientUsername'
            >
            </DiaryV2Component>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-layout class="pt-4">
              <v-flex xs4>
                <v-autocomplete
                    v-model="displayTimezoneOffset"
                    :items="allTimezoneOffsets"
                    label="Display Timezone"
                    @change="onTimezoneUpdate"
                >
                  <v-tooltip slot="append-outer" bottom>
                    <span>Select the patient's timezone. Note: This currently defaults to the timezone of the clinician's browser, but a future version of the software will automatically set it to the patient's timezone.</span>
                    <v-icon slot="activator">help</v-icon>
                  </v-tooltip>
                </v-autocomplete>
              </v-flex>
              <v-flex xs4>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                    class="pt-3"
                    v-model="searchText"
                    append-icon="search"
                    label="Search, e.g. cheese,milk,nausea"
                    hint="Separate multiple items with a comma"
                    persistent-hint
                    single-line
                    @blur="addItemToSearchHistory"
                >
                  <v-menu
                      slot="prepend"
                      offset-y
                      v-model="historyMenuOpen"
                      :close-on-content-click="false"
                  >
                    <v-icon slot="activator">{{ historyMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
                    <v-list>
                      <v-list-tile
                          v-for="(item, i) in getSearchHistory"
                          :key="i"
                      >
                        <v-list-tile-title style="cursor: pointer" @click="onSearchHistoryItemSelected(i)">
                          {{
                            item
                          }}
                        </v-list-tile-title>
                        <v-icon @click="deleteSearchItem(i)"
                                class="align-right pl-3">delete
                        </v-icon>
                      </v-list-tile>
                      <v-list-tile v-if="searchHistoryLength === 0">
                        <v-list-tile-title>
                          No search history
                        </v-list-tile-title>

                      </v-list-tile>
                    </v-list>
                  </v-menu>
                  <!--          cycling through the search results - work in progress -->
                  <!--          <span slot="append-outer">1/5</span>-->
                  <!--          <v-icon slot="append-outer">chevron_left</v-icon>-->
                  <!--          <v-icon slot="append-outer">chevron_right</v-icon>-->
                  <v-tooltip slot="append-outer" bottom>
                    <span>Tip: use a comma between words to search for multiple items.</span>
                    <v-icon slot="activator">help</v-icon>
                  </v-tooltip>
                </v-text-field>
              </v-flex>
            </v-layout>
            <table v-for="(dayData, day) in patientData" v-bind:key="day" class="mysymptoms-patient-diary">
              <thead>
              <tr>
                <th colspan="3">{{ formatDayForDisplay(day, displayTimezoneOffset) }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="diaryEvent in dayData" v-bind:key="diaryEvent.id" class="<%=rowClass%>">
                <td class="mysymptoms-diary-time">{{ formatTime(diaryEvent.date) }}</td>
                <td class="mysymptoms-diary-event">
                  <!--                    get the right icon for this entry , note passing the symptom detail is a bit of a hack
                                          It's only needed for moods, since we have different mood icons for different mood levels-->
                  <img alt="event type" :src="getIconName(diaryEvent.entry_name, diaryEvent.detail)" width="20px"
                       height="20px"/>
                  <!--          <span style="padding-left: 5px" v-html="formatEntryName(diaryEvent.entry_name)"></span>-->
                  <span style="padding-left: 5px"
                        v-html="$options.filters.highlightSearchResults(diaryEvent.entry_name, diaryEvent.entry_name, searchText)">
                  </span>
                </td>
                <td class="mysymptoms-diary-content"
                    v-html="$options.filters.highlightSearchResults(diaryEvent.entry_name, diaryEvent.entry_description,  searchText)">
                </td>
              </tr>
              </tbody>
            </table>
            <p v-if="numResultsLoaded===0">No diary entries</p>
            <p class="mt-4 mb-0" v-else-if="!isMore">
              No more diary entries.
            </p>

            <!-- only show the Load More button after we've finished loading data, and only enable it if there is more data -->
            <v-btn class="mt-4" v-if="loadingCompleted && isMore" @click="loadDiaryEntries(true)"
                   color="primary">
              Load More
            </v-btn>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-layout row class="pt-3">
              <v-flex xs8 text-xs-center>
                <v-btn small color="primary" @click="setChartFromDate(1)">1 month</v-btn>
                <v-btn small color="primary" @click="setChartFromDate(3)">3 month</v-btn>
                <v-btn small color="primary" @click="setChartFromDate(6)">6 month</v-btn>
              </v-flex>
            </v-layout>
            <!--            To and From Date pickers-->
            <!--            set v-if=true for debugging -->
            <v-layout v-if="isDev()" class="pt-2">
              <!--              From Date Picker -->
              <v-flex xs8 text-xs-center>
                <v-menu
                    v-model="showFromDatePickerMenu"
                    :close-on-content-click="false"
                    full-width
                    max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="fromDateFormatted"
                        label="From"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="fromDate"
                      @change="showFromDatePickerMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <!--              To Date picker -->
              <v-flex xs2>
                <v-menu
                    v-model="showToDatePickerMenu"
                    :close-on-content-click="false"
                    full-width
                    max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="toDateFormatted"
                        label="To"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="toDate"
                      @change="showToDatePickerMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>

            <!--            iterate over the datasets for each outcome type -->
            <v-layout v-if="allOutcomeDatasets.length === 0">
              <p>No outcomes in date range</p>
            </v-layout>
            <v-layout v-else v-for="(outcome, i) in allOutcomeDatasets" :key="i">
              <v-flex xs8 class="pb-3">
                <ScatterChart
                    :chartData="outcome.dataset"
                    :options="outcome.options"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>
    </div>

    <v-layout row justify-center>
      <!--/*      <v-dialog v-model="showCookiePopup" persistent max-width="290" style="color: red; background-color: rgba(0, 0, 0, 0.5)">*/-->
      <v-dialog v-model="showNewDiaryPopup" persistent max-width="500">
        <!--        <p style="color: red">Hello</p>-->
        <!--        <v-btn flat @click="showCookiePopup=false">I Accept</v-btn>-->
        <v-card style="background-color: rgba(0, 0, 0, 0.5); color: white">
          <v-card-title class="headline">New Diary and Charts</v-card-title>
          <v-card-text>
            <p>
              We are trialling a new version of the Diary and Charts. Please try it and let us know what you think on our
              <router-link
                  :to="{name: 'feedback'}"
                  target="_blank"
              >
                feedback page
              </router-link>.
            </p>
            <img alt="mySymptoms Logo" style="padding-bottom: 5px; padding-top: 5px; padding-right: 5px"
                 src="../assets/diary_v2_tab.jpg" height="124"/>
            <p>
              The old versions are still available on other tabs if you prefer to stick to what you know while we finalise the new version.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="newDiaryPopupOK" style="color: white">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import PatientDiaryService from '../utils/patient-diary-service'
import Logger from '../utils/logger-utils'
import _ from 'lodash'
import moment from 'moment-timezone'
import Loader from '../utils/loader-utils'
import VError from 'verror'
import RealNameUtils from '../utils/real-name-helpers'
import TimezoneUtils from '../utils/timezone-utils'
import {defineComponent} from "@vue/composition-api";
import {ScatterChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import DiaryV2Component from '../components/DiaryV2Component'
import {DateTime} from "luxon"
import UIOneOffEventService from '../utils/ui-one-off-event-service.js'

const numResultsDefault = 25
const localStorageHistoryKey = 'searchHistory'
const defaultChartWindow = 1 // months

/**
 // * converts  moment-timezone date of a diary Event object to sortable string containing just the day e.g.  2018-09-01
 * gets the start of for a given moment date
 * @param {Object} diaryEvent - containing a date field as a moment date
 * @returns {Object} moment date
 */
function getStartOfDay(diaryEvent) {
  const startOfDay = diaryEvent.date.clone().startOf('day').format(); // note need to clone since startOf mutates
  return startOfDay
}

Chart.register(...registerables);

const showNewDiaryPopupEvent = 'showNewDiaryPopupEvent'

export default defineComponent({
  name: "PatientDiary",
  props: ['patientUsername', 'numResultsProp'],
  components: {
    ScatterChart,
    DiaryV2Component
  },

  data() {
    return {
      showNewDiaryPopup: false,
      showOverlay: true,
      overlaydisplay: 'block',
      active: null,
      text: "blah blah",
      activeTab: 0,
      /**
       *  PatientData has the format ...
       * {
       *  "2020-07-12": [
       *      {
       *          "date": "2020-07-12T04:00:18.000Z",
       *          "dateString": "Sun 12 Jul 2020",
       *          "entry_type": "event",
       *          "entry_name": "Drink",
       *          "entry_description": "Notes: 11th 5:00",
       *          "detail": {
       *              "id": "9e3b5715-7a56-4405-aef5-d8396f380dcb",
       *              "username": "dph201",
       *              "lastModified": "2020-07-13T22:20:19.833+0000",
       *              "eventTime": "2020-07-12T04:00:18.000+0000",
       *              "endTime": null,
       *              "type": "Drink",
       *              "content": [],
       *              "notes": "11th 5:00",
       *              "intensity": -1
       *          }
       *      },
       *  ]
       * "2020-07-11": [
       * ... etc.
       *      ]
       * }
       */
      patientData: {},
      numResultsLoaded: 0,
      loadingCompleted: false,
      displayTimezoneOffset: [], // "in offset format, e.g. ["-5:00","EST"], note it's an array rather than object to
      // work with v-autocomplete
      isMore: false,
      searchText: '',
      searchHistory: [], // array of previous search strings
      historyMenuOpen: false,
      // matchCounter: 0
      matches: [],
      patientRealName: '',
      allOutcomeDatasets: [],
      // a basic set of options from this copy for options for each outcome set
      baseChartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 2,
        plugins: {
          title: {
            display: true,
            text: '', // this is filled in on a per-chart basic programmatically below
            font: {
              size: 14
            }
          },
          legend: {
            display: false
          }
        },
        legend: {
          display: false
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                // day: 'DD MMM'
                day: 'd MMM'
              }
            },
            // min: moment().subtract(defaultChartWindow, 'month'),
            // max: moment()
            min: DateTime.now().minus({months: defaultChartWindow}),
            max: DateTime.now(),
          },
          y: {
            title: {
              display: true,
              text: 'Intensity'
            },
            min: 0,
            max: 10,
            ticks: {
              stepSize: 1
            }
          }
        }
      },
      showToDatePickerMenu: false,
      showFromDatePickerMenu: false,
      fromDate: moment().subtract(defaultChartWindow, 'month').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    }
  }
  ,
  computed: {
    toDateFormatted() {
      return this.toDate ? moment(this.toDate).format('MMM Do YYYY') : ''
    },

    fromDateFormatted() {
      return this.fromDate ? moment(this.fromDate).format('MMM Do YYYY') : ''
    },

    toDateMom() {
      return moment(this.toDate)
    },

    fromDateMom() {
      return moment(this.fromDate)
    },

    // searchResults() {
    //   const res = _.keys(patientData, key => {
    //     const recs = patientData[key]  // array or results for this day
    //     _.map(recs, rec => {
    //
    //     })
    //   })
    //   return res
    // },

    getSearchHistory() {
      return this.searchHistory
    },

    searchHistoryLength() {
      const len = this.searchHistory.length
      return len
    },

    userDisplayName() {
      const realName = this.patientRealName
      const displayName = realName === "" ? this.patientUsername : realName
      return displayName
    },

    numResults() {
      const res = this.numResultsProp ? this.numResultsProp : numResultsDefault
      return res
    },

    allTimezoneOffsets() {
      const all = TimezoneUtils.allTimezoneOffsets();
      const offsetsForDisplay = _.map(all, x => {
        return {
          text: `${x.offset}  ${x.name}`,
          value: [x.offset, x.name]
        }
      })
      return offsetsForDisplay
    },
  },

  filters: {

    /**
     * highlightSearchResults - searches through a string for a search terms. Any occurrences of the search
     * terms are wrapped in a <span> with highlighting.
     * The search is case-insensitive and matches all occurrences. White space is trimmed from the search terms
     *
     * eventType {string} - e.g. Lunch, Drink, Symptom, etc ... used to determine the color to highlight.
     *                      Outcome types ('Symptom','Bowel movement','Energy','Sleep quality') are coloured differently to input type
     * words {string} - the words in which to search
     * searchTerm {string} - a comma separated lists of words to search for
     */
    highlightSearchResults(eventType, words, searchTerms) {
      // highlightSearchResults(words, searchTerms) {
      let ret = ''

      // highlight outcome events differently from input events
      const highlightColour = _.includes(['Symptom', 'Bowel movement', 'Energy', 'Sleep quality'], eventType) ? "rgba(255,60,0,0.31)" : "rgba(162,255,0,0.57)"

      if (searchTerms !== '') {

        // escape special regexp chars ..
        const escapedTerms = searchTerms.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')

        // parse the search terms and trim white space
        const searchTermsArray = escapedTerms.split(',').map(x => x.trim())

        // create an 'or' regex term
        const regEx = searchTermsArray.join('|')


        const re = new RegExp(regEx, "gi")
        ret = words.replace(re, (match) => `<span style="background-color:${highlightColour}">${match}</span>`
        )
      } else {
        ret = words
      }
      return ret
    }
  },

  methods: {
    newDiaryPopupOK() {
      Loader.start()
      UIOneOffEventService.setUIEventHappenedForUser(this.$store.state.username, showNewDiaryPopupEvent).then(() => {
        Loader.stop()
        this.showNewDiaryPopup = false
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to update event status for ${this.$store.state.username}, event: ${showNewDiaryPopupEvent}`), {})
      })
    },

    onTabChange(arg) {
      // Logger.debug("tab changed: " + arg)
      let tabName
      switch (arg) {
        case 0:
          tabName = 'Diary V2'
          this.$refs.diary_v2.loadData()
          break
        case 1:
          tabName = 'Diary'
          this.loadDiaryAndChartData()
          break
        case 2:
          tabName = 'Charts'
          this.loadDiaryAndChartData()
          break
        default:
          tabName = 'Unknown'
      }
      this.$gtag.event('tab_change', {event_label: `${arg}, ${tabName}`})
    },

    isDev() {
      const isDev = process.env.VUE_APP_MYSYMPTOMS_MODE === 'development'
      return isDev
    },
    setChartFromDate(startOffset) {
      this.fromDate = this.toDateMom.clone().subtract(startOffset, 'month').format('YYYY-MM-DD')
    },

    loadChartOutcomesWithLoader() {
      Loader.start()
      this.loadChartOutcomes().then(() => {
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get chart outcomes for ${this.patientUsername}`), {})
      })
    },

    // for the symptom trend charts
    loadChartOutcomes() {
      // Logger.debug("loading outcomes: " + this.fromDateMom.format() + ", " + this.toDateMom.format())

      // Loader.start()
      const prom = PatientDiaryService.getOutcomesForCharts(
          this.$store.state.username,
          this.patientUsername,
          this.fromDateMom,
          this.toDateMom).then(res => {
            // Logger.debug("processing outcomes" + JSON.stringify(res))
            // res is an object with a property for each outcome type ... which contains an array for outcomes for that type. E.g
            // {
            //   "Nausea": [
            //     {
            //       "type": "Nausea",
            //       "start_time": "2022-02-21T18:57:19.000Z",
            //       "end_time": "2022-02-21T18:57:19.000Z",
            //       "intensity": 2
            //     }
            //   ],
            //   "Eczema": [
            //     {
            //       "type": "Eczema",
            //       "start_time": "2022-02-21T18:57:19.000Z",
            //       "end_time": "2022-02-21T18:57:19.000Z",
            //       "intensity": 6
            //     }
            //   ],
            // }
            // etc
            // loop over each property in the result object (i.e. outcome type)
            this.allOutcomeDatasets = [] // delete any previous data
            for (const outcomeType in res) {
              // for each outcome type we now have an array of occurrences of that type, create a vue-chart-3 dataset
              // for those occurrences, and add it to our array of datasets
              const occurrences = res[outcomeType]
              const chartDataset = this.createChartDataSet(outcomeType, occurrences)
              const chartOptions = JSON.parse(JSON.stringify(this.baseChartOptions))
              chartOptions.plugins.title.text = outcomeType
              this.allOutcomeDatasets.push({
                    dataset: chartDataset,
                    options: chartOptions
                  }
              )
            }
            // Loader.stop()
          }
      )

      return prom
    },

    createChartDataSet(outcomeType, occurrence) {
      return {
        datasets: [
          {
            // label: outcomeType,
            backgroundColor: '#f87979',
            data: occurrence.map(occurrence => {
              const temp = DateTime.fromISO(occurrence.start_time.toISOString())
              return {
                x: DateTime.fromISO(occurrence.start_time.toISOString()),
                y: occurrence.intensity
              }
            })
          }
        ]
      }
    },

    onSearchHistoryItemSelected(idx) {
      this.searchText = this.searchHistory[idx]
      this.historyMenuOpen = false
    }
    ,

    // add the text to the search history. The text is added to the top. If the item is already in the search
    // history it is deleted from its current position and added again at the first position.
    // The length of the history is limited to 10
    addItemToSearchHistory() {
      const searchTerm = this.searchText
      if (searchTerm !== "" && searchTerm !== null) {
        // if this item already exists in the search history, remove it
        const idx = this.searchHistory.indexOf(searchTerm)
        if (idx > -1) {
          this.searchHistory.splice(idx, 1)
        }

        // add item to top of history
        this.searchHistory.unshift(searchTerm)

        // limit the number of items
        this.searchHistory = this.searchHistory.slice(0, 10)

        // write to storage so other tabs get the changes, and this tab reloads with the same values
        localStorage.setItem(localStorageHistoryKey, JSON.stringify(this.searchHistory))

        // register this as an event with Google Analytics
        const params = {event_label: searchTerm}
        this.$gtag.event('search', params)
      }
    }
    ,

    deleteSearchItem(idx) {
      this.searchHistory.splice(idx, 1)
      // write to storage so other tabs get the changes, and this tab reloads with the same values
      localStorage.setItem(localStorageHistoryKey, JSON.stringify(this.searchHistory))
    }
    ,

    getIconName(entryName, detail) {
      let image = '';

      // Outcomes: 'Symptom','Bowel movement','Energy','Sleep quality'
      // Events: 'Dinner','Snack','Lunch','Breakfast','Supplements','Drink','Medication','Stress','Period','Other'
      if (entryName === 'Symptom') {
        image = 'warning'
      } else if (entryName === 'Bowel Movement') {
        image = 'toilet'
      } else if (entryName === 'Energy') {
        image = 'battery'
      } else if (entryName === 'Sleep Quality') {
        image = 'sleep'
      } else if (entryName === 'Dinner' || entryName === 'Snack' || entryName === 'Lunch' || entryName === 'Breakfast') {
        image = 'food'
      } else if (entryName === 'Supplements') {
        image = 'vitamins'
      } else if (entryName === 'Drink') {
        image = 'drinks'
      } else if (entryName === 'Medication') {
        image = 'medicine'
      } else if (entryName === 'Stress') {
        image = 'cloud'
      } else if (entryName === 'Exercise') {
        image = 'runner'
      } else if (entryName === 'Environment') {
        image = 'globe'
      } else if (entryName === 'Period') {
        image = 'period'
      } else if (entryName === 'Mood') {
        // to get the right mood icon, need to map the intensity onto a 1 - 5 scale e.g. 1 -> 1, 3 -> 2, 5 -> 3, 7 -> 4
        // 9 -> 5
        const iconNum = Math.ceil(detail.symptoms[0].intensity / 2)

        image = 'mood' + iconNum
      } else if (entryName === 'Other') {
        image = 'other'
      } else {
        Logger.error(new VError(`unknown event type when selecting icon: ${entryName}.`), {})
        // this will result in a 'missing image' icon in the browser, hopefully alerting someone to report it to support
        // Chose not to log as an error as might result in a barrage of snack-bars
        image = 'not_found'
      }

      if (image !== '' && image !== 'not_found') {
        // https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
        return require(`../assets/diary/${image}.png`)
      }
    }
    ,

    /**
     * Formats the time component of the moment-timezone date "HH:mm"
     *
     * @param {Moment} time - a moment-timezone date time
     * @returns {string}  - in the format of "HH:mm"
     */
    formatTime(time) {
      // const ret = moment(dateInSecs).format("HH:mm")
      const ret = time.format("HH:mm")
      return ret
    }
    ,

    /**
     * Takes an iso string representation of a day e.g. '2020-07-01' and converts into Formats the day component of the
     * moment-timezone date to 'Thu 8 Nov 2018'
     *
     * @param {string} day - a moment-timezone date
     * @param {string} timezoneOffset - timezone in which to display the date in the format e.g. "-4:00 EDT"
     * @returns {string} - the date in the format of ddd D MMM YYYY, e.g. Thu 8 Nov 2018
     */
    formatDayForDisplay(day, timezoneOffset) {
      const dayMom = moment.parseZone(day)
      const dayMom2 = dayMom.clone().utcOffset(timezoneOffset, false)
      const ret = dayMom2.format("ddd D MMM YYYY")
      return ret

    }
    ,

    getTimezoneCookieKey(username) {
      return "timezone_" + username
    }
    ,

    onTimezoneUpdate() {
      const cookieKey = this.getTimezoneCookieKey(this.patientUsername)
      localStorage.setItem(cookieKey, this.displayTimezoneOffset)
      this.loadDiaryEntries(false)
    }
    ,
    /**
     * Goes to the server for the next bunch of diary entries
     *
     * @param {boolean} more - if true, gets the next bunch of dates, if false, just reloads the current events
     * (in case of the display timezone change)
     *
     * returns a promise
     */
    loadDiaryEntries(more) {

      // if we haven't loaded any events yet, or we're doing a refresh, get the next bunch of events going backwards
      // from today, else go backwards from the day of the last patientData day
      // note that the patientData organises the diary such that each object key is a calendar day, the value
      // for that key is an array of diary entries for that day
      const diaryDates = Object.keys(this.patientData)
      const eventTimeOffset = (diaryDates.length === 0 || !more)
          ? moment.tz().utcOffset(this.displayTimezoneOffset[0]) // now
          // : moment.tz(diaryDates[diaryDates.length - 1]).utcOffset(this.displayTimezoneOffset[0])
          : moment.parseZone(diaryDates[diaryDates.length - 1]).utcOffset(this.displayTimezoneOffset[0])

      // if we want to display numResults, we need to ask for this number from each api endpoint (outcomes and events),
      // merge the two (time interleaved) and then take the first numResults.
      // this is to ensure that in the time period the user sees there are no entries missing from one of the streams.
      // When the user clicks the 'Load More' button, we repeat this but specify the timeOffset as being the time of
      // last diary entry displayed from the previous batch
      // Note also that numResults is a minimum, the actual number is rounded up, so we always display full days.
      const prom = PatientDiaryService.getDiary(
          this.$store.state.username,
          this.patientUsername,
          this.numResults,
          eventTimeOffset)
          .then(res => {

            const diaryEvents = res.data

            if (diaryEvents.length > 0) {

              this.numResultsLoaded = this.numResultsLoaded + diaryEvents.length

              // this triggers the Load More to be disabled if there are no more
              this.isMore = res.isMore

              // group the events by day (we assume they are sorted by day)
              const groupedByDay = _.groupBy(diaryEvents, getStartOfDay)

              // sort the events within each day by ascending time
              const entries = Object.entries(groupedByDay)
              const sortedByTimeArray = entries.map(entry => {
                const day = entry[0]
                const events = entry[1]
                const sortedEvents = events.sort((a, b) => {
                  const diff = a.date.clone().diff(b.date)
                  return diff
                }) // sort ascending by date
                return [day, sortedEvents]

              })

              // convert the array back to an object https://stackoverflow.com/questions/49807489/reversing-an-object-entries-conversion
              const sortedByTimeObj = sortedByTimeArray.reduce((obj, [k, v]) => {
                obj[k] = v
                return obj
              }, {})

              // merge the new data with the existing
              this.patientData = more ? Object.assign({}, this.patientData, sortedByTimeObj) : sortedByTimeObj
            } else {
              // don't need to do anything
            }

            this.$router.push({path: this.$route.path, query: {numResults: `${this.numResultsLoaded}`}})

            this.loadingCompleted = true
          })
      return prom
    },

    loadDiaryAndChartData() {
      // for ths charts
      Loader.start()
      const prom1 = this.loadChartOutcomes()

      const prom2 = this.loadDiaryEntries(false)

      Promise.all([prom1, prom2]).then(() => {
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get diary entries or chart outcomes for ${this.patientUsername}`), {})
      })
    },

  },

  /**
   * tz {string} - e.g. 'America/New York'
   * returns {string} e.g. '-5:00'
   */
  timezoneTzToOffset(tz) {
    const ret = moment.tz(tz).format('Z z')
    return ret
  },


  getRandomInt() {
    return Math.floor(Math.random() * (50 - 5 + 1)) + 5
  },

  mounted() {
    try {

      // timezone is stored in a cookie as a string in the format "-5:00,EST"
      const cookieKey = this.getTimezoneCookieKey(this.patientUsername)
      const retrievedTimezone = localStorage.getItem(cookieKey)

      // if it's set, split it into an array for use, else use the timezone from the local machine
      this.displayTimezoneOffset = retrievedTimezone ? _.split(retrievedTimezone, ',') : TimezoneUtils.currentOffset()

      if (typeof this.patientUsername === 'undefined') {
        Logger.error(new VError("patientUsername undefined"), {})
      } else {


        // see if the patient's real name is set in the database, if not, look to see if we're storing it in local
        // storage
        PatientDiaryService.getPatientInfo(this.$store.state.username, this.patientUsername).then(res => {
          if (res.data.hasOwnProperty('firstName') && res.data.firstName !== null) {
            // assume if firstName is set, so is lastName ... enforced at the database level
            this.patientRealName = res.data.firstName + " " + res.data.lastName
          } else {
            this.patientRealName = RealNameUtils.getRealNameFromLocalStorage(this.patientUsername);
          }
        }).catch(err => {
          Logger.error(new VError(err, `failed to get patient info for ${this.$store.state.username} / ${this.patientUsername}`), {})
          this.patientRealName = "<error>"
        })

        this.$refs.diary_v2.loadData()
      }

      // check to see if the user has already see the popup announcing the new diary
      UIOneOffEventService.hasUIEventHappenedForUser(this.$store.state.username, showNewDiaryPopupEvent).then( res => {
        this.showNewDiaryPopup = ! res.data
      })

      // initialise the search history from local storage if it exists
      const searchHistory = localStorage.getItem(localStorageHistoryKey)
      if (searchHistory !== null) {
        const searchHistoryParsed = JSON.parse(searchHistory)
        this.searchHistory = searchHistoryParsed
      }

      // listen for search history updates from other tabs
      window.addEventListener('storage', (e) => {
        if (e.key === localStorageHistoryKey) {
          const newHistory = JSON.parse(e.newValue)
          this.searchHistory = newHistory
        }
      })
    } catch (err) {
      Logger.error(new VError(err), {})
    }
  },
  watch: {
    fromDate() {
      this.baseChartOptions.scales.x.min = DateTime.fromISO(this.fromDateMom.toISOString())
      this.loadChartOutcomesWithLoader()
    },

    toDate() {
      this.baseChartOptions.scales.x.max = DateTime.fromISO(this.toDateMom.toISOString())
      // Logger.debug("toDate: " + this.toDateMom.format() + ", fromDate: " + this.fromDateMom.format())
      if (this.toDateMom.isBefore(this.fromDateMom)) {
        this.fromDate = this.toDateMom.clone().subtract(defaultChartWindow, 'month').format('YYYY-MM-DD')
        // Logger.debug("fromDate: " + this.fromDate)
      }
      this.loadChartOutcomesWithLoader()
    }
  }
})
</script>

<style scoped>


#overlay {
  position: fixed;
  /*display: block;*/
  display: none;
  /*display: var(--overlaydisplay);*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  cursor: pointer;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  /*transform: translate(-50%,-50%);*/
  /*-ms-transform: translate(-50%,-50%);*/
}

/* Patient diary table */
.mysymptoms-patient-diary {
  width: 100%;
  margin-top: 20px;
}

.mysymptoms-patient-diary > thead > tr > th {
  padding: 5px;
  background-color: var(--v-primary-lighten1);
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
}

.mysymptoms-patient-diary > tbody > tr {
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
}

.mysymptoms-patient-diary > tbody > tr > td {
  padding: 5px;
}

.mysymptoms-diary-time {
  width: 10%;
  vertical-align: top;
}

.mysymptoms-diary-event {
  width: 20%;
  vertical-align: top;
}

.mysymptoms-diary-content {
  width: 70%;
  vertical-align: top;
}

.beta_badge {
  border-radius: 5px;
  font-weight: bold;
  font-size: 70%;
  padding: 3px;
  margin-left: 5px;
  margin-bottom: 10px;
  background-color: red;
  color: white;
}
</style>

<style lang="stylus">
$color-pack = false

@import '~vuetify/src/stylus/main'
</style>
