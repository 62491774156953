export default {
  setupPaddle() {
    //https://sandbox-checkout.paddle.com/api/
    if (process.env.VUE_APP_PADDLE_USE_SANDBOX === "true") {
      Paddle.Environment.set('sandbox')
    }

  // const ret = process.env.VUE_APP_BETA_TRIAL;
    const paddleVendorId = Number(process.env.VUE_APP_PADDLE_VENDOR_ID)

    Paddle.Setup({vendor: paddleVendorId})
  }
}
