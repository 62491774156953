import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import SignIn from './views/SignIn.vue'
import TermsOfUse from './views/TermsOfUse.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import CookiePolicy from './views/CookiePolicy.vue'
import ResetPassword from "./views/ResetPassword.vue"
import ResetPasswordRequest from './views/ResetPasswordRequest.vue'
import Session from './utils/session'
import NotFound from './views/NotFound.vue'
import PatientManagement from './views/PatientManagement.vue'
import PatientInvitations from './views/PatientInvitations.vue'
import PatientDiary from './views/PatientDiary.vue'
// import PatientDiaryV2 from './views/PatientDiaryV2.vue'
import Clinician from './views/Clinician.vue'
import ClinicianRegistration from './views/ClinicianRegistration.vue'
import VerificationEmailNotification from './views/VerificationEmailNotification.vue'
import ResendVerificationEmail from './views/ResendVerificationEmail.vue'
import VerifyEmail from './views/VerifyEmail.vue'
import NotifyPatients from './views/NotifyPatients.vue'
import AccessError from './views/AccessError.vue'
import Support from './views/Support.vue'
import About from './views/About.vue'
import ClinicianRegistrationBetaInfo from './views/ClinicianRegistrationBetaInfo.vue'
import ResetPasswordConfirmation from './views/ResetPasswordConfirmation.vue'
import AcceptPolicyDocs from "./views/AcceptPolicyDocs";
import ClinicianManagement from "./views/ClinicianManagement";
import Test from './views/Test.vue'
import Test2 from './views/Test2.vue'
import Test3 from './views/Test3.vue'
import Test4 from './views/Test4.vue'
import Test5 from './views/Test5.vue'
import News from './views/News.vue'
import Feedback from './views/Feedback.vue'
import Plans from "@/views/Plans";
import MySubscription from "@/views/MySubscription";
import {store} from './store/store'
// on ice
// import NewsItem from './views/NewsItem.vue'

Vue.use(VueRouter);

const ifNotSignedIn = (to, from, next) => {
  if (!Session.isSignedIn()) {
    next()
  } else {
    next({name: 'access_error'})
  }
}

const ifSignedIn = (to, from, next) => {
  if (Session.isSignedIn()) {
    next()
  } else {
    // if the user was trying to access a protected resouce, store the name as a query param so we can redirect
    // after signing in
    next({name: 'signin', query: {redirect: to.path}})
  }
}

// noinspection JSUnresolvedVariable
const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: {name: 'patient_management'},
      beforeEnter: ifSignedIn
    },
    {
      path: '/signin',
      name: 'signin',
      component: SignIn,
      beforeEnter: ifNotSignedIn
    },
    {
      // called when the user clicks on the forgotten password link on the sign in screen. Sends an email to the user
      // with a link in to reset the password
      path: '/reset_password_request',
      name: 'reset_password_request',
      component: ResetPasswordRequest,
      beforeEnter: ifNotSignedIn
    },
    {
      // called when a clinician user clicks on the link in the email sent in response to /reset_password_request
      path: '/resetpassword/clinician/:reset_token',
      name: 'reset_password_clinician',
      component: ResetPassword,
      beforeEnter: ifNotSignedIn,
      props: (route) => ({
        mode: 'public',
        resetToken: route.params.reset_token
      })
    },
    {
      // called when a patient clicks on the link in the email sent in response to /reset_password_request
      path: '/resetpassword/patient/:reset_token',
      name: 'reset_password_patient',
      component: ResetPassword,
      beforeEnter: ifNotSignedIn,
      meta: {patientReq: true},  // temporary fix so we can disable menus etc when the patient resets a password
      props: (route) => ({
        mode: 'public',
        resetToken: route.params.reset_token,
      })
    },
    {
      // called when the user clicks on the reset password option on the User menu when logged in
      path: '/resetpassword',
      name: 'reset_password_private',
      component: ResetPassword,
      beforeEnter: ifSignedIn,
      props: () => ({
        mode: 'private',
        resetToken: null
      })
    },
    {
      // called when the user clicks on the reset password option on the User menu when logged in
      path: '/reset_password_confirmation',
      name: 'reset_password_confirmation',
      component: ResetPasswordConfirmation,
      beforeEnter: ifNotSignedIn,
      meta: {patientReq: true},  // temporary fix so we can disable menus etc when the patient resets a password
    },
    // called by clinician users when they want to look at their own patients
    {
      path: '/patient_management',
      name: 'patient_management',
      component: PatientManagement,
      beforeEnter: ifSignedIn,
      props: (route) => {
        const clinicianUsername = store.state.username
        return {clinicianUsername: clinicianUsername}
      },
    },
    // called by admin users when they want to look at patients for another clinician
    {
      path: '/patient_management/:clinicianUsername',
      name: 'patient_management',
      component: PatientManagement,
      beforeEnter: ifSignedIn,
      props: (route) => {
        return {clinicianUsername: route.params.clinicianUsername}
      },
    },
    {
      path: '/clinician_management',
      name: 'clinician_management',
      component: ClinicianManagement,
      beforeEnter: ifSignedIn
    },
    {
      path: '/patient_invitations',
      name: 'patient_invitations',
      component: PatientInvitations, // TBC
      beforeEnter: ifSignedIn
    },
    {
      path: '/invite_patient',
      name: 'invite_patient',
      component: NotFound, // TBC
      beforeEnter: ifSignedIn
    },
    {
      path: '/patient/:patientUsername',
      name: 'patient_diary',
      component: PatientDiary,
      beforeEnter: ifSignedIn,
      // props: true
      // Inject  props based on route.query values (our query parameters!)
      props: (route) => ({
        patientUsername: route.params.patientUsername,
        numResultsProp: route.query.numResults
      })
    },
    // {
    //   path: '/patient_v2/:patientUsername',
    //   name: 'patient_diary_v2',
    //   component: PatientDiaryV2,
    //   beforeEnter: ifSignedIn,
    //   // props: true
    //   // Inject  props based on route.query values (our query parameters!)
    //   props: (route) => ({
    //     patientUsername: route.params.patientUsername,
    //     numResultsProp: route.query.numResults
    //   })
    // },
    // from clicking the register link on the signin page
    {
      path: '/clinician/create',
      name: 'clinician_create',
      component: ClinicianRegistration,
      beforeEnter: ifNotSignedIn
    },
    // from the invitation email link sent to clinicians from the patient
    {
      path: '/clinician/create/:email/:token',
      name: 'clinician_registration_from_invite',
      component: ClinicianRegistration,
      beforeEnter: ifNotSignedIn,
      props: (route) => ({
        registerFromInvite: true,
        token: route.params.token,
        initialEmail: route.params.email,
        seenBetaInfo: route.query.seenBetaInfo || false,
      })
    },
    // during the beta we display an extra page with info about the trial
    {
      path: '/clinician/beta_info',
      name: 'clinician_beta_info',
      component: ClinicianRegistrationBetaInfo
    },
    // during the beta we display an extra page with info about the trial
    {
      path: '/clinician/beta_info/:email/:token',
      name: 'clinician_beta_info_from_email_link',
      component: ClinicianRegistrationBetaInfo,
      beforeEnter: ifNotSignedIn,
      props: true
    },
    {
      path: '/clinician/edit/:clinicianUsername',
      name: 'clinician_edit',
      component: Clinician,
      beforeEnter: ifSignedIn,
      props: true
    },
    {
      path: '/clinician/verification_email_notification',
      name: 'verification_email_notification',
      component: VerificationEmailNotification,
      beforeEnter: ifNotSignedIn
    },
    {
      path: '/clinician/resend_verification_email',
      name: 'resend_verification_email',
      component: ResendVerificationEmail,
      beforeEnter: ifNotSignedIn
    },
    // called with the user clicks on the link in the verification email
    {
      path: '/verifyemail/:email/:token',
      name: 'verify_email',
      component: VerifyEmail,
      beforeEnter: ifNotSignedIn,
      props: true
    },
    {
      path: '/accept_policy_docs',
      name: 'accept_policy_docs',
      component: AcceptPolicyDocs,
      beforeEnter: ifSignedIn,
      props: true
    },
    // {
    //   path: '/paddle_checkout',
    //   name: 'paddle_checkout',
    //   component: PaddleCheckout,
    //   props: true
    // },
    {
      path: '/clinician/news',
      name: 'news',
      component: News,
      beforeEnter: ifSignedIn,
    },
    // on ice
    // {
    //   path: '/clinician/news_item',
    //   name: 'news_item',
    //   component: NewsItem,
    //   beforeEnter: ifSignedIn,
    // },
    // for if a user tries to access a page when he's signed in for which he must be signed out
    {
      path: '/access_error',
      name: 'access_error',
      component: AccessError
    },
    {
      path: '/terms_of_use',
      name: 'terms_of_use',
      component: TermsOfUse,
    },
    {
      path: '/privacy_policy',
      name: 'privacy_policy',
      component: PrivacyPolicy,
    },
    {
      path: '/cookie_policy',
      name: 'cookie_policy',
      component: CookiePolicy,
    },
    {
      path: '/notify_patients',
      name: 'notify_patients',
      component: NotifyPatients,
      beforeEnter: ifSignedIn,
    },
    {
      path: '/plans',
      name: 'plans',
      component: Plans,
      // beforeEnter: ifSignedIn,
    },
    {
      path: '/my_subscription',
      name: 'my_subscription',
      component: MySubscription,
      beforeEnter: ifSignedIn,
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: Feedback,
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '/test',
      name: 'test',
      component: Test
    },
    {
      path: '/test2',
      name: 'test2',
      component: Test2
    },
    {
      path: '/test3',
      name: 'test3',
      component: Test3
    },
    {
      path: '/test4',
      name: 'test4',
      component: Test4
    },
    {
      path: '/test5',
      name: 'test5',
      component: Test5
    },
    {
      path: '*',
      redirect: '/404'
    }


  ],
});
export default router
