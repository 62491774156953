<template>
    <div>

        <v-layout>
            <!-- left padding to centre the sign-in form -->
            <v-flex xs1 md4></v-flex>

            <v-flex xs10 md4>

                <v-form ref="form" v-model="formValid" class="mysymptoms-form-container" @submit="formSubmit">
                    <h2 class="text-center">Resend Verification Email</h2>
                    <hr/>
                    <p>Please enter the email address you used when you registered. We will send you a message with
                        further
                        instructions.</p>
                    <v-text-field
                            v-model="email"
                            :rules="[requiredRule, emailOKRule]"
                            label="E-mail"
                            required
                            :success="!!email"
                    ></v-text-field>

                    <v-btn
                            type="submit"
                            color="primary"
                            block
                    >
                        Submit
                    </v-btn>
                </v-form>
            </v-flex>
            <!-- right padding to centre the sign-in form -->
            <v-flex xs1 md4></v-flex>
        </v-layout>
    </div>

</template>

<script>
  import Logger from '../utils/logger-utils'
  import VError from 'verror'
  import ClinicianAccountService from '../utils/clinician-account-service'
  import Loader from '../utils/loader-utils'
  import * as HttpStatus from 'http-status-codes'
  import {emailOKRule, requiredRule} from "../utils/rules";

  export default {
    name: "ResetPasswordRequest",
    data() {
      return {
        email: '',
        formValid: false
      }
    },
    methods: {
      requiredRule: requiredRule,
      emailOKRule: emailOKRule,

      formSubmit(evt) {
        try {
          // Logger.debug("ResendValidationEmail form submit");
          // stop the submit button click from refreshing the page
          evt.preventDefault()

          // the submit button shouldn't be active until the form is valid, but double check here anyway.
          if (this.formValid) {
            Loader.start()
            ClinicianAccountService.resendVerificationEmail(this.email).then((resp) => {
              Loader.stop()
              this.$router.push({name: 'verification_email_notification'})
            }).catch(err => {
              Loader.stop()
              const cause = VError.cause(err)
              if (err.name === HttpStatus.CONFLICT.toString() ) {
                Logger.error(new VError(cause.response.data.message),{})
              } else {
                Logger.error(new VError(err, `ResendValidationEmail Request failed`),{})
              }
            })
          }
        } catch
          (ex) {
          Logger.error(new VError(ex, `ResendValidationEmail Request failed`))
        }
      }
    }
  }
</script>
