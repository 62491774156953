import Token from "../utils/token"
import {store} from "../store/store.js"
import Logger from "./logger-utils"

// An overview of how Sessions are managed.
// ----------------------------------------
// We initialise the session by getting a token from the server.
// The token is stored in Vuex. We also store the username and password.
// Along with the token we receive a timeout value (which describes how long the server is valid in the server).
// We set a timer on the client to run after this timeout value.
// If the user hasn't interacted with the app (typed or move the mouse) within this timeout value,
// a popup appears asking the user if he wants to carry on with the session. If not, the session is logged out.
// Every time we make a API call, we send the token. If the server replies to say that the token has expired
// we signin again before retrying the API call.
const Session = {

  isSignedIn() {
    // noinspection JSUnresolvedVariable
    let signedIn = store.state.isSignedIn
    // Logger.debug(`Session.isSignedIn: ${signedIn}`)
    return signedIn
  },

  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns {Promise<T | never>}
   */
  signIn(username, password) {

    // Logger.debug("sign in called")

    // noinspection JSUnresolvedFunction
    store.commit("setUsername", {value: username, broadcast: true})
    // noinspection JSUnresolvedFunction
    store.commit("setPassword", {value: password, broadcast: true})

    // we use the availability of a valid oauth token from the server to sign the user in on the client
    return Token.getPrivateToken(username, password).then((resp) => {
      // Logger.debug(`Token.getPrivateToken.then() called: ${JSON.stringify(resp)}`)
      // we don't actually need to use the returned values, just the fact that the call to getPrivateToken has succeeded
      // means that we are signed in are good to proceed. The token and related values are stored in vuex
    })
  },
  // signInAgain() {
  //   // noinspection JSUnresolvedVariable
  //   return this.signIn(store.state.username, store.state.password)
  // },

  signOut() {
    // noinspection JSUnresolvedFunction
    store.commit('clearPrivateTokenInfo', {broadcast: true})
    // noinspection JSUnresolvedFunction
    store.commit('setIsSignedIn', {value: false, broadcast: true})
    // noinspection JSUnresolvedFunction
    store.commit('setUsername', {value: '', broadcast: true})
    // noinspection JSUnresolvedFunction
    store.commit('setPassword', {value: '', broadcast: true})
    store.commit('setPlan', {value: {}, broadcast: true})
  },

}

export default Session

