<template>
    <!--uses pure-css-loader -->
    <div class="loader loader-default" v-bind:class="{'is-active': active}" data-text></div>
</template>

<script>
  import {EventBus} from '../utils/event-bus'

  export default {
    name: "Loader",
    data() {
      return {
        active: false
      }
    },
    methods: {
      start() {
        this.active = true
      },
      stop() {
        this.active = false
      }
    },
    created() {
      EventBus.$on('loader-start', this.start)
      EventBus.$on('loader-stop', this.stop)
    }
  }
</script>

<style scoped>
    /* used by pure-css-load */
    /*noinspection CssUnusedSymbol*/
    .loader.is-active {
        background-color: rgba(0, 0, 0, .35);
    }
</style>
