import {checkProperties} from "./helpers"
import Api from './api'
import moment from 'moment'
import VError from 'verror'

const tokenPath = "oauth/token"


// noinspection JSCommentMatchesSignature
export default {

  getPublicToken() {
    return this.getToken("grant_type=client_credentials").then( res => {
      checkProperties(res.data, ['access_token'])
      return res.data.access_token
    })
  },

  // TBC token fresh isn't yet implemented on the server
  // refreshToken(refreshToken, callback) {
  //   this.getToken(`grant_type=refresh_token&refresh_token=${refreshToken}`, callback)
  // },

  getPrivateToken(username, password) {

    // debug
    if ( typeof password === 'undefined' || username === 'undefined') {
      const pwd = Boolean(password) ? password : 'undefined'
      const uname = Boolean(username) ? username : 'undefined'
      throw new VError(`password: ${pwd}, username: ${uname}`)
    }

    return this.getToken("grant_type=password&username=" + username + "&password=" + password)
      .then(res => {
        // Logger.debug(`getPrivateToken: post response: ${JSON.stringify(res)}`)
        checkProperties(res.data, ['access_token', 'refresh_token', 'expires_in'])

        const privateToken = res.data.access_token
        const refreshToken = res.data.refresh_token

        const privateTokenExpiresIn = parseInt(res.data.expires_in)
        if (isNaN(privateTokenExpiresIn)) {
          throw new VError(`expires_in is not an Integer: ${res.data.expires_in}`)
        }

        const privateTokenExpiresAt = moment().add(privateTokenExpiresIn, 'seconds').toISOString()

        return {privateToken, refreshToken, privateTokenExpiresIn, privateTokenExpiresAt}
      })
  },

  /**
   *
   * @param {string} - queryParams
   * @returns {*} Promise resolving to
   *                {
   *
   *                }
   */
  getToken(queryParams) {
    const pathWithParams = `${tokenPath}?${queryParams}`
    return Api.post(pathWithParams)
  },


  // TBC - not yet implemented on the server
  // signOut() {
  //   const signoutUrl = "/logout"
  // },

}
