import Token from './token.js'
import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import {store} from "../store/store"
import Logger from './logger-utils'
import VError from 'verror'

const METHOD_PUT = 'put'
const METHOD_POST = 'post'
const METHOD_GET = 'get'
const METHOD_DELETE = 'delete'

export default {

  AXIOS_ERROR_NAME: "Axios Interal Error",

  get(path, data) {
    data = data ? data : {}
    return this.request(METHOD_GET, path, data)
  },

  post(path, data) {
    data = data ? data : {}
    return this.request(METHOD_POST, path, data)
  },

  put(path, data) {
    data = data ? data : {}
    return this.request(METHOD_PUT, path, data)
  },

  delete(path, data) {
    data = data ? data : {}
    return this.request(METHOD_DELETE, path, data)
  },

  /**
   *
   * @param {string} method - get, post, put, delete
   * @param {string} path e.g. /public/resetpassword
   * @param {object} [dataIn] - to be passed as the http payload
   * @returns {Promise} Axios HTTP promise
   */
  request(method, path, dataIn) {

    const publicResource = path.startsWith('public') || path.startsWith('/public')
    const privateResource = path.startsWith('private') || path.startsWith('/private')
    const oauthResource = path.startsWith('oauth') || path.startsWith('/oauth')


    const data = (typeof dataIn === 'undefined') ? {} : dataIn

    let retPromise = null // the promise to return

    if (oauthResource) {
      const privKey = process.env.VUE_APP_PRIV_KEY
      if (privKey === "") {
        throw new Error('VUE_APP_PRIV_KEY not set in environment')
      }

      const headers = {
        'Content-Type': 'application/json',
        // Authorization: `Basic ${this.privKey}`
        Authorization: `Basic ${privKey}`
      }
      // TBC need to add a doPrivateRequest that handles a 401 if token has expired
      // or need to check, when user clicked to carry on from Timeout message, why was the token not refreshed then?
      retPromise = this.doRequest(method, path, data, headers)
    } else if (publicResource) {
      retPromise = this.doPublicRequest(method, path, data)
    } else if (privateResource) {

      retPromise = this.doPrivateRequest(method, path, data)

    } else {
      // assume a request to an external server
      retPromise = this.doExternalRequest(method, path, data)
      // throw new Error(`unknown resource type: ${path}`)
    }

    return retPromise
  },

  /**
   *  Lowest level wrapper around the Axios api call to the mySymptoms api server
   *
   * @param method - GET, PUT, POST, DELETE
   * @param path - e.g. /public/account/register
   * @param data - json payload
   * @param headers
   * @returns {Promise<AxiosResponse<any>>}
   */
  doRequest(method, path, data, headers) {

    const apiUrl = process.env.VUE_APP_API_BASE_URL

    if (apiUrl === "") {
      throw new Error('VUE_APP_API_BASE_URL not set in environment')
    }

    // add in info to help debugging on the server, note they are all lower case as header fields do not allow uppercase
    headers['appinstanceid'] = store.state.appId
    headers['tabid'] = store.state.tabId
    store.commit('incMsgCnt')
    headers['msgcnt'] = store.state.msgCnt
    headers['mysymptoms'] = 'web'

    // Logger.debug(`doRequest: ${path}`)

    const axiosConfig = {
      method: method,
      baseURL: apiUrl,
      url: path,
      headers: headers,
      responseType: 'json',
      timeout: 20000, // ms
      data: data
    }

    const prom = axios(axiosConfig).then(res => {
      // debugger
      return res
    }).catch(err => {
      // depending on where the error was thrown from we can find different data...
      //
      // Axios Generated:
      // These errors are generated in the Axios library itself. They are standard javascript Error object. For exampe,
      // Axios cannot connect to the server. We just package in a VError with some context information and rethrow
      //
      // Oauth / Spring spring:
      // The request has successfully made it to the server but has failed validation. In this case we have extra information
      // in the following fields, for example:
      //
      //     err.response.status: 400,
      //     err.response.data: {
      //         "error": "invalid_grant",
      //         "error_description": "Username or password incorrect"
      //     }
      //
      //     err.response.status: 401,
      //     err.response.data: {
      //         "error": "invalid_token",
      //         "error_description": "Access token expired: 7372be07-4dd5-4660-94e2-4f0ea9033f74"
      //     }
      //
      // MySymptoms generated:
      // When the request successfully validates on the server, but mySymptoms generates an error, we have the following
      // extra fields, e.g.:
      //
      //      err.response.status: 404,
      //      err.response.data: {
      //         "message": "Patient not found for this clinician",
      //         "code": "NOT_FOUND"
      //      }
      //
      if (!err.response) {
        // Axios errors (identified by the lack of a response object)
        throw new VError({
          name: "Axios error",
          cause: err,
          info: {axiosConfig: axiosConfig}
        }, `SyncServer api call failed`)
      } else {
        // we have a server generated error but don't know whether it's a oauth or mySymptoms generated error.
        // A meaningful message will be in either the data.error_description or data.message field

        // we expect there to be a data field, but let's do some defensive programming just in case ..
        const data = Boolean(err.response.data) ? err.response.data : {message: "missing response data"}

        const src = [data.error_description, data.message]
        let msg = src.filter(x => typeof x !== 'undefined').join(', ')

        // provide context information in the VError with info object from the axiosConfig and response.data object
        const info = {
          axiosConfig: axiosConfig,
          response_data: data
        }

        // we use the HTTP status as the VError name so the calling functions can programmatically identify the error
        // and act accordingly
        const statusString = Boolean(err.response.status) ? err.response.status.toString() : "missing status"
        throw new VError({name: statusString, cause: err, info: info}, msg)
      }
    })
    return prom
  },

  /**
   * make a request for a public resource on mySymptoms API server.
   * We need a valid public token. If we don't already have a valid token we need to request the token, before we make
   * the actual request.
   *
   * @params {integer} retryCnt - this method could be called recursively when we're trying to get a token. We keep count to prevent
   * infinite loop
   */
  doPublicRequest(method, path, data, retryCnt) {

    // not sure how many retries we need, do we every need more than one?
    const MaxRetries = 1

    retryCnt = retryCnt ? retryCnt : 0

    // NB Token.getPublicToken() could end with recursively calling Api.doPublicRequest to get oauth token
    return Token.getPublicToken().then(token => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      return this.doRequest(method, path, data, headers) // note this just completes the promise, does not return from 'post'
        .catch(err => {
          // check for expired token, in which case we try to get a new one
          const cause = VError.cause(err)
          if (err.name === HttpStatus.UNAUTHORIZED.toString() && cause.response.data.error === "invalid_token") {

            // limit the number of retries to avoid infinite loop
            if (retryCnt > MaxRetries) {
              throw new VError(err, "exceeded max number of retries for public token.")
            } else {
              // Logger.debug("public token expired, getting another from server.")
              // clear public token and re-call this function again. next time Token.getPublicToken will be forced to get
              // a new token from server
              // noinspection JSUnresolvedFunction
              store.commit('setPublicToken', {value: '', broadcast: true})
              // note that this 'return' is within the catch clause and so resolves the promise
              return this.doPublicRequest(method, path, data, retryCnt + 1)
            }
          } else {
            // we're not handling other errors so rethrow
            throw err
          }
        })
    })
  },


  /**
   * make a request for a private resource. We need a valid private token. If we don't already have a valid token we
   * need to request the token, before we make the actual request.
   *
   * @params {integer} retryCnt - this method could be called recursively when we're trying to get a token. We keep count to prevent
   * infinite loop
   */
  doPrivateRequest(method, path, data, retryCnt) {

    // not sure how many retries we need, do we every need more than one?
    const MaxRetries = 1

    retryCnt = retryCnt ? retryCnt : 0

    // defensive programming
    if (store.state.username === "")
      throw new VError("store.state.username not set")

    if (store.state.password === "")
      throw new VError("store.state.password not set")

    // NB Token.getPublicToken() could end with recursively calling Api.post to get oauth token
    // noinspection JSUnresolvedVariable
    return Token.getPrivateToken(store.state.username, store.state.password).then(resp => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${resp.privateToken}`
      }
      return this.doRequest(method, path, data, headers) // NB this just completes the promise, does not return from 'post'
        .catch(err => {
          // test for if the private token has expired, in which case we try to get a new one
          const cause = VError.cause(err)
          if (err.name === HttpStatus.UNAUTHORIZED.toString() && cause.response.data.error === "invalid_token") {

            // limit the number of retries to avoid infinite loop
            if (retryCnt > MaxRetries) {
              throw new VError(err, "exceeded max number of retries for private token.")
            } else {
              // Logger.debug("private token expired, getting another from server.")
              // clear private token info and re-call this function again. next time Token.getPrivateToken will be forced to get
              // a new token from server
              // noinspection JSUnresolvedFunction
              store.commit('clearPrivateTokenInfo', {broadcast: true})
              // note that this 'return' is within the catch clause and so resolves the promise
              return this.doPrivateRequest(method, path, data, retryCnt + 1)
            }
          } else {
            throw err
          }
        })
    })
  },
  doExternalRequest(method, path, data) {

    // Logger.debug(`doRequest: ${path}`)

    const axiosConfig = {
      method: method,
      baseURL: path,
      // url: path,
      // headers: headers,
      // responseType: 'json',
      timeout: 20000, // ms
      data: data
    }

    const prom = axios(axiosConfig).then(res => {
      // debugger
      return res
    }).catch(err => {
      Logger.error(new VError(err),{})
    })
    return prom
  }
}

