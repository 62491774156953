import Vue from 'vue'
import Vuex from 'vuex'
import {BroadcastChannel} from 'broadcast-channel'
import Logger from '../utils/logger-utils'
import TabId from '../utils/tab-id'
import VError from 'trace-error'
import storeConfig from './store-config'
import moment from 'moment-timezone'

Vue.use(Vuex);

/**
 * The Store is synchronised across all browser tabs running MySymptoms.
 *
 * We achieve this with the inter-tab messaging library BroadcastChannel.
 *
 * When a tab opens it sends out a stateRequestMsg over the channel. All other tabs responded with their state, which
 * the new tab listens for and uses to initialise its own state. If no other tabs respond with the store will stay
 * in its initialised default state.
 *
 * When any of the tabs updates its state (e.g. signing in or out), it broadcasts a message over the channel, which
 * other tabs listen for and update their state accordingly.
 *
 * Note there is a small inefficiency in the current implementation. When a tab opens and sends out the stateRequestMsg
 * it may receive back more than one reply (if there is more than one other tabs open). Therefore the newly opened
 * tab might initialise its state multiple times.
 *
 * See here for discussion of the different options on how to share state between tabs.
 * https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
 * We have essentially implemented the 'Sharing memoryStorage' option through using the BroadcastChannel library.
 *
 * As the article points out, there is a shortcoming in this implementation in that when there is only one tab open
 * a page refresh will force the user to log in again. We might address this in a future release.
 *
 */

  // Declare the channel through which we communitate between the browsers
const channel = new BroadcastChannel('mySymptoms');
// consts for the channel message types
const stateRequestMsg = 'stateRequest' // sent when a browser is opened (or page refreshed) to request state from other tabs
const stateReplyMsg = 'stateReply'  // sent in reply to a stateRequestMsg
const stateUpdateMsg = 'stateUpdate' // sent whenever a tab updates its state

/**
 * initialiseStore : requests the store state from other open browser tabs if they exist, otherwise sets state to default
 * values.
 */
const initialiseStore = function () {

  // wait a short time and resolve if we have received a reply from another tab. If not wait and try again.
  // If we exceed max number of tries resolve anyway. Essentially we want to resolve as soon as possible, but
  // we want to allow for the possibility that we are the first tab to open, so we will never get a reply.
  function waitForInitialisation(resolve, retryCnt) {
    const MaxRetries = 10

    // set a delay to give time for the other tabs to reply
    if (retryCnt >= MaxRetries) {
      // Logger.debug(`waitForInitialisation resolved after ${retryCnt} retries`)
      resolve()
    } else {
      setTimeout(() => {
        // noinspection JSUnresolvedVariable
        if (store.state.isInitialisedFromAnotherTab) {
          // Logger.debug(`waitForInitialisation resolved after ${retryCnt} retries`)
          resolve()
        } else {
          waitForInitialisation(resolve, retryCnt + 1)
        }
      }, 10)
    }
  }

  const p = new Promise((resolve, reject) => {
    const msg = {type: stateRequestMsg, src: TabId.getInstance(), msgCnt: nextMsgCnt()}
    try {
      // Logger.debug(`postingMessage: ${JSON.stringify(msg)}`)
      // post a message to other tabs requesting their state. We process replies asynchronously in channel.onmessage
      // which sets the store.state.isInitialisedFromAnotherTab flab
      // noinspection JSIgnoredPromiseFromCall
      channel.postMessage(msg)
      // we now wait for the store.state.isInitialisedFromAnotherTab or timeout if we don't get a reply after set period
      waitForInitialisation(resolve, 0)
    } catch (ex) {
      const te = new VError(ex, `failed call to channel.postMessage with msg: ${JSON.stringify(msg)}`)
      reject(te)
    }
  })
  return p
}


// we don't necessarily want to broadcast all state changes, these are the ones we do want to broadcast ...
const sharedMutations = ['setPublicToken', 'setPrivateTokenInfo', 'setOauthToken', 'setOauthRefreshToken',
  'setOauthTokenExpires',
  'clearPrivateTokenInfo',
  // 'setTokenExpired',
  'setIsSignedIn', 'setUsername', 'setPassword', 'setLastActivityTime'
  , 'setPlan'
]

// noinspection JSValidateTypes
const store = new Vuex.Store(storeConfig)

/**
 * store.subscribe - gets called whenever the state is mutated. We use this to broadcast the new state to other tabs
 *  we only want to post state updates that originated from this tab, not updates that originated from other tabs
 * The function that calls the store.commit controls this by setting the payload.broadcast flag.
 *
 * @param mutation {Object}
 * @param mutation.type {String} - The mutation that has been called: setToken, setRefreshToken, etc
 * @param mutation.payload.broadcast[Boolean] - default true. Broadcast this mutation to other tabs.
 * @param mutation.payload.value {any} - The value set in the mutation
 * @param state - The Vuex state object after the mutation has been applied
 */

function storeSubscribeCallback(mutation, state) {

  let msg = {}

  try {

    // Logger.debug(`called with : ${JSON.stringify(mutation)}`)

    // not all mutation types are shared with other tabs, check if this is one of the types we want to share.
    const shareMutation = sharedMutations.includes(mutation.type)

    // also, we only want to broadcast state updates that originated from this tab, not updates that originated from other tabs
    if (shareMutation && mutation.payload.broadcast) {
      msg = {type: stateUpdateMsg, mutation: mutation, src: TabId.getInstance(), msgCnt: nextMsgCnt()}
      // Logger.debug(`postingMessage: ${JSON.stringify(msg)}`)
      // noinspection JSIgnoredPromiseFromCall
      channel.postMessage(msg)
    } else {
      // Logger.debug("not broadcast; sharedMutation: " + shareMutation + ", payload: "
      //   + (mutation.payload ? mutation.payload : ''))
    }
  } catch (ex) {
    Logger.error(new VError(ex, `Failed store subscribe callback for msg: ${JSON.stringify(msg)}`))
  }
}

// noinspection JSUnresolvedFunction
store.subscribe(storeSubscribeCallback)

/**
 * increments the msgCnt and returns the incremented value
 * @returns {*|number}
 */
function nextMsgCnt() {
  // noinspection JSUnresolvedFunction
  store.commit("incMsgCnt")
  // noinspection JSUnresolvedVariable
  return store.state.msgCnt
}

/**
 * A helper function for retrieving the Plan object ... adds in Moment versions for each date field
 */
function getPlan() {
  const tz = moment.tz.guess(true);

  const plan = Object.assign({}, store.state.plan)
  plan.cancellationEffectiveDateMom = plan.cancellationEffectiveDate ? moment.tz(plan.cancellationEffectiveDate, tz) : ''
  plan.nextBillDateMom = plan.nextBillDate ? moment.tz(plan.nextBillDate, tz) : ''
  plan.createdAtMom = plan.createdAt ? moment.tz(plan.createdAt, tz) : ''
  plan.nextRetryDateMom = plan.nextRetryDate ? moment.tz(plan.nextRetryDate, tz) : ''
  plan.pausedFromMom = plan.pausedFrom ? moment.tz(plan.pausedFrom, tz) : ''
  plan.pausedAtMom = plan.pausedAt ? moment.tz(plan.pausedAt, tz) : ''
  return plan
}

/**
 * Set up Broadcast channel listener method
 *
 * msg.type - the type of message:
 *              stateRequestMsg - the state is being requested from other tab, or
 *              stateReplyMsg - other tabs have replied with their state (to a stateRequestMsg sent out previously)
 *              stateUpdateMsg - another tab has updated its state and pushed out this notification to all other tabs
 * msg.state - the Vuex Store state object
 */
channel.onmessage = msg => {
  try {
    // Logger.debug(`received message: ${JSON.stringify(msg)}`)

    if (typeof msg.type === "undefined") {
      // noinspection ExceptionCaughtLocallyJS
      throw new VError("msg.type is undefined")
    }

    if (msg.type === stateRequestMsg) {
      // we have received a message requesting state.
      // Note we dont want send all state members; broadcast only parts of the state we wish to sync with tabs
      // these fields are merged at the receivers end with the other state fields
      // const expires_date = store.state.oauthTokenExpires !== null ? store.state.oauthTokenExpires.toDate() : null

      // noinspection JSUnresolvedVariable
      const stateToSend = {
        oauthPublicToken: store.state.oauthPublicToken,
        oauthPrivateToken: store.state.oauthPrivateToken,
        oauthRefreshToken: store.state.oauthRefreshToken,
        username: store.state.username,
        displayName: store.state.displayName,
        password: store.state.password,
        oauthPrivateTokenExpiresIn: store.state.oauthPrivateTokenExpiresIn,
        oauthPrivateTokenExpiresAt: store.state.oauthPrivateTokenExpiresAt,
        // tokenExpired: store.state.tokenExpired,
        isSignedIn: store.state.isSignedIn,
        appId: store.state.appId,
        plan: store.state.plan
      }
      const msg = {type: stateReplyMsg, state: stateToSend, src: TabId.getInstance(), msgCnt: nextMsgCnt()}
      // Logger.debug(`postingMessage: ${JSON.stringify(msg)}`)
      // noinspection JSIgnoredPromiseFromCall
      channel.postMessage(msg)
    } else if (msg.type === stateReplyMsg) {
      // we received this in reply to sending out a stateRequestMsg during initialisation.
      // Note it is OK to use store.repaceState in this case as we are initialising.
      // noinspection JSUnresolvedVariable
      const newState = Object.assign(store.state, msg.state)
      // noinspection JSUnresolvedFunction
      store.replaceState(newState)
      // noinspection JSUnresolvedFunction
      store.commit("setIsInitialisedFromAnotherTab", {value: true, broadcast: false})

    } else if (msg.type === stateUpdateMsg) {
      // another tab has updated its state and has pushed out notification to other tabs
      // commit the mutation with but with 'broadcast: false' to prevent the mutation from being re-propagated
      const newPayload = Object.assign(msg.mutation.payload, {broadcast: false})
      // noinspection JSUnresolvedFunction
      store.commit(msg.mutation.type, newPayload)
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new VError(`unknown message type: ${msg.type}`)
    }
  } catch (ex) {
    Logger.error(new VError(ex, `Failed channel listener callback for msg: ${JSON.stringify(msg)}`))
  }
}

export {store}
export {initialiseStore}
export {getPlan}
