<template>
    <v-layout>
        <v-flex xs2 md4></v-flex>
        <v-flex xs8 md4>
            <v-form ref="form" v-model="formValid" class="mysymptoms-form-container" @submit.prevent="onSignInFormSubmit">
                <h2 class="text-center">Sign In</h2>
                <hr/>
                <!-- note on the noUppercaeRule: The It's a weaker form of the usernameOKRule used in the registration
                ... we want to guard against the user logging in with a username that differs by case from the one the
                register with. Spring will allow it, but our api security doesn't.
                We could use usernameOKRule, but noUppercaseRule gives a nicer user experience and meets the authentication needs.
                -->
                <v-text-field
                        name="username"
                        autocomplete="username"
                        v-model.trim="username"
                        label="Username"
                        :rules="[requiredRule, noUppercaseRule, usernameNotEmailRule]"
                        :success="!!username"
                        data-cy="username-input"
                ></v-text-field>
                <v-text-field
                        name="password"
                        v-model.trim="password"
                        id="password"
                        label="Password"
                        autocomplete="current-password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[requiredRule]"
                        :success="!!password"
                        data-cy="password-input"
                >
                    <v-tooltip slot="append-outer" bottom>
                        <v-icon
                                slot="activator"
                                light
                                @click="showPassword = !showPassword"
                        >{{showPassword ? 'visibility_off' : 'visibility'}}
                            <v-btn></v-btn>
                        </v-icon>
                        Show Password
                    </v-tooltip>
                </v-text-field>

                <v-btn
                        type="submit"
                        class="mt-3"
                        block
                        color="primary"
                        data-cy="signin-button"
                >
                    Sign In
                </v-btn>

                <div class="text-center signin-footer">
                    <router-link style='padding-right: 25px' :to="{name: 'reset_password_request'}">Reset Password</router-link>
                    &nbsp;
                    <router-link :to="{name: 'clinician_create'}">Register</router-link>
                </div>
            </v-form>
        </v-flex>
        <v-flex xs2 md4>
        </v-flex>

        <v-dialog
                v-model="accountNotVerified"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">Account Not Verified</v-card-title>

                <v-card-text>
                    This account has not been verified. Please check your email (including Spam folder) for
                    instructions, or click
                    <router-link :to="{name: 'resend_verification_email'}">here</router-link>
                    to resend the verification email.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="primary"
                            flat="flat"
                            @click="accountNotVerified = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-layout>
</template>

<script>
  import Session from '../utils/session.js'
  import Logger from '../utils/logger-utils'
  import VError from 'verror'
  import PasswordUtils from '../utils/password-utils'
  import ClinicianAccountService from '../utils/clinician-account-service'
  import * as HttpStatus from 'http-status-codes'
  import Loader from '../utils/loader-utils'
  import {requiredRule, noUppercaseRule, usernameNotEmailRule} from "../utils/rules";

  export default {
    name: "SignIn",
    data() {
      return {
        username: '',
        password: '',
        formValid: false,
        showPassword: false,
        accountNotVerified: false
      }
    },
    methods: {
      // usernameOKRule: usernameOKRule,
      requiredRule: requiredRule,
      noUppercaseRule: noUppercaseRule,
      usernameNotEmailRule: usernameNotEmailRule,

      onSignInFormSubmit(evt) {

        try {

          // Logger.debug("signing in " + this.username + ":" + this.password);

          // stop the submit button click from refreshing the page
          // evt.preventDefault()

          // only attempt signing in if the field validations have passed.
          const isValid = this.$refs.form.validate()
          if (isValid) {
            const encryptedPassword = PasswordUtils.encrypt(this.password)

            Loader.start()

            Session.signIn(this.username, encryptedPassword)
              .then(() => {
                Loader.stop()
                //   // on successful sign-in a redirect to the home page is triggered by the watcher App.watch.isSignedIn
              })
              .catch(err => {
                Loader.stop()
                const cause = VError.cause(err)
                if (err.name === HttpStatus.BAD_REQUEST.toString() && Boolean(cause.response.data.error === "invalid_grant")) {
                  // before we report to the user, check to see if there's a pending verification on this account
                  ClinicianAccountService.isAccountPending(this.username).then(res => {
                    if (res.data.pending === true) {
                      // show dialogue telling user the account hasn't been verified
                      this.accountNotVerified = true
                    } else {
                      Logger.error('Username or Password invalid')
                    }
                  }).catch(err => {
                    Logger.error(new VError(err, `Error determining verification status for username: ${this.username}`))
                  })
                } else {
                  Logger.error(new VError(err, `Signin failed`))
                }
              })
          }
        } catch (ex) {
          // for any other error display in the general alert panel
          Logger.error(new VError(ex, `Signin failed`))
        }
      },

    },
  }

</script>

<style scoped>
    .signin-footer {
        margin-top: 20px
    }
</style>
