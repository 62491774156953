/**
 *  Checks if list of props is present on an object. Throws an Error if any are missing, otherwise returns silently.
 * @param {Object} obj : object to test
 * @param {(string|string[])} props
 */
import VError from 'verror'

function checkProperties(obj, props) {

  // convert props to an array if not already
  const arrayProps = Array.isArray(props) ? props : [ props ]

  // which of the props are missing from the object?
  const missingProps = arrayProps.filter( att => {
    const ret = !obj.hasOwnProperty(att)
    return ret
  })
  if (missingProps.length > 0) {
    throw new VError(`Missing properties: ${missingProps.join(', ')}`)
  }
}

export {checkProperties}
