<template>
    <v-layout pt-3>
        <v-flex xs1/>
        <v-flex xs10 class="mysymptoms-main-container">
            <div v-html="docBody">
            </div>
        </v-flex>
        <v-flex xs1/>
    </v-layout>
</template>

<script>

  import {policyDocService, PrivacyPolicyDocType} from "../utils/policy_doc_service";
  import axios from "axios";
  import Logger from "../utils/logger-utils";
  import Loader from '../utils/loader-utils'

  export default {
    name: 'PrivacyPolicy',
    data() {
      return {
        docBody: ""
      }
    },
    created() {
      Loader.start()
      // call the sync server api to find out which is the current policy document
      // and load the html dynamically from the public folder (it's located there so we can share it with the
      // company website)
      policyDocService.getCurrentPolicyDocMetaData(PrivacyPolicyDocType).then(res => {
        return policyDocService.getDocContent(res.data.docName)
      }).then(response => {
        this.docBody = response.data
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    }
  };

</script>
<style scoped>
    td, th {
        vertical-align: top;
        border: solid 1px grey;
        padding: 5px;
    }

    .no_borders td {
        border-style: none;

    }

    table {
        margin-bottom: 10px
    }

    ol {
        list-style-type: lower-alpha;
    }
</style>
