<template>
  <div class="mysymptoms-main-container">

    <v-layout>
      <v-flex xs8>
        <h1 class="display-1 pt-3">Clinicians</h1>
        <!--        <p>Plan: {{ this.$store.state.plan.displayName }}, Status: {{ this.$store.state.plan.status }}</p>-->
      </v-flex>
      <v-flex xs4>
        <v-text-field
            v-model="searchTerm"
            append-icon="search"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-card>
      <!-- main data table -->
      <!-- Note, we are not using the v-data-table built in search. Instead, we are pre-filtering the items so we can
      search on the displayed Patient Since date string-->
      <!-- pagination https://github.com/vuetifyjs/vuetify/issues/442 -->
      <v-data-table
          :headers="headers"
          :items="clinicians"
          :total-items="totalClinicians"
          must-sort
          no-data-text="No clinicians found."
          no-results-text="No clinicians found."
          v-bind:pagination.sync="pagination"
          v-bind:rows-per-page-items="rowsPerPageItems"
      >
        <template slot="headerCell" slot-scope="props">
          <span v-if="!('tooltip' in props.header) || props.header.tooltip === ''">{{ props.header.text }}</span>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.header.text }}</span>
            </template>
            <span>{{ props.header.tooltip }}</span>
          </v-tooltip>
        </template>

        <template slot="items" slot-scope="props"
        >
          <!--          :to="{name: 'patient_management', params: { clinicianUsername: props.item.username }}"-->
          <router-link
              tag="tr"
              :to="{path: `patient_management/${props.item.username}`}"
              class="black--text cursor_pointer"
          >
            <td class="justify-left">
              {{ props.item.username }}
            </td>
            <td>
              {{ props.item.email }}
            </td>
            <td>{{ props.item.regDate }}</td>
            <!--            <td>{{ props.item.lastUpdated }}</td>-->
            <!--            <td class="justify-center px-24 enabled"> </td>-->
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ClinicianAccountService from '../utils/clinician-account-service'
import Logger from '../utils/logger-utils'
import Loader from "../utils/loader-utils"
import moment from 'moment'
import _ from 'lodash'


export default {
  name: "PatientManagement",
  // components: {VDialog},
  data() {
    return {
      // max25chars: (v) => v.length <= 25 || 'Input too long!',
      headers:
          [
            {text: 'Username', value: 'username', sortable: true},
            {text: 'Email', value: 'email', sortable: true},
            // { text: 'Name', value: 'realName', sortable: true, searchable: true },
            // note we're sorting on the numeric version of the Patient Since but displaying the text version
            {text: 'Reg Date', value: 'dateCreated', sortable: true},
          ],

      clinicians: [],
      totalClinicians: 0,
      // cliniciansLoaded: false,
      searchTerm: '',
      pagination: {'sortBy': 'dateCreated', 'descending': true, 'rowsPerPage': 25},
      // pagination: {},
      // rowsPerPageItems: [3, 10, 25, 50, {text: 'All', value: -1}],
      rowsPerPageItems: [3, 10, 25, 50,],
      confirmDeleteDialog: {},
      showEditRealNameDialogue: {}
    }
  },
  methods: {

    // when we're on a plan with a limited number clients, the clinician may have downgraded from a plan with
    // more clients. In that case disable access to a number of patients that exceed the limit for this plan
    // OR
    // if the account is paused, disable them all
    isRowDisabled(row_cnt) {
      if (this.$store.state.plan.status === 'paused') {
        return true
      } else if (!this.$store.state.plan.numClients) {
        // numClients not defined so we are on an unlimited plan
        return false
      } else {
        return row_cnt >= this.$store.state.plan.numClients
      }
    },

    getClinicianData(pageNum, rowsPerPage, descending, sortBy, searchTerm) {
      const i = 1;
      // Loader.start()
      this.loading = true
      // ClinicianAccountService.getClinicianAccountsInfo(0, 25, "asc", "id").then(resp => {
      // note page num is zero-base on server but 1-based on vuetify
      const sortOrder = descending ? "desc" : "asc"
      let sortField
      switch (sortBy) {
        case 'username':
          sortField = 'user' // the clinician table has a one to one mapping to the user table
          break;
        case 'email':
          sortField = 'email'
          break;
        case 'dateCreated':
          sortField = 'id'
          break;
      }

      ClinicianAccountService.getClinicianAccountsInfo(pageNum - 1, rowsPerPage, sortOrder, sortField, searchTerm).then(resp => {

        this.totalClinicians = resp.data.totalUsers
        const tempData = _.map(resp.data.accountsDtos, clinician => {
          const regDate = moment(clinician.regDate)
          return {
            username: clinician.username,
            email: clinician.email,
            regDate: regDate.format("DD MMM YYYY"),
            regDateSortable: regDate.valueOf()
          }

        })
        // we need to make sure the data is sorted by the default sort order, since if some of rows are disabled we need
        // to make sure it is the first few rows are enable (note rows are disabled if the user has downgraded from
        // thei subscription plan and has too many patients for their current plan
        // this.patients = _.orderBy(tempData, ["patientSinceSortable", 'desc']);
        // this.clinicians = _.orderBy(tempData, ["patientSinceSortable"], ['desc'])
        // this.cliniciansLoaded = true
        this.clinicians = tempData
        this.loading = false
        // Loader.stop()
      }).catch(err => {
        // Loader.stop()
        this.loading = false
        Logger.error(err)
      })
    }
  },
  computed: {
    /**
     * filters the patient records by searching ** only ** the string fields of the row. The row is included if any
     * field value contains the search text (case insensitive)
     */
    // filteredClinicians() {
    //   const search = this.search.toString().toLowerCase()
    //   if (search.trim() === '') {
    //     return this.clinicians
    //   }
    //
    //   const ret = this.clinicians.filter(clinician => {
    //     // loop over each member of the object, return true as soon as we find a field that contains the search string
    //     for (let [, value] of Object.entries(clinician)) {
    //       if (typeof value === "string" && value.toLowerCase().includes(search)) {
    //         return true
    //       }
    //     }
    //     // we didn't find any occurrence of the search string so return false
    //     return false
    //   })
    //   return ret
    // }
  },
  mounted() {


  },
  watch: {
    pagination: {
      handler() {
        const {sortBy, descending, page, rowsPerPage} = this.pagination
        this.getClinicianData(page, rowsPerPage, descending, sortBy, this.searchTerm)
      }
    },
    searchTerm(newVal) {
      const {sortBy, descending, page, rowsPerPage} = this.pagination
      this.getClinicianData(page, rowsPerPage, descending, sortBy, newVal)
    }
  }
}
</script>

<style scoped>
.cursor_pointer {
  cursor: pointer;
  height: 42px;
  width: 42px;
}

/*https://stackoverflow.com/questions/61396466/how-to-disable-a-vuejs-router-link*/
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.enabled {
  opacity: 1;
  pointer-events: auto;
}
</style>
