<template>
    <div class="form-group">
        <v-text-field
                v-model="password"
                :rules="passwordRules"
                :label="placeholder"
                autocomplete="new-password"
                :error-count=5
                :success-messages="pwdSuccessMsgs"
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :success="!!password"
                v-on:input="$emit('input', $event)"
        ></v-text-field>
        <v-text-field
                ref="confirmRef"
                v-model="confirmPassword"
                :rules="[requiredRule, matchesPasswordRule]"
                label="Confirm Password"
                :type="showPassword ? 'text' : 'password'"
                :success="!!confirmPassword"
        ></v-text-field>

    </div>
</template>

<script>
  import zxcvbn from 'zxcvbn';
  import Logger from '../utils/logger-utils'

  // the password component passes the value up to the parent through calling .$emit('input', value) when
  // the password values input meet all the criteria.
  //
  const minPwdStrength = 3
  function initialState() {
    return {
      valid: false,
      password: '',
      showPassword: false,
      confirmPassword: '',
      hasError: false,
      shouldValidate: true,
      passwordRules: [
        passwordStrengthRule,
        v => v.length < 128 || 'Password must be less than 128 characters.',
      ],
    }
  }
  function passwordStrengthRule(pwd) {
    // Logger.debug("passwordStrengthRule: " + pwd)
    const z = zxcvbn(pwd)
    const score = z.score
    if (score < minPwdStrength) {
      const fb = z.feedback
      const hint = [fb.warning].concat(fb.suggestions).filter(x => x !== '').join(' ')
      // const hint = [fb.warning].filter(x => x !== '').join('. ')
      const msg = `${strengthMsg(score)} ${hint}`
      return msg
    } else {
      return true
    }
  }

  function strengthMsg(passwordStrength) {
    let desc = ''
    if (passwordStrength < minPwdStrength) {
      desc = 'Too weak'
    } else if (passwordStrength === minPwdStrength) {
      desc = 'OK'
    } else {
      desc = 'Strong'
    }
    return `Strength: ${passwordStrength} / 4. ${desc}.`
  }

  export default {
    name: "Password",
    props: {
      value: {
        type: String
      },
      placeholder: {
        type: String,
        default: 'Password'
      },
      rules: {
        type: Array,
        required: false
      }
    },
    data() {
      return initialState()
        // valid: false,
        // password: '',
        // showPassword: false,
        // confirmPassword: '',
        // hasError: false,
        // shouldValidate: true
    },
    methods: {

      requiredRule(v) {
        return !!v || `Field is required.`
      },
      /**
       * Emit password value to parent component, if the password meets all criteria
       * @param  {String} value password typed in
       */
      emitPasswordIfOK() {
        let valToEmit = this.valid ? this.password : ''
        this.$emit('input', valToEmit)
      },
      matchesPasswordRule(confirmPwd) {
        return (confirmPwd === this.password) || 'Does not match password'
      },

      setInitialState(){
        Object.assign(this.$data, initialState());
      }
    },
    computed: {

      pwdSuccessMsgs() {
        const z = zxcvbn(this.password)
        const score = z.score
        if (score >= minPwdStrength) {
          return strengthMsg(score)
        } else {
          return ''
        }
      },

      /**
       * passwordStrength is the score calculated by zxcvbn - on a scale of 0 - 4
       * @return {Number} Password Strength Score
       */
      // passwordStrength() {
      //   return this.password ? zxcvbn(this.password).score : null
      // },

    },

    watch: {
      password: function (newVal, oldVal) {
        this.$refs.confirmRef.validate()
      }

    }
  }
</script>
