/**
 * Utilities to send a logging message event to the Logger component which is embedded in the App component
 *
 * From any component call:
 *
 * import Logger from '../utils/logger-utils'
 * Logger.error(new VError(`cannot open file ${filename}`))
 *
 * Passing a VError object to Logger.error() automatically captures filename and line number.
 *
 * if error occurs inside try / catch, stack the errors with  ...
 *
 *  try {
 *  }
 *   catch (error) => {
 *    Logger.error(new VError(error, `cannot open file ${filename}`))
 *  }
 *
 *
 * The error messages are displayed:
 * - on screen : by displaying the message summary in a Snackbar
 * - in the console : the error.stack() is displayed
 *
 * Message are sent via the system Event bus, so messages may be logged from any thread.
 *
 * Multiple messages can be displayed at a time. The messages persist until the user dismisses them.
 */


import {EventBus} from "./event-bus"
import TabId from "./tab-id"
import moment from "moment"
import {detect} from 'detect-browser'


function handleDebugEvent(msg, callerFunction, appId) {
  const now = moment().format("HH:mm:ss:SSS")
  console.info(now, appId, callerFunction, msg)
}

EventBus.$on('logger-debug-event', handleDebugEvent)

const Logger = {

  /**
   * error - utility method, called by components to log an error on the event bus. Handled in LoggerComponent.vue
   * and displays the msg in the on screen
   *
   * @param {VError} err : object containing error stack
   * @param {object} [opts] - see Toasted options object https://github.com/shakee93/vue-toasted, optional
   */
  error(err, opts) {
    EventBus.$emit('logger-error-event', err, opts)
  },

  /**
   *
   * @param {string} msg
   * @param {string} [callerFunc] - optional, the name of the calling function, used for display purposes,
   *                              the method attempts to calculate it if it is not passed
   */
  debug(msg, callerFunc) {
    const browser = detect()

    let callerFunction = ''
    if ( typeof callerFunc !== 'undefined') {
      callerFunction = callerFunc
    } else {

      // to calculate the name of the calling function we use the trick to generate an Error object and look at the
      // call stack.
      const err = (new Error)
      const stack = err.stack.split("\n")

      // each browser type constructs the Error object different, so we need to parse differently
      switch (browser && browser.name) {
        case 'chrome': {
          const callerLine = stack[2]
          const callerLineSplit = callerLine.split(" ")
          callerFunction = callerLineSplit[5]
          break
        }

        case 'firefox': {
          const callerLine = stack[1]
          const callerLineSplit = callerLine.split("@")
          callerFunction = callerLineSplit[0]
          break
        }

        case 'safari': {
          callerFunction = stack[1]
          break
        }

        case 'edge': {
          callerFunction = '' // not supported yet
          break
        }

        default:
          callerFunction = '' // not supported yet
      }
    }

    EventBus.$emit('logger-debug-event', msg, callerFunction, TabId.getInstance())
  },
  /**
   * info - utility method, called by components to log message on the event bus. Handled in LoggerComponent.vue
   * and displays the msg in the on screen
   *
   */
  info(msg) {
    EventBus.$emit('logger-info-event', msg)
  },

}

export default Logger
