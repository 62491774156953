<template>
  <div>
    <v-container grid-list-md text-xs-center>
      <v-layout row pb-4>
        <v-flex xs12>
          <span class="display-1 body_text">mySymptoms Pricing</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm1></v-flex>
        <!-- free plan -->
        <v-flex xs6 sm5>
          <v-card class="mx-4 plan_item">
            <v-card-title class="justify-center display-1 pb-2 highlight_text font-weight-bold">
              Free
            </v-card-title>
            <v-card-text class="body_text body-1 pt-0">
              Try mySymptoms with your clients
            </v-card-text>
            <v-card-text class="pb-5">
              <p class="body_text display-1">{{ getPlanPrice(level1) }}</p>
              <p class="body_text body-1">per professional, per month, billed monthly</p>
            </v-card-text>
            <v-divider/>
            <v-card-text class="px-0 ">
              <v-list class="pl-4">
                <v-list-tile v-for="item in level0Features" :key="item">
                  <v-list-tile-action>
                    <v-icon color="primary">check</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="white-space: normal">{{ item }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card-text>
            <v-card-actions class="justify-center pb-3">
              <v-btn
                  @click="checkoutLevel(level1)"
                  :disabled="levelDisabled(level1)"
                  color="primary"
                  slot="activator"
              >{{
                  buttonText(level1.name)
                }}
              </v-btn>
            </v-card-actions>
            <v-card-text v-if="this.currentPlan.status==='deleted'">
              <!--            the placement of this element only works if there are two plans ... one premium and one free-->
              This plan is scheduled to become active on {{ this.getFormattedCancellationEffectiveDate() }}
            </v-card-text>
          </v-card>
        </v-flex>

        <!-- premium plan -->
        <v-flex xs6 sm5>
          <v-card class="mx-4 plan_item">
            <v-card-title class="justify-center display-1 highlight_text pb-2 font-weight-bold">
              Premium
            </v-card-title>
            <v-card-text class="body_text body-1 pt-0">
              for doctors, dietitians, nutritionists and health coaches
            </v-card-text>
            <v-card-text class="">
              <p class="body_text display-1 "> {{ getPlanPrice(level2) }}
              </p>
              <p>
                <span class="special-offer">Introductory Offer!!</span></p>
              <p class="body_text body-1">per professional, per month, billed monthly</p>
            </v-card-text>
            <v-divider/>
            <v-card-text class="px-0 ">
              <v-list class="pl-4">
                <v-list-tile v-for="item in level1Features" :key="item">
                  <v-list-tile-action>
                    <v-icon color="primary">check</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-sub-title style="white-space: normal">{{ item }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card-text>

            <v-card-actions class="justify-center pb-3">
              <v-btn
                  @click="checkoutLevel(level2)"
                  :disabled="levelDisabled(level2)"
                  color="primary"
              >{{
                  buttonText(level2.name)
                }}
              </v-btn>
            </v-card-actions>

            <v-card-text v-if="this.currentPlan.status==='deleted'">
              Your plan is scheduled to be cancelled on {{ this.getFormattedCancellationEffectiveDate() }}
            </v-card-text>

            <!-- dialogue asking for the free period code ... for clinicians on the beta trial -->
            <v-row v-if="!levelDisabled(level2)" justify="center">
              <v-dialog
                  v-model="showFreeTrialDialogue"
                  persistent
                  max-width="400px"
              >
                <template v-slot:activator="{ on }">
                  <!--                  <v-list-tile-action-text v-if="freeTrialCodeValidated"><a href="#" v-on="on">Your complimentary period will be applied at checkout</a></v-list-tile-action-text>-->
                  <v-list-tile-action-text><a href="#" v-on="on">Beta Trial Participant?</a></v-list-tile-action-text>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="title">Activate Complimentary Period</span>
                  </v-card-title>
                  <v-card-text>
                    If you participated in our Beta trial, please enter the code to subscribe to the premium plan with
                    your complimentary period
                  </v-card-text>
                  <v-card-text>
                    <v-text-field
                        label=" Code"
                        required
                        v-model="freeTrialCode"
                        :error-messages="freeTrialCodeErrors"
                        @keydown="clearFreeTrialCodeErrors"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="showFreeTrialDialogue = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        default
                        @click="freeTrialDialogueEnter"
                    >
                      Subscribe
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card>
        </v-flex>
        <v-flex sm1></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs2></v-flex>
        <v-flex xs8>
          <p v-show="isCurrentPlanLegacy()">
            You plan: {{ currentPlan.displayName }} is no longer available for new subscriptions. You can stay on your
            current
            plan or subscribe to one of the news plans above.
          </p>
        </v-flex>
        <v-flex xs2></v-flex>
      </v-layout>
    </v-container>

    <!-- pop-up to let user know they are about to upgrade -->
    <v-container fluid>
      <div class="text-xs-center">
        <v-dialog v-model="showPremiumUpgradeWarning" persistent width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Subscription Upgrade
            </v-card-title>
            <v-card-text>
              You are about to change your subscription plan. If you are moving to a more expensive plan,
              your credit card will be billed immediately
              for the remainder of the month at the new plan rate.
              Do you wish to continue?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" flat @click="handlePremiumUpgradeWarningNo">No</v-btn>
              <v-btn color="primary" flat @click="handlePremiumUpgradeWarningYes">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>

    <PaddleCheckout
        v-bind:username.sync='username'
        v-bind:email.sync='email'
        v-bind:showCheckout.sync='showCheckout'
        v-bind:country.sync='country'
        v-bind:postcode.sync='postcode'
        v-bind:paddleId.sync='pendingNewPlanId'
        v-bind:planName.sync='pendingNewPlan'
        v-bind:referrerSuccessCallback='() => this.paddleSuccessCallback(pendingNewPlan)'
        v-bind:referrerCloseCallback='() => { this.showCheckout = false}'
        v-bind:overrideURL.sync='freeTrialOverrideURL'
    >
    </PaddleCheckout>

    <!-- pop-up to let user know they are about to cancel a premium subs-->
    <CancelPremiumSubs
        v-bind:showCancelPremiumSubsWindow.sync='showCancelPremiumSubsWindow'
    >
    </CancelPremiumSubs>
  </div>
</template>

<script>
import Logger from '@/utils/logger-utils'
import ClinicianAccountService from '@/utils/clinician-account-service'
import Loader from '@/utils/loader-utils'
import SubscriptionPlanService from '@/utils/subscription-plan-service'
import CountryToCurrency from '@/utils/country-to-currency-codes'
import PaddleCheckout from '@/components/PaddleCheckout'
import CancelPremiumSubs from "@/components/CancelPremiumSubs";
import {EventBus} from "@/utils/event-bus";
import moment from "moment-timezone";
import {getPlan} from "@/store/store"
import VError from "verror";

export default {
  components: {PaddleCheckout, CancelPremiumSubs},
  name: "Plans",
  data() {
    return {
      showFreeTrialDialogue: false,
      freeTrialCode: '',
      freeTrialCodeValidated: false,
      freeTrialOverrideURL: '',
      freeTrialCodeErrors: [],
      email: '',
      country: '',
      postcode: '',
      showPremiumUpgradeWarning: false, // controls a pop-up warning the user they are moving between premium plans
                                        // and their credit card with be immediately charged / refunded
      showCancelPremiumSubsWindow: false,
      // showPremiumCancelWarning: false, // controls a pop-up warning user they are about to cancel a premium plan
      // showSurvey: false,
      // which will remain active until the next billing date
      pendingNewPlan: '',
      pendingNewPlanId: 0,
      // levels: [],
      // used to hold the level info we get back from the db, contains the fields:
      //         {
      //            name: "level2",
      //            isPremium: true,  // are we charging for it?
      //            paddleId: 20612, // null if not isPremium
      //            displayName: 'Premium',
      //            priceUSD: '19.99', // null if not isPremium
      //            priceGBP: '14.99', // null if not isPremium
      //            priceEUR: '17.99', // null if not isPremium
      //        },
      levels: [
        // used to hold the level info we get back from the db
        {
          // name: this.level1Name,
          name: SubscriptionPlanService.level1Name,
          paddleId: 0,
          isPremium: false,
          displayName: '',
          priceUSD: '',
          priceGBP: '',
          priceEUR: '',
        },
        {
          name: SubscriptionPlanService.level2Name,
          paddleId: 0,
          isPremium: false,
          displayName: '',
          priceUSD: '',
          priceGBP: '',
          priceEUR: '',
        }
      ],
      showCheckout: false
    }
  },

  methods: {
    clearFreeTrialCodeErrors() {
      this.freeTrialCodeErrors = []
    },

    freeTrialDialogueEnter() {
      const level2 = this.getLevel(SubscriptionPlanService.level2Name)
      const that = this

      SubscriptionPlanService.getTrialUrl(level2.paddleId, 183, this.freeTrialCode).then(res => {

        that.freeTrialOverrideURL = res.data
        this.freeTrialCodeValidated = true
        this.showFreeTrialDialogue = false

        this.checkoutLevel(level2)

      }).catch(err => {
        const cause = VError.cause(err)
        if (cause.response.data.code == "INVALID_CODE") {
          that.freeTrialCodeErrors = ["Invalid Code"]
        } else {
          this.showFreeTrialDialogue = false
          Logger.error(err)
        }
      })

    },

    getFormattedCancellationEffectiveDate() {
      const formattedDate = (this.currentPlan.cancellationEffectiveDateMom instanceof moment) ?
          this.currentPlan.cancellationEffectiveDateMom.format("D MMM YYYY ") : ""
      return formattedDate
    },

    getLang() {
      return navigator.language || navigator.browserLanguage || (navigator.languages || ["en"]) [0]
    },

    getPlanPrice(level) {

      let priceFmt;
      // the levels may not yet have been populated from the server
      if (!level) {
        return ""
      }

      const ccy = CountryToCurrency.CountryToCurrency[this.country]
      let price;
      if (level.isPremium) {
        // show the prices in the default currency for their registered country
        if (ccy === 'GBP') {
          price = level.priceGBP
        } else if (ccy === 'EUR') {
          price = level.priceEUR
        } else {
          price = level.priceUSD
        }
      } else {
        price = 0.0
      }
      // ccy is populated from a server callback, it might not be populated yet
      if (ccy) {
        priceFmt = new Intl.NumberFormat(this.getLang(), {style: 'currency', currency: ccy}).format(price)
      } else {
        priceFmt = ''
      }

      return priceFmt
    },

    getLevel(levelName) {
      const level = _.find(this.levels, ['name', levelName])
      return level

    },
    // is the user on a active or legacy plan?
    isCurrentPlanLegacy() {
      return this.currentPlan.name !== this.levels[0].name
          && this.currentPlan.name !== this.levels[1].name
      // && this.currentPlan.name !== this.levels[2].name
    },

    checkoutLevel(level) {
      this.checkout(level.isPremium, level.paddleId, level.name)
    },

    levelDisabled(level) {
      const disabled = !this.currentPlan.name || level.name === this.currentPlan.name
          || this.currentPlan.status === 'deleted'
      return disabled
    },

    checkout(isNewPlanPremium, newPlanPaddleId, newPlanName) {

      if (isNewPlanPremium) {
        this.pendingNewPlan = newPlanName
        this.pendingNewPlanId = newPlanPaddleId
        if (this.currentPlan.isPremium) {
          // if the user is already on a premium plan we are updating to another premium plan then
          // we just need to send an update to Paddle, but pop up a warning to let the user know their card will be
          // charged / refunded
          // this pops up the dialogue box asking user to continue
          this.showPremiumUpgradeWarning = true
        } else {
          // launch the paddle checkout window
          // this.checkoutFreeToPremium(newPlanPaddleId, newPlanName)
          this.showCheckout = true
        }
      } else {
        // open popup to tell user their subs will be cancelled
        // this.showPremiumCancelWarning = true
        this.showCancelPremiumSubsWindow = true
        // this.pendingNewPlan = newPlanName

      }
    },

    paddleSuccessCallback(newPlanName) {
      // this gets called when the checkout has completed successfully. The payment might still fail but we'll
      // deal with that separately.
      this.showCheckout = false
      // set a timer to wait for a few secs to the server has chance to update with the new subscription
      setTimeout(() => {
        // Logger.debug("emitting event for updating subs plan")
        EventBus.$emit('update-subscription-plan')
      }, 3000)
      this.$router.push({name: 'home'})
      Logger.info(`Plan changed.`)
    },

    getSubscribeButtonText(levelName) {
      let buttonText = ''
      if (levelName === this.currentPlan.name) {
        buttonText = 'Current Plan'
      } else {
        buttonText = 'Subscribe'
      }
      return buttonText
    },

    // called when a user clicks Yes on the 'you're about to switch from one premium subscription to another'
    // however, if we're in the cancellation period of the previous subs, they need to checkout as though it's a new
    // subscription
    handlePremiumUpgradeWarningYes() {

      if (this.currentPlan.status === 'deleted') {
        // if we're in the cancellation period, we need to check out as though it's a new subscription
        this.showPremiumUpgradeWarning = false
        this.showCheckout = true

      } else {
        Loader.start()
        const that = this
        ClinicianAccountService.updatePremiumSubscriptionPlan(this.$store.state.username, this.pendingNewPlan).then(() => {
          // wait a second before triggering an update for the subscription plans we hold in the store
          setTimeout(() => {
            EventBus.$emit('update-subscription-plan')
          }, 3000)

          that.pendingNewPlan = ''
          that.pendingNewPlanId = ''
          Loader.stop()
          that.showPremiumUpgradeWarning = false
          this.$router.push({name: 'home'})
          Logger.info(`Plan changed.`)
        }).catch(err => {
          that.pendingNewPlan = ''
          that.pendingNewPlanId = ''
          that.showPremiumUpgradeWarning = false
          Loader.stop()
          Logger.error(err)
        })
      }
    },

    handlePremiumUpgradeWarningNo() {
      this.showPremiumUpgradeWarning = false
    },

    buttonText(levelName) {
      return this.getSubscribeButtonText(levelName)
    },
  },

  computed: {
    level1() {
      return this.getLevel(SubscriptionPlanService.level1Name)
    },

    level2() {
      return this.getLevel(SubscriptionPlanService.level2Name)
    },

    // level3() {
    //   return this.getLevel(this.level3Name)
    // },

    username() {
      return this.$store.state.username
    },

    currentPlan() {
      return getPlan()
      // return this.$store.state.plan
    },

    level0Features() {
      return ["Upto 3 clients"].concat(this.commonFeatures)
    },

    level1Features() {
      return ["Unlimited clients"].concat(this.commonFeatures)
    },

    commonFeatures() {
      return [
        "Real-time view of your client's food and symptom diaries",
        'Search meals, ingredients and symptoms',
        'Clients can track other health and lifestyle factors',
        'HIPAA Compliant',
        'GDPR (Health) Compliant',
        'Symptom trend charts',
      ]
    }
  },

  created() {
    const that = this

    Loader.start();

    ClinicianAccountService.getClinicianAccountInfo(this.$store.state.username).then(resp => {
      that.email = resp.data.email
      that.postcode = resp.data.zip
      that.country = resp.data.country

      return SubscriptionPlanService.getSubscriptionPlans(this.levels[0].name, this.levels[1].name)
      // , this.levels[2].name)
    }).then((resp) => {
      that.levels = _.map(resp.data, level => {
        return {
          name: level.name,
          displayName: level.displayName,
          isPremium: level.premium,
          paddleId: level.paddleId,
          priceUSD: level.priceUSD,
          priceGBP: level.priceGBP,
          priceEUR: level.priceEUR
        }
      })
      Loader.stop();

    }).catch(err => {
      Logger.error(err)
      Loader.stop()
    })
  },

  watch: {}

}
</script>

<style lang="scss">
.plan_item {
  /*padding: 5px;*/
  /*background-color: var(--v-secondary-lighten3);*/
  /*color: white;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
  /*border-color: #dcdcdc;*/
}

.body_text {
  color: var(--v-on-primary)
}

.highlight_text {
  color: var(--v-primary-base)
}

.special-offer {
  font-size: 15px;
  font-weight: 700;
  background-color: var(--v-tertiary-lighten3);
  color: var(--v-on-secondary);
  padding: 10px;
  margin: 0 auto 40px;
  border-radius: 5px;
  letter-spacing: 0.5px;
}
</style>
