<template>
  <div>
    <p>
      Scatter Chart
    </p>
    <!--    <canvas id="myChart5" height="200" width="400" style="border:1px solid #000000;"></canvas>-->
    <canvas id="myChart6" height="200" width="400" style="border:1px solid #000000;"></canvas>
    <canvas id="myChart5" height="200" width="400" style="border:1px solid #000000;"></canvas>
  </div>
</template>

<script>
import {
  ScatterChart,
  // LineChart
} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation"

Chart.register(annotationPlugin, ...registerables);

const DATA_COUNT = 7;
const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function months(config) {
  var cfg = config || {};
  var count = cfg.count || 12;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}

const labels = months({count: 7});

var _seed = Date.now();

function srand(seed) {
  _seed = seed;
}

function rand(min, max) {
  min = valueOrDefault(min, 0);
  max = valueOrDefault(max, 0);
  _seed = (_seed * 9301 + 49297) % 233280;
  return min + (_seed / 233280) * (max - min);
}

export default {
  name: "PatientDiary",
  props: ['patientUsername', 'numResultsProp'],
  components: {
    // ScatterChart,
    // LineChart
  },

  data() {
    return {
      chartData1: {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      },
      chartOptions1: {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        }
      },
      chartData2: {
        datasets: [
          {
            "backgroundColor": "#f87979",
            // "label": "my label",
            "data": [
              {
                "x": "2022-02-21T18:55:08.000Z",
                "y": 2
              },
              {
                "x": "2022-02-22T14:55:28.000Z",
                "y": 4
              }
            ]
          },
          // {
          //   type: 'line',
          //   label: 'Line Dataset',
          //   data: [5, 5, 5, 5],
          //   fill: false,
          //   borderColor: 'rgb(54, 162, 235)'
          // }
        ]
      },
      chartOptions2: {
        // "maintainAspectRatio": false,
        // "aspectRatio": 2,
        layout: {
          padding: {
            top: 30,
            bottom: 300,
          }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": false
          },
          // annotation: {
          //   annotations: {
          //     box1: {
          //       // Indicates the type of annotation
          //       type: 'box',
          //       xMin: "2022-02-21T00:00:00Z",
          //       xMax: "2022-02-23T00:00:00Z",
          //       yMin: 2,
          //       yMax: 1,
          //       backgroundColor: 'rgba(255, 99, 132, 0.25)'
          //     }
          //   }
          // }
        },
        "legend": {
          "display": true
        },
        "scales": {
          "x": {
            "type": "time",
            "time": {
              "unit": "day",
              "displayFormats": {
                "day": "DD MMM"
              }
            },
            "min": "2022-02-20T00:00:00.000Z",
            "max": "2022-02-25T11:04:44.598Z"
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
          // annotation: {
          //   annotations: [
          //     {
          //       type: 'box',
          //       xMin: 1,
          //       xMax: 2,
          //       yMin: 50,
          //       yMax: 70,
          //       backgroundColor: 'rgba(255, 99, 132, 0.25)'
          //     }
          //   ]
          // }
        }
      },
      chartData3: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [{
          label: 'My First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      chartOptions3: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              box1: {
                // Indicates the type of annotation
                type: 'box',
                xMin: 1,
                xMax: 2,
                yMin: 50,
                yMax: 70,
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
              }
            }
          }
        }
      },
      chartData4: {
        "labels": labels,
        datasets: [
          {
            // data: [[1,2],[2,3]],
            // data: [1,2,3],
            // data: labels.map(() => {
            //   // return [rand(-100, 100), rand(-100, 100)];
            //   return [10,20]
            // }),
            label: 'set1',
            data: [[0, 1], [0, 1], [0, 0]],
            // data: [1,2,3,4],
            backgroundColor: 'rgb(255, 99, 132)'
          },
          {
            label: 'set2',
            // data: [[1, 2], [2, 3], [4, 5]],
            data: [[0, 1], [0, 0], [0, 1]],
            // data: [1,2,3,4],
            backgroundColor: 'rgb(14, 99, 132)'
          }
        ]
      },
      chartOptions4: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Floating Bar Chart'
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      },
      chartData5: {
        labels: ['January', 'February', 'March', 'April'],
        datasets: [{
          type: 'bar',
          label: 'Bar Dataset',
          data: [10, 20, 30, 40],
          backgroundColor: 'rgb(14, 99, 132)'
        }, {
          type: 'line',
          label: 'Line Dataset',
          data: [50, 50, 50, 50],
          backgroundColor: 'rgb(14, 99, 0)'
        }],
      },
      chartOptions5: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Mixed Chart'
          }
        },
        // scales: {
        //   x: {
        //     stacked: true,
        //   },
        //   y: {
        //     stacked: true
        //   }
        // }
      },
      chartData6: {
        datasets: [
          {
            type: 'scatter',
            "backgroundColor": "#f87979",
            "label": "Nausea",
            "data": [
              {
                "x": "2022-02-21T18:55:08.000Z",
                "y": 2
              },
              {
                "x": "2022-02-22T14:55:28.000Z",
                "y": 4
              }
            ]
          },
          {
            type: 'bar',
            label: 'Cheese',
            data: [{
              "x": "2022-02-21T10:55:08.000Z",
              "y": 10,
            },
              {
                "x": "2022-02-22T12:55:28.000Z",
                "y": 10
              }
            ],
            fill: false,
            borderColor: 'rgb(54, 162, 235)',
            barThickness: '5',
            backgroundColor: 'rgb(14, 99, 255, 0.3)'
          },
          {
            type: 'bar',
            label: 'Eggs',
            data: [{
              "x": "2022-02-20T08:55:08.000Z",
              "y": 10
            },
              {
                "x": "2022-02-24T12:55:28.000Z",
                "y": 10
              }
            ],
            fill: false,
            // borderColor: 'rgb(54, 162, 235)',
            barThickness: '5',
            backgroundColor: 'rgb(114, 199, 100, 0.3)'
          }
        ]
      },
      chartOptions6: {
        // "maintainAspectRatio": false,
        // "aspectRatio": 2,
        layout: {
          // padding: {
          //   top: 30,
          //   bottom: 300,
          // }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": true
          },
        },
        "legend": {
          "display": false
        },
        "scales": {
          "x": {
            "type": "time",
            "time": {
              "unit": "day",
              "displayFormats": {
                "day": "DD MMM"
              }
            },
            "min": "2022-02-20T00:00:00.000Z",
            "max": "2022-02-25T11:04:44.598Z"
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
        }
      }
    }
  },
  mounted() {
    // const i = this.$refs.scatterRef
    // const image = this.$refs.scatterRef.chartInstance.toBase64Image()
    // const ctx = document.getElementById('myChart2')
    // const plugin = {
    //   id: 'custom_canvas_background_color',
    //   beforeDraw: (chart, args, options) => {
    //     // const ctx = chart.canvas.getContext('2d');
    //     // ctx.save();
    //     // ctx.globalCompositeOperation = 'destination-over';
    //     // ctx.fillStyle = 'lightGreen';
    //     // ctx.fillRect(0, 0, chart.width, chart.height);
    //     // ctx.restore();
    //     const { ctx,chartArea: { top, right, bottom, left, width, height}, scales: {x, y}} = chart;
    //     ctx.save();
    //     ctx.strokeStyle = 'blue'
    //     // ctx.strokeRect(0,chart.height, 200, chart.height)
    //     ctx.strokeRect(left,(bottom + 100), 10, 50)
    //     // chart.height = 1500
    //     // ctx.strokeRect(0, 10, 200, 0)
    //     // chart.
    //     ctx.restore();
    //     const i = 0
    //   }
    // };
    // const myChart2 = new Chart(ctx, {
    //   type: 'scatter',
    //   data: this.chartData2,
    //   options: this.chartOptions2,
    //   plugins: [plugin]
    // })
    // myChart2;
    const ctx5 = document.getElementById('myChart5')
    const myChart5 = new Chart(ctx5, {
      // type: 'bar',
      data: this.chartData5,
      options: this.chartOptions5,
      // plugins: [plugin]
    })
    myChart5;
    const ctx6 = document.getElementById('myChart6')
    const myChart6 = new Chart(ctx6, {
      type: 'scatter',
      data: this.chartData6,
      options: this.chartOptions6,
      // plugins: [plugin]
    })
    myChart6;
  },
  methods: {}
}
</script>
