<template>
  <div>
    <v-container grid-list-md text-xs-center>
      <v-layout row pb-4>
        <v-flex xs12>
          <span class="display-1 body_text">Subscription</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs2></v-flex>
        <v-flex xs8>
          <v-layout row wrap>
            <v-flex xs6>
              <v-card class="rounded-card">
                <v-list two-line subheader>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Current Plan
                        <v-tooltip bottom>
                          <v-icon slot="activator" @click="this.refreshPlan">sync</v-icon>
                          <span>Refresh</span>
                        </v-tooltip>
                      </v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.plan.displayName }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action-text><a href="#" @click="goToPlans">Change</a></v-list-tile-action-text>
                  </v-list-tile>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Status</v-list-tile-title>
                      <v-list-tile-sub-title v-bind:class="{'error--text': isWarningStatus}">{{
                          this.formattedStatus
                        }}
                      </v-list-tile-sub-title>
                      <!--                      <v-list-tile-sub-title class="error&#45;&#45;text">{{ this.formattedStatus }}</v-list-tile-sub-title>-->
                    </v-list-tile-content>
                  </v-list-tile>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Number of Clients</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.formattedNumberOfClients }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>

                  <v-list-tile v-show="this.isActivePendingPaused">
                    <v-list-tile-content>
                      <v-list-tile-title>Paused From</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.formattedPausedFromDate }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-content>
                      <v-list-tile-title>Paused Reason</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.formattedPausedReason }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>

                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs6>
              <v-card class="rounded-card">
                <v-list two-line subheader>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Next Payment</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.formattedNextPayment }}</v-list-tile-sub-title>
                      <!--                      {{ this.formatPaymentPrice(props.plan.saleGross, props.item.currency) }}-->
                      <v-list-tile-sub-title>
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>

                  <div v-show="this.isSubscriptionPremium && !this.isActivePendingCancelled">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title>Next Payment Date</v-list-tile-title>
                        <v-list-tile-sub-title>{{ this.formattedNextBillDate }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action-text>
                        <!--                      a bit of hack, we want the Cancel link to appear as a link but function like a clickable button -->
                        <a href="#" @click="() => showCancelPremiumSubsWindow = true">Cancel</a>
                      </v-list-tile-action-text>
                    </v-list-tile>

                    <div v-show="this.hasCoupon">
                      <v-list-tile>
                        <v-list-tile-content>
                          <v-list-tile-title>Coupon Applied</v-list-tile-title>
                          <v-list-tile-sub-title>{{ this.coupon.couponCode }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-content>
                          <v-list-tile-title>Coupon Discount</v-list-tile-title>
                          <v-list-tile-sub-title>{{ this.formattedCouponDiscount }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </div>
                  </div>

                  <v-list-tile v-show="this.isActivePendingCancelled">
                    <v-list-tile-content>
                      <v-list-tile-title>Cancellation Effective From</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.formattedCancellationEffectiveDate }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>

                </v-list>
              </v-card>
            </v-flex>
            <v-flex xs0 sm1></v-flex>
          </v-layout>

          <!-- 'Past due' notification -->
          <v-layout row wrap v-show="this.isPastDue">
            <v-flex>
              <v-card class="rounded-card error-card">
                <v-card-text>* The last {{ this.plan.retryNumber }} attempt(s) to collect payment has failed. To ensure
                  continued access to the service, please check your payment details below and <a
                      :href="this.plan.updatePaymentMethodUrl" target="_blank">update</a> if necessary. If you don't
                  update your payment method we will try again on: {{ formatDate(this.plan.nextRetryDateMom) }}.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <!-- 'Paused' notification -->
          <v-layout row wrap v-show="this.isPaused">
            <v-flex>
              <v-card class="rounded-card error-card">
                <v-card-text>* Your subscription plan has been paused because we have been unable to collect payment.
                  Access to certain features have been restricted. Please update your payment details or contact support
                  to restart your subscription.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>


          <!--        Payment details Section -->
          <v-layout row wrap v-show="this.isSubscriptionPremium && !this.isActivePendingCancelled">
            <v-flex>
              <v-card class="rounded-card">
                <v-card-title>Payment Details</v-card-title>
                <v-list two-line subheader>
                  <!--                <v-subheader>Payment Details</v-subheader>-->
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Method</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.getPaymentMethodDisplay }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action-text>
                      <a :href="this.plan.updatePaymentMethodUrl" target="_blank">Change</a>
                    </v-list-tile-action-text>
                  </v-list-tile>
                  <v-list-tile v-show="this.plan.paymentMethod === 'card'">
                    <v-list-tile-content>
                      <v-list-tile-title>Last 4 digits</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.plan.lastFourDigits }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>

                  <v-list-tile v-show="this.plan.paymentMethod === 'card'">
                    <v-list-tile-content>
                      <v-list-tile-title>Expiry</v-list-tile-title>
                      <v-list-tile-sub-title>{{ this.plan.expiryDate }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex>
              <v-card class="rounded-card">
                <v-card-title><h4>Payments</h4></v-card-title>
                <v-divider></v-divider>
                <v-data-table
                    :headers="paymentHeaders"
                    :items="payments"
                    no-data-text="No payments"
                    class="elevation-1"
                    v-bind:pagination.sync="pagination"
                >
                  <template v-slot:items="props">
                    <td>{{ formatDate(props.item.date) }}</td>
                    <td>{{ props.item.planName }}</td>
                    <td class="text-xs-right">{{ formatPaymentPrice(props.item.saleGross, props.item.currency) }}</td>
                    <td class="text-xs-right"><a :href="props.item.receiptUrl" target="_blank">View Invoice</a></td>
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2></v-flex>
      </v-layout>
    </v-container>
    <!--    </div>-->
    <!--    <v-spacer/>-->
    <CancelPremiumSubs
        v-bind:showCancelPremiumSubsWindow.sync='showCancelPremiumSubsWindow'
    >
    </CancelPremiumSubs>
  </div>
</template>

<script>

import ClinicianAccountService from "@/utils/clinician-account-service"
import moment from 'moment-timezone'
import Logger from '../utils/logger-utils'
import _ from 'lodash'
import {EventBus} from "@/utils/event-bus";
import {getPlan} from "@/store/store"
import CancelPremiumSubs from "@/components/CancelPremiumSubs";
import SubscriptionPlanService from "@/utils/subscription-plan-service"
import Loader from "@/utils/loader-utils";
import VError from "verror";

export default {
  name: "MySubscription",
  components: {CancelPremiumSubs},
  data() {
    return {
      showCancelPremiumSubsWindow: false,
      coupon: {
        couponCode: '',
        discountType: '',
        discountAmount: 0,
        discountCurrency: '',
        expires: '',
      },
      payments: [],
      paymentHeaders: [
        {text: 'Date', align: 'left', sortable: true, value: 'date'},
        {text: 'Plan', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
        {text: 'View Invoice', value: 'view', sortable: false},
      ],
      pagination: {'sortBy': 'dateSortable', 'descending': true, 'rowsPerPage': 25},
    }
  },


  created() {

    // make sure the subs plan is up to date in the store
    EventBus.$emit('update-subscription-plan')


  },
  computed: {
    //  use the helper function in the store.js to get the plan details
    plan() {
      return getPlan()
    },

    // is the status such that we want to warn the user
    isWarningStatus() {
      return this.isPaused || this.isPastDue
    },
    // if payment method is 'card' show the card type e.g. visa, otherwise show the payment method e.g. paypal
    getPaymentMethodDisplay() {
      return (this.plan.paymentMethod === "card") ? this.plan.cardType : this.plan.paymentMethod
    },
    isSubscriptionPremium() {
      // might need a better way of calculating ...
      return this.plan.isPremium
    },
    isActive() {
      return this.plan.status === 'active' || this.isActivePendingCancelled
    },
    // note that when a subscription is cancelled, the status changes to cancelled immediately, but the cancellation only
    // comes into effect at some point in the future ... so the status should really be 'active - pending cancelled'
    // so we add in an extra state to clarify:
    // 'activePendingCancelled = the subscription has been cancelled but we are before the cancellation Effective Date
    // 'cancelled' = we are past the cancellation effective date
    //
    // Note we shouldn't see a truly cancelled subscription since when a subscription is cancelled there is a free subs
    // created that immediately follows it, so we see the free subscription instead
    // isCancelled() {
    //   return this.plan.cancellationEffectiveDateMom !== ""
    // },
    isActivePendingCancelled() {
      return this.plan.cancellationEffectiveDateMom !== ""
    },
    isActivePendingPaused() {
      const inPausedPendingPeriod = (this.plan.pausedFromMom instanceof moment) ? moment().isBefore(this.plan.pausedFromMom) : false
      return this.plan.status === 'active' && inPausedPendingPeriod
    },
    // we are past the paused effective from date
    isPaused() {
      // return this.plan.pausedFromMom !== ""
      return this.plan.status === 'paused'
    },
    isPastDue() {
      return this.plan.status === 'past_due'
    },
    formattedNextBillDate() {
      // const formattedDate =PastDue (this.plan.nextBillDate instanceof moment) ? this.plan.nextBillDate.format("D MMM YYYY") : ""
      // const formattedDate = (this.plan.nextBillDateMom instanceof moment) ? this.plan.nextBillDateMom.format("D MMM YYYY") : ""

      const formattedDate = this.lastPayment ? this.lastPayment.nextBillDate.format("D MMM YYYY") : ''
      return formattedDate
    },

    formattedCancellationEffectiveDate() {
      const formattedDate = (this.plan.cancellationEffectiveDateMom instanceof moment) ?
          this.plan.cancellationEffectiveDateMom.format("D MMM YYYY ") : ""
      return formattedDate
    },
    formattedCouponExpires() {
      const formattedDate = (this.coupon.expires instanceof moment) ?
          this.coupon.expires.format("D MMM YYYY ") : ""
      return formattedDate
    },

    formattedCouponDiscount() {
      let discount
      if (this.coupon.discountType === '') {
        discount = ''
      } else if (this.coupon.discountType === 'percentage') {
        discount = (100 * this.coupon.discountAmount) + '%'
      } else if (this.coupon.discountType === 'flat') {
        discount = this.coupon.discountCurrency + this.coupon.discountAmount
      } else {
        Logger.error(`unknown discount type: ${this.coupon.discountType}`)
      }
      return discount
    },
    formattedCreatedDate() {
      const formattedDate = (this.plan.createdAtMom instanceof moment) ? this.plan.createdAtMom.format("D MMM YYYY ") : ""
      return formattedDate
    },
    formattedPausedFromDate() {
      const formattedDate = (this.plan.pausedFromMom instanceof moment) ? this.plan.pausedFromMom.format("D MMM YYYY ") : ""
      return formattedDate
    },
    /**
     * convert the pause reasons to something a bit more presentable:
     *    'delinquent' -> 'Payment Failed'
     *    'voluntary' -> 'Manual'
     * @returns {*}
     */
    formattedPausedReason() {
      let formattedReason;
      if (this.plan.pausedReason === 'delinquent') {
        formattedReason = 'Payment Failed'
      } else if (this.plan.pausedReason === 'voluntary') {
        formattedReason = 'Manual'
      } else {
        // shouldn't get here, but leave it as an option just in case
        formattedReason = _.startCase(this.plan.pausedReason)
      }
      return formattedReason
    },

    lastPayment() {
      const latestPayment = _.maxBy(this.payments, x => x.dateSortable)
      return latestPayment
    },

    // get coupon code applied to last payment and assume it's going to be applied to next payment
    // couponCode() {
    //   let couponCode;
    //   if (this.lastPayment) {
    //     couponCode = this.lastPayment.couponCode ? this.lastPayment.couponCode : ''
    //   } else {
    //     couponCode = ''
    //   }
    //   return couponCode
    // },

    hasCoupon() {
      return this.coupon.couponCode != ''
    },

    // couponDetails() {
    //   const couponInfo = SubscriptionPlanService.getCouponDetails(this.couponCode)
    //   return couponInfo
    // },

    formattedNextPayment() {
      // when the page first loads the price is not yet populated, need to wait until it's brought from the server

      let price

      if (this.plan.isPremium) {

        // const latestPayment = this.lastPayment

        // get the last payment
        price = this.lastPayment ? this.formatPaymentPrice(this.lastPayment.nextPaymentAmount, this.plan.currency) : ''
        // let price = ""
        // if (this.plan.nextPaymentAmount) {
        // Logger.debug(this.plan)
        // price = new Intl.NumberFormat(this.getLang(), {
        //   style: 'currency',
        //   currency: this.plan.currency
        // }).format(this.plan.nextPaymentAmount)
        // } else {
        //   price = "Free"
        // }
      } else {
        price = "Free"
      }
      return price
    },
    formattedStatus() {
      let fmtStatus = ''
      if (this.isActivePendingCancelled) {
        fmtStatus = 'Active - Pending Cancelled'
        // } else if (this.plan.status === 'deleted') {
        //   fmtStatus = 'Cancelled'
      } else if (this.isActivePendingPaused) {
        fmtStatus = 'Active - Pending Paused'
      } else if (this.isPastDue) {
        fmtStatus = 'Payment Overdue *'
      } else if (this.isPaused) {
        fmtStatus = 'Paused *'
      } else {
        fmtStatus = _.startCase(this.plan.status)
      }
      return fmtStatus
    },
    formattedNumberOfClients() {
      if (this.$store.state.plan.numClients) {
        return this.$store.state.plan.numClients
      } else {
        return 'Unlimited'
      }
    }
  },
  methods: {
    refreshPlan() {
      EventBus.$emit('update-subscription-plan')
    },

    goToPlans() {
      this.$router.push({name: 'plans'})
    },

    getLang() {
      return navigator.language || navigator.browserLanguage || (navigator.languages || ["en"]) [0]
    },

    formatPaymentPrice(price, currency) {
      const priceFmt = new Intl.NumberFormat(this.getLang(), {style: 'currency', currency: currency}).format(price)
      return priceFmt
    },

    formatDate(date) {
      const formattedDate = (date instanceof moment) ? date.format("D MMM YYYY") : ""
      return formattedDate
    },

  },
  watch: {
    // wait for the plan in the store to be updated before getting the subscription and coupon details
    plan(newVal) {
      const tz = moment.tz.guess(true);
      Loader.start()
      ClinicianAccountService.getSubscriptionPayments(this.$store.state.username).then(resp => {
        const arr = _.map(resp.data, payment => {
              const x = {
                date: moment.tz(payment.paymentDate, tz),
                dateSortable: moment.tz(payment.paymentDate, tz).valueOf(), // a version of the date to sort on
                saleGross: payment.saleGross,
                nextPaymentAmount: payment.nextPaymentAmount,
                nextBillDate: moment.tz(payment.nextBillDate, 'UTC'),
                couponCode: payment.coupon,
                currency: payment.currency,
                receiptUrl: payment.receiptUrl,
                planName: payment.subscriptionPlanDisplayName,
              }
              return x
            }
        )
        this.payments = arr

        // if we are on a premium plan, and if the last payment has a coupon, make a call to the Paddle Api to get the coupon details
        const latestPayment = _.maxBy(this.payments, x => x.dateSortable)
        if (latestPayment && latestPayment.couponCode && this.plan.paddlePlanId) {
          // note this returns another Future, process the result in the next 'then' clause
          // return SubscriptionPlanService.getCouponDetails(latestPayment.couponCode)
          return SubscriptionPlanService.getCouponDetails(this.plan.paddlePlanId, latestPayment.couponCode)
        } else {
          // return an empty promise so the 'then' method doesn't freak out
          return Promise.resolve()
        }
      }).then(res => {
        if (res) {
          this.coupon.couponCode = res.data.couponCode
          this.coupon.discountType = res.data.discountType
          this.coupon.discountAmount = res.data.discountAmount
          this.coupon.discountCurrency = res.data.discountCurrency
          this.coupon.expires = moment.tz(res.data.expires, 'UTC')
        }
        Loader.stop()
      }).catch(err => {
        Logger.error(err)
        Loader.stop()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.rounded-card {
  border-radius: 10px;
}

.error-card {
  //background-color: #f0a1a1;
  background-color: var(--v-error_background-base)
}

//.warning-text {
//  //color: var(--v-error-base)
//  color: red
//}
</style>
