<template>
    <v-layout pt-3>
        <v-flex xs1/>
        <v-flex xs10 class="mysymptoms-main-container">
            <div v-html="docBody">
            </div>
        </v-flex>
        <v-flex xs1/>
    </v-layout>
</template>

<script>

  import axios from 'axios'
  import Logger from "../utils/logger-utils";
  import {policyDocService, CookiePolicyDocType} from '../utils/policy_doc_service'
  import Loader from '../utils/loader-utils'

  export default {
    name: 'CookiePolicy',
    data() {
      return {
        docBody: ""
      }
    },
    created() {
      // call the sync server api to find out which is the current policy document
      // and load the html dynamically from the public folder (it's located there so we can share it with the
      // company website)
      Loader.start()
      policyDocService.getCurrentPolicyDocMetaData(CookiePolicyDocType).then(res => {
        return policyDocService.getDocContent(res.data.docName)
      }).then(response => {
        this.docBody = response.data
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    }
  }
  ;
</script>
