<template>
  <div>
    <p>
      Scatter Chart
    </p>
    <!--    <canvas id="myChart5" height="200" width="400" style="border:1px solid #000000;"></canvas>-->
    <!--    <canvas height="200" width="400" style="border:1px solid #000000;"></canvas>-->
    <!--    1 -->
    <v-layout>
      <v-flex style="background-color: orange" xs1></v-flex>
      <v-flex xs10>
        <canvas style="border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>
    <!--    2 -->
    <p>2</p>
    <v-layout>
      <v-flex style="background-color: orange" xs1></v-flex>
      <v-flex xs10>
        <canvas width="400px" height="200px" style="border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <!--    3 -->
    <p>3</p>
    <v-layout>
      <v-flex style="background-color: orange" xs1></v-flex>
      <v-flex xs10>
        <div style="width: 100px; height: 100px">
          <canvas width="400px" height="200px" style="border:1px solid #000000; background-color: lightblue"></canvas>
        </div>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <br/> <br/> <br/><br/>

    <!--    4 -->
    <p>4</p>
    <v-layout>
      <v-flex style="background-color: orange" xs1></v-flex>
      <v-flex xs10>
        <canvas width="400px" height="200px"
                style="width: 100px; height: 100px; border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p>5</p>
    <v-layout>
      <v-flex style="background-color: orange" xs1></v-flex>
      <v-flex xs10>
        <canvas id="canvas5" width="400px" height="200px"
                style="border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p>6</p>
    <v-layout>
      <v-flex style="background-color: orange" xs1>
        <v-btn @click="click6">Click Me</v-btn>
<!--        <span v-if="show6">hello</span>-->
      </v-flex>
      <v-flex xs10>
        <canvas id="canvas6" width="400px" height="200px"
                style="border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p>7</p>
    <v-layout>
      <v-flex xs1>
        <v-btn color="primary" @click="click7(1.1)">+</v-btn>
        <br/>
        <v-btn color="primary" @click="click7(0.9)">-</v-btn>
      </v-flex>
      <v-flex xs10>
        <canvas id="canvas7" width="400px" height="200px"
                style="border:1px solid #000000; background-color: lightblue"></canvas>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p>8</p>
    <v-layout>
      <v-flex xs1 style="background-color: orange">
        <v-btn color="primary" @click="click8(1.1)">+</v-btn>
        <br/>
        <v-btn color="primary" @click="click8(0.9)">-</v-btn>
      </v-flex>
      <v-flex xs10>
        <!--        <div class="chart-container" style="position: relative; height:40vh; width:80vw">-->
        <canvas id="canvas8" style="border:1px solid #000000; background-color: lightblue"></canvas>
        <!--        </div>-->
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p>9</p>
    <p> maintainAspectRatio: false, style="height:30vh; width:50%" </p>
    <v-layout>
      <v-flex xs1 style="background-color: orange">
        <v-btn color="primary" @click="click9(5)">+</v-btn>
        <br/>
        <v-btn color="primary" @click="click9(-5)">-</v-btn>
      </v-flex>
      <v-flex xs10>
        <div class="chart-container" style="height:30vh; width:50%">
          <canvas id="canvas9" style="border:1px solid #000000; background-color: lightblue"></canvas>
        </div>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <p> 10 maintainAspectRatio: false, style="height:300px" </p>
    <v-layout>
      <v-flex xs1 style="background-color: orange">
        <v-btn color="primary" @click="click10(50)">+</v-btn>
        <br/>
        <v-btn color="primary" @click="click10(-50)">-</v-btn>
      </v-flex>
      <v-flex xs10>
        <div class="chart-container" style="height:300px">
          <canvas id="canvas10" style="border:1px solid #000000; background-color: lightblue"></canvas>
        </div>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>

    <!--            maintainAspectRatio: true, -->
    <p>11 maintainAspectRatio: true, style="height:300px" </p>
    <v-layout>
      <v-flex xs1 style="background-color: orange">
        <v-btn color="primary" @click="click9(5)">+</v-btn>
        <br/>
        <v-btn color="primary" @click="click9(-5)">-</v-btn>
      </v-flex>
      <v-flex xs10>
        <div class="chart-container" style="height:300px">
          <canvas id="canvas11" style="border:1px solid #000000; background-color: lightblue"></canvas>
        </div>
      </v-flex>
      <v-flex xs1 style="background-color: orange"></v-flex>
    </v-layout>
  </div>
</template>

<script>

import moment from "moment-timezone";
import {Chart} from "chart.js";

export default {
  name: "PatientDiary",
  components: {},

  data() {
    return {
      shw6: false,
      chartOptions9a : {
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        },
        layout: {
          padding: {
            bottom: 0
          }
        }
      },
      chartOptions10 : {
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        },
        layout: {
          padding: {
            bottom: 0
          }
        }
      },

      chartOptions11 : {
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        },
        layout: {
          padding: {
            bottom: 0
          }
        }
      },
    }
  },

  mounted() {
    this.draw5()
    this.draw6()
    this.draw7()
    this.draw8()
    this.draw9()
    this.draw10()
    this.draw11()
  },
  methods: {

    draw11() {
      const chartData1 = {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      }

      const el = document.getElementById('canvas11')
      const ctx = el.getContext('2d')
      const plugin = {}
      const canvas11 = new Chart(ctx, {
        type: 'scatter',
        data: chartData1,
        options: this.chartOptions11,
        plugins: [plugin]
      })
      canvas11;
    },

    click10(scale) {
      let chartStatus = Chart.getChart("canvas10"); // <canvas> id
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      var canvas = document.getElementById('canvas10');
      var ctx = canvas.getContext('2d');
      const oldHeight = parseInt(canvas.parentNode.style.height)
      const newHeight = oldHeight + scale
      canvas.parentNode.style.height = `${newHeight}px`
      const oldPadding = parseInt(this.chartOptions10.layout.padding.bottom)
      const newPadding = oldPadding + scale
      this.chartOptions10.layout.padding.bottom = newPadding

      this.draw10()
    },

    draw10() {
      const chartData1 = {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      }

      const el = document.getElementById('canvas10')
      const ctx = el.getContext('2d')
      const plugin = {}
      const canvas10 = new Chart(ctx, {
        type: 'scatter',
        data: chartData1,
        options: this.chartOptions10,
        plugins: [plugin]
      })
      canvas10;
    },

    click9(scale) {
      let chartStatus = Chart.getChart("canvas9"); // <canvas> id
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      var canvas = document.getElementById('canvas9');
      var ctx = canvas.getContext('2d');
      const oldHeight = parseInt(canvas.parentNode.style.height)
      const newHeight = oldHeight + scale
      canvas.parentNode.style.height = `${newHeight}vh`
      const oldPadding = parseInt(this.chartOptions9a.layout.padding.bottom)
      const newPadding = oldPadding + 50
      this.chartOptions9a.layout.padding.bottom = newPadding

      this.draw9()
    },

    draw9() {
      const chartData1 = {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      }

      const el = document.getElementById('canvas9')
      // el.height = '1500px'
      const ctx = el.getContext('2d')
      const plugin = {}
      const canvas9 = new Chart(ctx, {
        type: 'scatter',
        data: chartData1,
        // options: chartOptions9b,
        options: this.chartOptions9a,
        plugins: [plugin]
      })
      canvas9;
    },

    draw8() {
      const chartData1 = {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      }

      const chartOptions1 = {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        }
      }

      const ctx = document.getElementById('canvas8').getContext('2d')
      const plugin = {}
      const canvas8 = new Chart(ctx, {
        type: 'scatter',
        data: chartData1,
        options: chartOptions1,
        plugins: [plugin]
      })
      canvas8;
    },


    click7(scale) {
      let chartStatus = Chart.getChart("canvas7"); // <canvas> id
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      var canvas = document.getElementById('canvas7');
      var ctx = canvas.getContext('2d');
      canvas.width = canvas.width * scale
      canvas.height = canvas.height * scale
      this.draw7()
    },

    draw7() {
      const chartData1 = {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      }

      const chartOptions1 = {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        }
      }

      const ctx = document.getElementById('canvas7').getContext('2d')
      const plugin = {}
      const canvas7 = new Chart(ctx, {
        type: 'scatter',
        data: chartData1,
        options: chartOptions1,
        plugins: [plugin]
      })
      canvas7;
    },

    click6() {
      // this.show6 = !this.show6
      var canvas = document.getElementById('canvas6');
      var ctx = canvas.getContext('2d');
      canvas.width = canvas.width * 1.1
      canvas.height = canvas.height * 1.1
      this.draw6()
    },

    draw6() {
      var canvas = document.getElementById('canvas6');
      var ctx = canvas.getContext('2d')
      ctx.beginPath();
      ctx.arc(60, 60, 50, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fillStyle = 'blue'
      ctx.fill();
    },

    draw5() {
      var canvas = document.getElementById('canvas5');
      var ctx = canvas.getContext('2d');
      var raf;

      var ball = {
        x: 100,
        y: 100,
        vx: 10,
        vy: 10,
        radius: 25,
        color: 'blue',
        draw: function () {
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.fillStyle = this.color;
          ctx.fill();
        }
      };

      function draw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ball.draw();
        ball.x += ball.vx;
        ball.y += ball.vy;
        // ball.vx *= .999;
        // ball.vy *= .9;

        if (ball.y + ball.vy > canvas.height ||
            ball.y + ball.vy < 0) {
          ball.vy = -ball.vy * .75;
        }
        if (ball.x + ball.vx > canvas.width ||
            ball.x + ball.vx < 0) {
          ball.vx = -ball.vx * 0.75;
        }

        raf = window.requestAnimationFrame(draw);
      }

      canvas.addEventListener('mouseover', function (e) {
        raf = window.requestAnimationFrame(draw);
      });

      canvas.addEventListener('mouseout', function (e) {
        window.cancelAnimationFrame(raf);
      });

      ball.draw();
    }
  }
}
</script>
