<template>
    <v-layout>
        <v-flex xs2 md4>
        </v-flex>
        <v-flex xs8 md4>

            <v-form ref="form" v-model="formValid" class="mysymptoms-form-container" @submit="onResetPasswordFormSubmit">
                <h2 class="text-center">Reset Password</h2>
                <hr/>
                <v-text-field
                        v-if="mode === 'private'"
                        v-model="oldPassword"
                        label="Old Password"
                        :append-icon="showOldPassword ? 'visibility_off' : 'visibility'"
                        :type="showOldPassword ? 'text' : 'password'"
                        @click:append="showOldPassword = !showOldPassword"
                        :success="!!oldPassword"
                        autocomplete="password"
                        autofocus
                        :rules="[requiredRule]"
                ></v-text-field>

                <!-- Note: the input fields in the password components are automatically
                    registered to this form when they are mounted, and so the rules get trigger when the form is validated
                 -->
                <password
                        ref="passwordComponent"
                        v-model="newPassword"
                        placeholder="New Password"
                        autocomplete="off"
                />

                <v-btn
                        type="submit"
                        block
                        color="primary"
                >
                    Reset Password
                </v-btn>

            </v-form>
        </v-flex>

        <v-flex xs2 md4></v-flex>
    </v-layout>
</template>

<script>
  import Logger from '../utils/logger-utils'
  import VError from 'verror'
  import PasswordUtils from '../utils/password-utils'
  import AccountService from '../utils/account-service'
  import Password from '../components/Password.vue'
  import Loader from '../utils/loader-utils'
  import * as HttpStatus from 'http-status-codes'
  import {requiredRule} from "../utils/rules";

  /**
   * This screen is used in two use cases:
   *    Private: when the user is currently signed in and selects to change password from the User Menu. In this case
   *        the component expects the user to enter their old password.
   *    Public: when the user is not currently signed in. This screen will have been opened by the used clicking on the
   *        link in the email they received after submitting the Reset Password Request form. In this case, the
   *        component expects a valid token to be passed in the url.
   */

  export default {
    name: "ResetPassword",
    components: {Password},
    props: [
      'mode', // public (not logged in) or private (logged in)
      'resetToken'
    ],
    data() {
      return {
        oldPassword: '',
        newPassword: '',
        showOldPassword: false,
        formValid: false,
        name: ''
      }
    },
    methods: {

      requiredRule: requiredRule,

      onResetPasswordFormSubmit(evt) {

        try {

          // Logger.debug("onResetPasswordFormSubmit");

          // stop the submit button click from refreshing the page
          evt.preventDefault()

          const isValid = this.$refs.form.validate()

          if (isValid) {

            // note the submit button is only enabled when the passwords match
            const encryptedNewPassword = PasswordUtils.encrypt(this.newPassword)

            Loader.start()

            if (this.mode === 'public') {
              AccountService.resetPasswordPublic(this.resetToken, encryptedNewPassword).then(() => {
                Loader.stop()
                Logger.info('Your password has been reset.')
                // for non patient requests send the user to the signin page
                if ( !this.$route.meta.patientReq ) {
                  this.$router.push({name: 'signin'})
                } else {
                  // this.$refs.form.reset()
                  // this.$refs.passwordComponent.setInitialState()
                  this.$router.push({name: 'reset_password_confirmation'})
                }
              }).catch(err => {
                // unfortunately we can't distinguish between a corrupt request token id and a token that's expired
                // and hence been deleted from the token store. We'll just assume the later and hope for the best ...
                if (err.name === HttpStatus.CONFLICT.toString()) {
                  Logger.error("The Password Reset request was not recognised. The reset token may have expired. Please try requesting a password reset again.", { duration: 10000})
                  // for non patient requests send the user to the clinic request password reset page
                  if ( !this.$route.meta.patientReq ) {
                    this.$router.push({name: 'reset_password_request'})
                  }
                } else {
                  Logger.error(new VError(err, "Reset Password failed."))
                }
                Loader.stop()
              })
            } else if (this.mode === 'private') {
              const oldPasswordEncrypted = PasswordUtils.encrypt(this.oldPassword)
              AccountService.resetPasswordPrivate(
                this.$store.state.username,
                encryptedNewPassword,
                oldPasswordEncrypted).then(() => {
                Loader.stop()
                Logger.info('Your password has been reset.')


                this.$router.push({name: 'home'})
              }).catch(err => {
                Loader.stop()
                // check if the exception was caused by incorrect old password. If so, give a friendly message to user
                const cause = VError.cause(err)
                if (err.name === HttpStatus.UNAUTHORIZED.toString() && cause.response.data.code === 'INCORRECT_PASSWORD') {
                  Logger.error('Old password is incorrect.')
                } else {
                  // we have an unexpected error, log a general message with a stack trace
                  Logger.error(new VError(err, `Reset password failed.`))
                }
              })
            }
          }
        } catch (ex) {
          Logger.error(new VError(ex, `Reset password failed.`))
        }
      },
    },
    created() {
      // mode and resetToken should have been set as a props, log errors if not set.
      if (this.mode === 'public') {
        if (typeof this.resetToken === "undefined") {
          Logger.error(VError("reset_token is undefined"))
        }
      } else if (this.mode !== 'private') {
        Logger.error(`unknown mode: ${this.mode}`)
      }
    }
  }
</script>

