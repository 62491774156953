import moment from "moment-timezone";

export default {

  currentOffset() {
    const tzName = moment.tz.guess(true);
    const offset = moment.tz(tzName).format('Z')
    const name = moment.tz(tzName).format('z')
    return [offset, name]
  },

  allTimezoneOffsets: function () {
    const all = [
      {
        "name": "",
        "offset": "-12:00"
      },
      {
        "name": "SST",
        "offset": "-11:00"
      },
      {
        "name": "HST",
        "offset": "-10:00"
      },
      {
        "name": "HDT",
        "offset": "-09:00"
      },
      {
        "name": "",
        "offset": "-09:00"
      },
      {
        "name": "",
        "offset": "-09:30"
      },
      {
        "name": "AKDT",
        "offset": "-08:00"
      },
      {
        "name": "MST",
        "offset": "-07:00"
      },
      {
        "name": "PDT",
        "offset": "-07:00"
      },
      {
        "name": "CST",
        "offset": "-06:00"
      },
      {
        "name": "MDT",
        "offset": "-06:00"
      },
      {
        "name": "EST",
        "offset": "-05:00"
      },
      {
        "name": "CDT",
        "offset": "-05:00"
      },
      {
        "name": "AST",
        "offset": "-04:00"
      },
      {
        "name": "EDT",
        "offset": "-04:00"
      },
      {
        "name": "CDT",
        "offset": "-04:00"
      },
      {
        "name": "ADT",
        "offset": "-03:00"
      },
      {
        "name": "",
        "offset": "-02:00"
      },
      {
        "name": "NDT",
        "offset": "-02:30"
      },
      {
        "name": "",
        "offset": "-01:00"
      },
      {
        "name": "GMT",
        "offset": "+00:00"
      },
      {
        "name": "CET",
        "offset": "+01:00"
      },
      {
        "name": "WAT",
        "offset": "+01:00"
      },
      {
        "name": "WEST",
        "offset": "+01:00"
      },
      {
        "name": "IST",
        "offset": "+01:00"
      },
      {
        "name": "BST",
        "offset": "+01:00"
      },
      {
        "name": "CAT",
        "offset": "+02:00"
      },
      {
        "name": "EET",
        "offset": "+02:00"
      },
      {
        "name": "CEST",
        "offset": "+02:00"
      },
      {
        "name": "SAST",
        "offset": "+02:00"
      },
      {
        "name": "MEST",
        "offset": "+02:00"
      },
      {
        "name": "EAT",
        "offset": "+03:00"
      },
      {
        "name": "EEST",
        "offset": "+03:00"
      },
      {
        "name": "IDT",
        "offset": "+03:00"
      },
      {
        "name": "MSK",
        "offset": "+03:00"
      },
      {
        "name": "",
        "offset": "+04:00"
      },
      {
        "name": "",
        "offset": "+04:30"
      },
      {
        "name": "",
        "offset": "+05:00"
      },
      {
        "name": "IST",
        "offset": "+05:30"
      },
      {
        "name": "PKT",
        "offset": "+05:00"
      },
      {
        "name": "",
        "offset": "+05:45"
      },
      {
        "name": "",
        "offset": "+06:00"
      },
      {
        "name": "",
        "offset": "+06:30"
      },
      {
        "name": "WIB",
        "offset": "+07:00"
      },
      {
        "name": "CST",
        "offset": "+08:00"
      },
      {
        "name": "HKT",
        "offset": "+08:00"
      },
      {
        "name": "WITA",
        "offset": "+08:00"
      },
      {
        "name": "PST",
        "offset": "+08:00"
      },
      {
        "name": "",
        "offset": "+08:45"
      },
      {
        "name": "AWST",
        "offset": "+08:00"
      },
      {
        "name": "WIT",
        "offset": "+09:00"
      },
      {
        "name": "KST",
        "offset": "+09:00"
      },
      {
        "name": "JST",
        "offset": "+09:00"
      },
      {
        "name": "ACST",
        "offset": "+09:30"
      },
      {
        "name": "AEST",
        "offset": "+10:00"
      },
      {
        "name": "",
        "offset": "+10:30"
      },
      {
        "name": "ChST",
        "offset": "+10:00"
      },
      {
        "name": "",
        "offset": "+11:00"
      },
      {
        "name": "NZST",
        "offset": "+12:00"
      },
      {
        "name": "",
        "offset": "+12:45"
      },
      {
        "name": "",
        "offset": "+13:00"
      },
      {
        "name": "",
        "offset": "+14:00"
      }
    ]
    return all
  }
}
