<template>
  <div class="mysymptoms-main-container">
    <v-layout>
      <v-flex xs8>
        <!--        <h1 class="display-1 pt-3">Invitations</h1><v-icon>refresh</v-icon>-->
        <v-card flat>
          <v-card-title class="pl-0">
            <h1 class="pt-3">Invitations</h1>
            <v-btn
                icon
                @click="triggerPendingInvitationsLoad()"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                      v-on="on"
                      color="grey darken-1">
                    refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs4>
        <!--suppress HtmlUnknownTag -->
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-flex>
    </v-layout>

    <div>
      <v-tabs
          v-model="activeTab"
      >
        <v-tab :key="1">
          Pending
        </v-tab>
        <v-tab :key="2">
          Previous
        </v-tab>
        <v-tab-item transition=false reverse-transition=false :key="1">
          <v-card>
            <!-- main data table -->
            <!-- Note, we are not using the v-data-table built in search. Instead, we are pre-filtering the items so we can
            search on the displayed date strings -->
            <!-- pagination https://github.com/vuetifyjs/vuetify/issues/442 -->
            <v-data-table
                :headers="pendingInvitationsHeaders"
                :items="filteredPendingInvitations"
                must-sort
                no-data-text="No pending patient invitations. Click on Patients in the navigation to see your patients."
                no-results-text="No pending patient invitations. Click on Patients in the navigation to see your patients."
                v-bind:pagination.sync="pagination"
                v-bind:rows-per-page-items="rowsPerPageItems"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.patientUsername }}</td>
                <td>{{ props.item.patientRealName }}</td>
                <td>{{ props.item.requestDate }}</td>
                <td class="justify-center px-24">

                  <!-- Accept Invitation Button -->
                  <v-btn icon
                         @click="acceptInvite(props.item.patientUsername)"
                  >
                    <v-tooltip bottom
                    >
                      <v-icon
                          slot="activator"
                      >
                        check
                      </v-icon>
                      <span>Accept Patient Request</span>
                    </v-tooltip>
                  </v-btn>

                  <!-- Decline Invitation Button -->
                  <v-dialog
                      v-model="showDialog[props.item.patientUsername]"
                      width="500"
                  >
                    <v-btn icon slot="activator">

                      <v-tooltip bottom>
                        <v-icon
                            slot="activator"
                        >
                          close
                        </v-icon>
                        <span>Decline Patient Request</span>
                      </v-tooltip>
                    </v-btn>

                    <v-card>
                      <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                      >
                        Confirm
                      </v-card-title>
                      <v-card-text>
                        Are you sure you want to decline {{ props.item.patientUsername }}'s
                        invitation?
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            flat
                            @click="showDialog[props.item.patientUsername] = false"
                        >
                          No
                        </v-btn>
                        <v-btn
                            color="primary"
                            flat
                            @click="declineInvite(props.item.patientUsername)"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <!--          <v-btn-->
          <!--              color="primary"-->
          <!--              @click="triggerPendingInvitationsLoad()"-->
          <!--          >-->
          <!--            Refresh-->
          <!--          </v-btn>-->

        </v-tab-item>
        <v-tab-item transition=false reverse-transition=false :key="2">
          <v-card flat>
            <!-- Note, we are not using the v-data-table built in search. Instead, we are pre-filtering the items so we can
            search on the displayed date strings -->
            <!-- pagination https://github.com/vuetifyjs/vuetify/issues/442 -->
            <v-data-table
                :headers="previousInvitationsHeaders"
                :items="filteredPreviousInvitations"
                must-sort
                no-data-text="No previous patient requests found."
                no-results-text="No previous patient requests found."
                v-bind:pagination.sync="pagination"
                v-bind:rows-per-page-items="rowsPerPageItems"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.patientUsername }}</td>
                <td>{{ props.item.patientRealName }}</td>
                <td>{{ props.item.requestDate }}</td>
                <td>{{ props.item.acceptedDate }}</td>
                <td>{{ props.item.rejectedDate }}</td>
                <td>{{ props.item.deletedDate }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>

    </div>

    <v-dialog v-model="showClientLimitPopup" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Client Limit</v-card-title>
        <v-card-text>
          You have reached the client limit for your plan. If you wish to upgrade your plan please click
          <router-link :to="{name: 'plans'}" target="_parent">here</router-link>
          to accept more clients.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="showClientLimitPopup=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import ClinicianAccountService from '../utils/clinician-account-service'
import Logger from '../utils/logger-utils'
import {checkProperties} from '../utils/helpers'
import Loader from "../utils/loader-utils"
import moment from 'moment'
import VDialog from "vuetify/lib/components/VDialog/VDialog";
import {EventBus} from "../utils/event-bus"
import VError from "verror";
import * as HttpStatus from "http-status-codes";

const dateFormat = "DD MMM YYYY kk:mm"

export default {
  name: "PatientInvitations",
  components: {VDialog},
  data() {
    return {
      showClientLimitPopup: false,
      activeTab: '', // not actually used other than being needed for the v-tabs control
      previousInvitations: [],
      pendingInvitationsHeaders:
          [
            {text: 'Patient Username', value: 'patientUsername', sortable: true},
            {text: 'Name', value: 'patientRealName', sortable: true},
            {text: 'Requested', value: 'patientSinceSortable', sortable: true},
            {text: 'Actions', value: 'actions', sortable: false},
          ],
      previousInvitationsHeaders:
          [
            {text: 'Patient Username', value: 'patientUsername', sortable: true},
            {text: 'Name', value: 'patientRealName', sortable: true},
            {text: 'Requested', value: 'requestDateSortable', sortable: true},
            {text: 'Accepted', value: 'accptedDateSortable', sortable: true},
            {text: 'Declined', value: 'rejectedDateSortable', sortable: true},
            {text: 'Deleted', value: 'deletedDateSortable', sortable: true},
          ],
      // patients: [],
      search: '',
      pagination: {'sortBy': 'patientSinceSortable', 'descending': true, 'rowsPerPage': 25},
      rowsPerPageItems: [10, 25, 50, {text: 'All', value: -1}],
      showDialog: {}
    }
  },
  methods: {
    removeReqFromPending(patientUsername) {
      const newReqList = this.pendingInvitations.filter(el => el.patientUsername !== patientUsername)
      this.$store.commit('setPendingPatientInvitations', {value: newReqList, broadcast: false})
    },

    acceptInvite(patientUsername) {
      Loader.start()
      ClinicianAccountService.acceptInvite(this.$store.state.username, patientUsername).then(() => {
        Loader.stop()
        // remove element from the array
        this.removeReqFromPending(patientUsername)
        Logger.info("Invitation accepted.")
        // Refresh the previous list so it displays this item.
        // NB this returns a promise which populates this.previousInvitations
        return this.getPreviousInvitationsPromise(this.$store.state.username)
      }).catch(err => {
        Loader.stop()
        const cause = VError.cause(err)
        if (err.name === HttpStatus.FORBIDDEN.toString() && (cause.response.data.code === "CLIENT_LIMIT_REACHED")) {
          this.showClientLimitPopup = true
        } else {
          Logger.error(err)
        }

      })
    },

    declineInvite(patientUsername) {
      this.showDialog[patientUsername] = false
      Loader.start()
      ClinicianAccountService.declineInvite(this.$store.state.username, patientUsername).then(() => {
        Loader.stop()
        // remove element from the array
        this.removeReqFromPending(patientUsername)
        Logger.info("Invitation Declined.")
        // Refresh the previous list so it displays this item.
        // NB this returns a promise which populates this.previousInvitations
        return this.getPreviousInvitationsPromise(this.$store.state.username)
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    },

    /**
     * NB returns a Promise
     */
    getPreviousInvitationsPromise(clinicianUsername) {
      return ClinicianAccountService.getPreviousInvitations(clinicianUsername).then(res => {
        this.previousInvitations = res.data.map(request => {
          checkProperties(request, ['patient', 'createdAt', 'acceptedDate', 'deletedDate', 'rejectedDate', 'requestDate'])
          const patientRealName = ((request.hasOwnProperty('patientFirstName') && request.patientFirstName !== null)) ?
              request.patientFirstName + " " + request.patientLastName : ""
          const requestDate = moment(request.requestDate)
          const acceptedDate = request.acceptedDate ? moment(request.acceptedDate) : null
          const deletedDate = request.deletedDate ? moment(request.deletedDate) : null
          const rejectedDate = request.rejectedDate ? moment(request.rejectedDate) : null
          // noinspection ES6ShorthandObjectProperty
          const patientUsername = request.patient
          return {
            patientUsername: patientUsername,
            patientRealName: patientRealName,
            acceptedDate: acceptedDate ? acceptedDate.format(dateFormat) : '',
            accptedDateSortable: acceptedDate ? acceptedDate.valueOf() : 0, // for sorting on
            deletedDate: deletedDate ? deletedDate.format(dateFormat) : '',
            deletedDateSortable: deletedDate ? deletedDate.valueOf() : 0, // for sorting on
            rejectedDate: rejectedDate ? rejectedDate.format(dateFormat) : '',
            rejectedDateSortable: rejectedDate ? rejectedDate.valueOf() : 0, // for sorting on
            requestDate: requestDate ? requestDate.format(dateFormat) : '',
            requestDateSortable: requestDate ? requestDate.valueOf() : 0, // for sorting on
          }
        })
      })
    },

    getPreviousInvitations() {
      Loader.start()
      // this is a little bit 'smoke and mirrors' - the Loader only shows for getting the Previous Invitations, we
      // assume that the pending requests have already loaded into the Store from the backgroudn thread running in App
      this.getPreviousInvitationsPromise(this.$store.state.username).then(() => {
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    },

    triggerPendingInvitationsLoad() {
      EventBus.$emit('check-for-pending-invites')
    }
  },
  computed: {
    pendingInvitations() {
      return this.$store.state.pendingPatientInvitations
    },
    /**
     * filters the requests records by searching ** only ** the string fields of the row. The row is included if any
     * field value contains the search text (case insensitive)
     */
    filteredPendingInvitations() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        return this.pendingInvitations
      }

      const ret = this.pendingInvitations.filter(req => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(req)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    },
    filteredPreviousInvitations() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        return this.previousInvitations
      }

      const ret = this.previousInvitations.filter(req => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(req)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    }
  },
  mounted() {
    this.getPreviousInvitations()
  },
}
</script>

