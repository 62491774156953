import {store} from "../store/store"
import TokenService from "./token-service"
import Logger from "./logger-utils";

/**
 * token.js - a wrapper around the token state info
 */
export default {
  /**
   *  getPrivateToken - return the private token from the store if we have one, otherwise fetch from server and store
   *                    before returning
   *
   * @param username
   * @param password
   * @returns {Promise} - that resolves to
   *                      {
   *                        privateToken: {string}
   *                        refreshToken: {string}
   *                        privateTokenExpiresIn: {int}
   *                        privateTokenExpiresAt: {string} - ISO8601 date string
   *                      }
   */
  taskQueue  : [],

  getPrivateToken(username, password) {
    // we want serialise calls to get tokens .. e.g. if we get two calls in quick succession, we want the first to
    // get the token from the server and write it to the store, and the second to wait for first to complete and
    // return the token from the store.
    //
    // calling getPrivateTokenTask returns a promise. We chain this promise with two others, one preceding and one following.
    // The preceding promise, puts its resolve function on a queue. If this is the first element in the queue we
    // immediate resolve the promise straight away or else it will be resolved when the preceding item in the queue has
    // completed.
    // The promise following, checks to see if there are any other promises in the queue and if so, calls them

    const that = this
    const funcName = 'getPrivateToken'

    return new Promise( (resolve) => {
      // add to end of queue, if this is the only one in the queue, then resolve now
      that.taskQueue.push(resolve)
      // Logger.debug(`adding to taskQueue. Length now: ${that.taskQueue.length}`, funcName)
      if (that.taskQueue.length === 1) {
        resolve()
      }
    }).then(() => {
      return that.getPrivateTokenTask(username, password)
    }).then( res => {
      // remove the promise just finished from the queue and resolve the next promise in the queue if there is one
      that.taskQueue.shift()
      // Logger.debug(`removed from taskQueue. Length now: ${that.taskQueue.length}`, funcName)
      if (that.taskQueue.length > 0) {
        that.taskQueue[0]()
      }
      return res
    }).catch( err => {
      that.taskQueue.shift()
      // Logger.debug(`removed from taskQueue. Length now: ${that.taskQueue.length}`, funcName)
      if (that.taskQueue.length > 0) {
        that.taskQueue[0]()
      }
      throw err
    })
  },


  getPrivateTokenTask(username, password) {

    let retPromise = null

    // check if the token in the store is valid, if not get a new token and update the store
    // if (store.state.oauthPrivateToken === '' || store.state.tokenExpired) {
    // noinspection JSUnresolvedVariable
    if (store.state.oauthPrivateToken === '') {
      retPromise = this.getPrivateTokenAndWriteToStore(username, password).then(() => {
        // noinspection ES6ShorthandObjectProperty
        // noinspection JSUnresolvedVariable
        return {
          privateToken: store.state.oauthPrivateToken,
          refreshToken: store.state.oauthRefreshToken,
          privateTokenExpiresIn: store.state.oauthPrivateTokenExpiresIn,
          privateTokenExpiresAt: store.state.oauthPrivateTokenExpiresAt,
        }
      })
    } else {
      // the token in the store is OK so just return a promise that resolves straight away
      retPromise = Promise.resolve().then(() => {
        // noinspection ES6ShorthandObjectProperty
        // noinspection JSUnresolvedVariable
        return {
          privateToken: store.state.oauthPrivateToken,
          refreshToken: store.state.oauthRefreshToken,
          privateTokenExpiresIn: store.state.oauthPrivateTokenExpiresIn,
          privateTokenExpiresAt: store.state.oauthPrivateTokenExpiresAt,
        }
      })
    }
    return retPromise
  },

  /**
   *
   * @param username
   * @param password
   * @returns {Promise}
   */
  getPrivateTokenAndWriteToStore(username, password) {
    return TokenService.getPrivateToken(username, password)
      .then(({privateToken, refreshToken, privateTokenExpiresIn, privateTokenExpiresAt}) => {
        /**
         * Store the token data before returning
         *
         * @param {string} privateToken
         * @param {string} refreshToken
         * @param {int} privateTokenExpiresIn - number of seconds
         * @param privateTokenExpiresAt: {string} - ISO8601 date string
         */
        // console.log("Signed in, getting account info");
        // noinspection JSUnresolvedFunction
        store.commit('setPrivateTokenInfo', {
          value: {
            privateToken: privateToken,
            refreshToken: refreshToken,
            privateTokenExpiresIn: privateTokenExpiresIn,
            privateTokenExpiresAt: privateTokenExpiresAt,
          },
          broadcast: true
        })
        // noinspection JSUnresolvedFunction
        store.commit('setIsSignedIn', {value: true, broadcast: true})

        // set a timer to refresh the token when it expires
        // Logger.debug(`expires in ${privateTokenExpiresIn}, ${privateTokenExpiresAt}`)
      })
  },

  /**
   *  getPublicToken - return the public token from the store if we have one, otherwise fetch from server and store
   *  before returning
   *
   * @returns {Promise} resolving to {string} - public token
   */
  getPublicToken() {
    // noinspection JSUnresolvedVariable
    if (store.state.oauthPublicToken === '') {
      return TokenService.getPublicToken().then(token => {
        // noinspection JSUnresolvedFunction
        store.commit("setPublicToken", {value: token, broadcast: true})
        return token
      })
    } else {
      // noinspection JSUnresolvedVariable
      return Promise.resolve(store.state.oauthPublicToken)
    }
  }

}
