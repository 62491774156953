<template>
    <v-layout pt-3>
        <v-flex xs2 md3/>
        <v-flex xs8 md6 class="mysymptoms-main-container">
            <div>
                <h1>About</h1>
                <table>
                    <tr class="text-xs-right">
                        <td>Client Version:</td>
                        <td class="text-xs-left">{{version}}</td>
                    </tr>
                    <tr class="text-xs-right">
                        <td>mySymptoms Mode:</td>
                        <td class="text-xs-left">{{mysymptoms_mode}}</td>
                    </tr>
                    <tr class="text-xs-right">
                        <td>NODE_ENV:</td>
                        <td class="text-xs-left">{{node_env}}</td>
                    </tr>
                    <tr class="text-xs-right">
                        <td>Api Url:</td>
                        <td class="text-xs-left">{{api_base_url}}</td>
                    </tr>
                </table>
            </div>
        </v-flex>
        <v-flex xs2 md3/>
    </v-layout>

</template>

<script>
  import {version} from '../../package.json'

  export default {
    name: "ContactUs",
    data:
      () => ({
        version: '',
        node_env: '',
        api_base_url: '',
        mysymptoms_mode: ''
      }),
    created() {
      this.version = version,
      this.node_env = process.env.NODE_ENV,
      this.api_base_url = process.env.VUE_APP_API_BASE_URL,
      this.mysymptoms_mode = process.env.VUE_APP_MYSYMPTOMS_MODE
    }
  }
</script>

<style scoped>
    td {
        padding-right: 10px;
        padding-bottom: 5px;
        vertical-align: top;
    }
</style>
