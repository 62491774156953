import Api from "@/utils/api";

const subscriptionPlanPathPublic = '/public/subscriptionplan'
const couponCodePath = '/private/paddle/coupon'

export default {

  // level1Name: 'level1',
  // level2Name: 'level2a',

  level1Name: process.env.VUE_APP_SUBS_LEVEL1_NAME,
  level2Name: process.env.VUE_APP_SUBS_LEVEL2_NAME,

  getSubscriptionPlans(...planNames) {
    const encodedPlanNames = encodeURIComponent(planNames.join())
    const path = `${subscriptionPlanPathPublic}?plans=${encodedPlanNames}`
    return Api.get(path)
  },

  getCouponDetails(subscriptionId, couponCode) {
    const path = `${couponCodePath}/${subscriptionId}/${couponCode}`
    return Api.get(path)
  },

  getTrialUrl(planId, trialDays, trialCode) {
    const path = `${subscriptionPlanPathPublic}/trialurl?planId=${planId}&trialDays=${trialDays}&trialCode=${trialCode}`
    return Api.get(path)
  }

}
