<template>
  <v-layout>
    <v-flex xs2 md4/>
    <v-flex xs8 md4>
      <v-form ref="regform" v-model="valid" class="mysymptoms-form-container" @submit.prevent="doSubmit">
        <h2 class="text-xs-center">Create your Account</h2>
        <p class="text-xs-center subheading">Register to view and search your patient's food and symptom
          diaries.</p>

        <v-text-field
            v-model="username"
            :rules="[requiredRule, usernameOKRule]"
            label="Username"
            autocomplete="username"
            required
            :success="!!username"
            data-cy="username"
        ></v-text-field>
        <v-text-field
            v-model="email"
            :rules="[requiredRule, emailOKRule]"
            label="Email"
            autocomplete="email"
            required
            :success="!!email"
            :disabled="registerFromInvite"
            data-cy="email"
        >
          <v-tooltip v-if="registerFromInvite" slot="append-outer" bottom>
            <v-icon
                slot="activator"
                light
                style="pointer-events: auto"
            >help
            </v-icon>
            If you wish to use a different email, please start the registration from the sign in page.
          </v-tooltip>
        </v-text-field>

        <!-- the password component passes the value back through calling .$emit('input', value) when
             the password values input meet all the criteria.
             remember that v-model='password' is shorthand for
                 v-bind:value='password'
                 v-on:input='password = $event'
             where 'value' is a prop on password component
        -->
        <!-- Note: the input fields in both password and ClinicianCommonFields components are automatically
        registered to this form when they are mounted, and so the rules get trigger when the form is validated
        -->
        <password
            v-model="password"
        >
        </password>
        <ClinicianCommonFields
            v-bind:profession.sync='profession'
            v-bind:professionOther.sync='professionOther'
            v-bind:title.sync='title'
            v-bind:titleOther.sync='titleOther'
            v-bind:firstName.sync='firstName'
            v-bind:lastName.sync='lastName'
            v-bind:practiceName.sync='practiceName'
            v-bind:website.sync='website'
            v-bind:addressLine1.sync='addressLine1'
            v-bind:addressLine2.sync='addressLine2'
            v-bind:city.sync='city'
            v-bind:state.sync='state'
            v-bind:postcode.sync='postcode'
            v-bind:country.sync='country'
        >
        </ClinicianCommonFields>

        <!-- only enable the plan selector when the country is selected, since the currency is displayed in the list
        items -->
        <!-- needed to set readonly in order to get the tool tip to display when the selector is deactivated:  -->
        <!-- https://stackoverflow.com/questions/51826891/how-do-i-enable-tooltips-on-a-disabled-text-field-in-vuetify-->
        <v-select
            v-model="plan"
            :items="plansFormatted"
            :rules="[requiredRule]"
            label="Select Plan"
            required
            return-object
            :disabled="this.country === ''"
            :readonly="this.country === ''"
            :success="!!plan"
        >
          readonly=false
          append-outer-icon="help"
          <v-tooltip slot="append-outer" bottom close-delay="750">
            <v-icon
                slot="activator"
                light
            >help
            </v-icon>
            <span v-if="this.country === ''">Select a country to enable Plan selector. </span>
            <span>
              <!-- add link to plan details -->
            Click <a href=#>here</a> for plan details.
            </span>
          </v-tooltip>
        </v-select>

        <v-checkbox
            v-model="agreeToTerms"
            :rules="[v => !!v || 'You must agree to continue.']"
            required
        >
          <div slot="label">
            I agree with the
            <router-link :to="{name: 'terms_of_use'}" target="_blank">Terms Of Use</router-link>
            and
            <router-link :to="{name: 'privacy_policy'}" target="_blank">Privacy Policy</router-link>
            .
          </div>
        </v-checkbox>

        <div class="text-xs-center">
          <v-btn ref="register_btn"
                 type="submit"
                 color="primary"
                 data-cy="register-button"
          >
            Register
          </v-btn>
        </div>

        <p class="pt-3">If you have previously registered and not received the email to verify your
          address, please click
          <router-link :to="{name: 'resend_verification_email'}">here.</router-link>
        </p>
        <!-- a dev/test feature to populate for the form with dummy data -->
        <v-btn name="populate_dummy_data" v-if="showPopulateDummyDataButton" @click="populateDummyData">
          Populate Test Data
        </v-btn>
      </v-form>


    </v-flex>
    <v-flex xs2 md4/>

    <div class="text-xs-center">
      <v-dialog v-model="showPaymentClosedWarning" persistent width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>Default Free Plan</v-card-title>
          <v-card-text>
            Your account will be created on the default free plan. You can upgrade to a premium plan by signing in and
            going to the 'Plans' page.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="handlePaymentClosedWarningOK">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <PaddleCheckout
        v-bind:username.sync='username'
        v-bind:email.sync='email'
        v-bind:showCheckout.sync='showCheckout'
        v-bind:country.sync='country'
        v-bind:postcode.sync='postcode'
        v-bind:paddleId.sync='plan.paddleId'
        v-bind:planName.sync='plan.name'
        v-bind:referrerSuccessCallback='afterAccountCreation'
        v-bind:referrerCloseCallback='()=> this.showPaymentClosedWarning = true'
    >
    </PaddleCheckout>

  </v-layout>
</template>

<script>

import Logger from '../utils/logger-utils'
import ClinicianAccountService from '../utils/clinician-account-service'
import SubscriptionPlanService from '@/utils/subscription-plan-service'
import LoaderUtils from '../utils/loader-utils'
import PasswordUtils from '../utils/password-utils'
import Password from "../components/Password"
import ClinicianCommonFields from "../components/ClinicianCommonFields"
import VError from 'verror'
import * as HttpStatus from 'http-status-codes'
import {emailOKRule, usernameOKRule, requiredRule} from '@/utils/rules'
import CountryToCurrency from '@/utils/country-to-currency-codes'
import PaddleCheckout from "@/components/PaddleCheckout"
import Plans from "@/views/Plans"
import _ from "lodash"

export default {
  components: {PaddleCheckout, Password, ClinicianCommonFields},
  that: this,
  name: "Clinician",
  props: {
    // did the user click on the link in the invite email?
    registerFromInvite: {
      type: Boolean,
      default: false
    },
    // the email address in the invite link
    initialEmail: {
      type: String,
      required: false
    },
    // the verification token in the invite link
    token: {
      type: String,
      required: false
    },
  },
  data:
      () => ({
        dummy: '',
        plan: '', // selected plan
        plans: [], // list of plans to populate the drop-down
        // plans is an array of:
        //     {
        //        text: `${x.displayName}, ${x.numClients} clients, ${priceGBP}`,
        //        value: 'level2',
        //        name: 'level2',
        //        displayName: 'mySymptoms Silver',
        //        premium: true,
        //        numClients: 20,
        //        priceUSD: "USD$25.00", // the number and currency formatting follows the convention for the user's locale
        //        priceEUR: "€27.00"
        //        priceGBP: "£30.00"
        //     }
        valid: true,
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        profession: '',
        professionOther: '',
        title: '',
        titleOther: '',
        firstName: '',
        lastName: '',
        practiceName: '',
        website: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        agreeToTerms: false,
        showPaymentClosedWarning: false,
        showCheckout: false,
        regByLinkFailureMsg: "Registration failed to verify this email address." // so it's available in unit test
      }),
  methods: {
    // for easy dev / debugging
    populateDummyData() {
      this.username = 'damianhelme'
      this.email = this.email ? this.email : 'me@damianhelme.com'
      this.password = 'MySymptoms1'
      this.confirmPassword = 'MySymptoms1'
      this.profession = 'Registered Dietician'
      this.title = 'Mr'
      this.firstName = 'Damian'
      this.lastName = 'Helme'
      this.practiceName = 'The Health Centre'
      this.addressLine1 = 'The High St.'
      this.addressLine2 = 'Great Shelford'
      this.city = 'Cambridge'
      this.state = 'Cambs'
      this.postcode = 'CB1 1XX'
      this.country = "GB"
    },

    emailOKRule: emailOKRule,
    usernameOKRule: usernameOKRule,
    requiredRule: requiredRule,

    doSubmit: function (evt) {
      try {
        // if the user tries to submit when any of the fields fail validation, the feedback will appear on the screen
        // on a per-field basis

        // stop the submit button click from refreshing the page
        // evt.preventDefault()

        // trigger the validations on the child password form
        if (this.$refs.regform.validate()) {
          LoaderUtils.start()
          const encryptedPassword = PasswordUtils.encrypt(this.password)

          // the data includes creating a free plan by default, if the user has selected a premium plan we
          // upgrade it to a premium plan later
          const initialPlan = (!this.plan.premium) ? this.plan.name : this.getDefaultFreePlan().name

          const data = {
            username: this.username,
            email: this.email,
            password: encryptedPassword,
            profession: this.profession,
            professionOther: this.professionOther,
            title: this.title,
            titleOther: this.titleOther,
            firstName: this.firstName,
            lastName: this.lastName,
            practiceName: this.practiceName,
            website: this.website,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            city: this.city,
            state: this.state,
            zip: this.postcode,
            country: this.country,
            planName: initialPlan
          }

          if (this.registerFromInvite) {
            data.verifyEmailToken = this.token
          }

          ClinicianAccountService.createClinicianAccount(data).then(() => {
            LoaderUtils.stop()

            // find selected plan in the list of plans
            const selectedPlan = this.plan
            if (selectedPlan.premium) {
              // take user to Paddle
              this.showCheckout = true;
            } else {
              this.afterAccountCreation()
            }
          }).catch(err => {
            LoaderUtils.stop()
            const cause = VError.cause(err)
            // test for the particular errors of username or email exists
            if (err.name === HttpStatus.CONFLICT.toString() && (
                cause.response.data.code === "EMAIL_EXISTS" || cause.response.data.code === "USERNAME_EXISTS"
            )) {
              Logger.error(cause.response.data.message)
            } else if (err.name === HttpStatus.BAD_REQUEST.toString() && cause.response.data.code === "EMAIL_VERIFICATION_ERROR") {
              Logger.error(this.regByLinkFailureMsg)
            } else {
              // an axios error, e.g. network error
              Logger.error(err)
            }
          })
        } else {
          Logger.error("Some fields on the form contain errors. Please fix and try again.")
        }
      } catch (e) {
        Logger.error(e)
      }
    },

    afterAccountCreation() {
      if (this.registerFromInvite) {
        this.$router.push({name: 'home'})
        Logger.info('Your account has been created. Please sign in.')
      } else {
        // Note we can't sign them in straight away as they need to verify their account first
        this.$router.push({name: 'verification_email_notification'})
      }
    },

    // by default we'll create a free plan for everyone, we'll assume there's at least one free plan available,
    // we'll take the one with the fewest number of users
    getDefaultFreePlan() {
      const freePlans = _.filter(this.plans, x => !x.premium)
      const x = _.minBy(freePlans, 'numClients')
      return x
    },

    handlePaymentClosedWarningOK() {
      this.afterAccountCreation();
    },
  },

  computed: {
    seenBetaInfoPage() {
      const ret = this.$route.query.seenBetaInfo === "true" || false
      return ret
    },
    inBeta() {
      const ret = process.env.VUE_APP_BETA_TRIAL === "true" || false
      return ret
    },
    showPopulateDummyDataButton() {
      // we only want to show this button in dev and deployed to the test (maybe staging at some point)
      const ret = process.env.VUE_APP_MYSYMPTOMS_MODE === "development" ||
          process.env.VUE_APP_MYSYMPTOMS_MODE === "test_deploy" ||
          process.env.VUE_APP_MYSYMPTOMS_MODE === "test"

      return ret
    },

    getLang() {
      return navigator.language || navigator.browserLanguage || (navigator.languages || ["en"]) [0]
    },

    /**
     * Returns an array of plans with a display text formatted for display based on the Country selected. It adds field:
     *    text: 'Bronze, 20 Clients, £15.00'
     * Where the price is in the currency corresponding to the selected country
     *
     * @returns {Array|*}
     */
    plansFormatted() {

      const that = this
      // iterate through the plans adding a display text element that shows the price in the correct currency

      const formattedPlans = _.map(this.plans, plan => {

        let price;
        if (plan.premium) {

          const ccy = CountryToCurrency.CountryToCurrency[this.country]
          if (ccy === 'GBP') {
            price = plan.priceGBP
          } else if (ccy === 'EUR') {
            price = plan.priceEUR
          } else {
            price = plan.priceUSD
          }
          price = price + '  per month'
        } else {
          price = "Free"
        }
        plan.text = `${plan.displayName}, ${plan.numClients} clients, ${price}`
        return plan
      })

      return formattedPlans
    }
  },

  created() {

    const that = this

    LoaderUtils.start()

    SubscriptionPlanService.getSubscriptionPlans(
        SubscriptionPlanService.level1Name,
        SubscriptionPlanService.level2Name,
    ).then(resp => {

      const tempPlans = _.map(resp.data, x => {

        const priceUSD = x.priceUSD === null ? 'Free' : new Intl.NumberFormat(that.getLang, {
          style: 'currency',
          currency: 'USD'
        }).format(x.priceUSD)
        const priceEUR = x.priceEUR === null ? 'Free' : new Intl.NumberFormat(that.getLang, {
          style: 'currency',
          currency: 'EUR'
        }).format(x.priceEUR)
        const priceGBP = x.priceGBP === null ? 'Free' : new Intl.NumberFormat(that.getLang, {
          style: 'currency',
          currency: 'GBP'
        }).format(x.priceGBP)

        return {
          value: x.name,
          name: x.name,
          displayName: x.displayName,
          premium: x.premium,
          paddleId: x.paddleId,
          numClients: x.numClients ? x.numClients : "Unlimited", // null means unlimted
          priceUSD: priceUSD,
          priceEUR: priceEUR,
          priceGBP: priceGBP,
        }
      })
      LoaderUtils.stop()
      // we want to sort by ascending value .. a bit of a problem in that which value do we take? If we assume that
      // prices will be set for all USD, EUR and GBP, then it doesn't matter
      that.plans = _.sortBy(tempPlans, ['premium', 'priceGBP']);
      // Logger.debug(that.plans)
    }).catch(err => {
      LoaderUtils.stop()
      Logger.error(err)
    })

    // if the user has clicked on a link in a invitation email, need to check token and email params set
    if (this.registerFromInvite) {
      if (typeof this.token === 'undefined' || typeof this.initialEmail === 'undefined') {
        throw new VError(`initialEmail: ${this.initialEmail} and token: ${this.token} need to be defined when registering from an invitation link.`)
      }
      this.email = this.initialEmail
    }

    // we have certain restrictions / modifications in place during the beta
    if (this.inBeta) {

      // during the beta trial we only accept invitations from the dummy patient sending out invites
      // the user has clicked on the 'Register' link on the website, block them
      if (!this.registerFromInvite) {
        this.$router.push({name: 'signin'})
        // Logger.error('Sorry, during the beta trial registrations are by invite only.')
        Logger.error('Sorry, we are not yet open for new registrations. Contact clinic@mysymptoms.net for more information')
      } else {

        // before allowing the registration, redirect the user to an info page.
        // This beta info page will redirect them back here with the 'seenBetaInfo' flag set.
        const seenInfo = this.seenBetaInfoPage
        if (!seenInfo) {
          this.$router.push({
            name: 'clinician_beta_info_from_email_link',
            props: {
              token: this.token,
              email: this.initialEmail
            },
            query: {
              // let the beta_info page know its been called by the registration page so it can send the user back here.
              fromRegPage: "true",
            }
          })
        }
      }
    }
  },

}

</script>
