import Api from './api'

const path = 'private/ui_one_off_event'

export default {

  hasUIEventHappenedForUser(username, uiEventName) {
    const eventEncoded = encodeURIComponent(uiEventName)
    const usernameEncoded = encodeURIComponent(username)
    const fullPath = `${path}/${usernameEncoded}/${eventEncoded}`
    return Api.get(fullPath)
  },

  setUIEventHappenedForUser(username, uiEventName) {
    const eventEncoded = encodeURIComponent(uiEventName)
    const usernameEncoded = encodeURIComponent(username)

    const data = {
      username: usernameEncoded,
      uioneOffEventName: eventEncoded,
    }
    return Api.post(`${path}/`, data)
  }
}
