<template>
  <div>
    <div class="mysymptoms-main-container">
      <v-layout>
        <v-flex xs8>
          <h1 class="pt-3">Notify Patients</h1>
        </v-flex>
      </v-layout>

      <p>
        Your patients can share their food and symptom diaries with you by sending you an invitation from within
        the mobile app.
      </p>
      <p>
        Use the form below so that we can send them instructions on your behalf.
      </p>
      <p>
        The email will include your email address ({{ clinicianEmail }}), which they will need in order to invite
        you.
      </p>
      <p>Invitations from your patients are listed
        <router-link
            :to="{ name: 'patient_invitations'}"
            target="_blank">
          here.
        </router-link>
      </p>
      <v-flex md4>
        <v-form ref="form" v-model="formValid" @submit="onNotifyPatientSubmit">

          <v-text-field
              autocomplete="off"
              v-model="patientEmail"
              label="Patient Email"
              :rules="[requiredRule, emailOKRule]"
              :success="!!patientEmail"
          ></v-text-field>

          <v-btn
              color="primary"
              type="submit"
          >
            Send
          </v-btn>
        </v-form>
      </v-flex>
      <v-flex md4/>
    </div>
  </div>
</template>

<script>

import Logger from '../utils/logger-utils.js'
import ClinicianAccountService from '../utils/clinician-account-service.js'
import VError from 'verror'
import Loader from '../utils/loader-utils.js'
import {checkProperties} from '../utils/helpers'
import {requiredRule, emailOKRule} from "../utils/rules";

export default {
  name: 'TermsOfUse',
  data() {
    return {
      patientEmail: '',
      formValid: false,
      clinicianEmail: ''
    }
  },
  methods: {
    requiredRule: requiredRule,
    emailOKRule: emailOKRule,

    username() {
      return this.$store.state.username
    },

    onNotifyPatientSubmit(evt) {


      try {

        // Logger.debug("notify patient form submit");

        // stop the submit button click from refreshing the page
        evt.preventDefault()

        const isValid = this.$refs.form.validate()

        if (isValid) {

          // log event with Google Analytics
          this.$gtag.event('notify_patient');

          Loader.start()
          ClinicianAccountService.sendInstructionsToPatient(this.username(), this.patientEmail).then(() => {
            Loader.stop()
            Logger.info('Instructions have been sent.')
          })
        }
      } catch (error) {
        Loader.stop()
        Logger.error(new VError(error, `Invite patient failed`))
      }
    }
  },

  created() {
    ClinicianAccountService.getClinicianAccountInfo(this.username()).then(res => {
      checkProperties(res, 'data')
      const data = res.data
      checkProperties(data, 'email')
      this.clinicianEmail = data.email;
    })
  }
};
</script>
