import Api from './api'

const clinicianAccountPath = '/private/clinician'
const clinicianAccountPathPublic = '/public/clinician'
const resendVerificationEmailPath = `${clinicianAccountPathPublic}/resend_verification_email`
const verifyPath = `${clinicianAccountPathPublic}/verify`
const verificationPendingStatuspath = `${clinicianAccountPathPublic}/verification_pending_status`

export default {
  createClinicianAccount(clinicianData) {
    return Api.post(clinicianAccountPathPublic, clinicianData)
  },

  updateClinicianAccount(username, clinicianData) {
    const url = `${clinicianAccountPath}/${clinicianData.username}`
    return Api.put(url, clinicianData)
  },

  getClinicianAccountInfo(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${clinicianAccountPath}/${encodedUsername}`
    return Api.get(path)
  },

  /**
   *
   * @param pageNumber - page number, zero based
   * @param pageSize -
   * @param sort - "asc" or "desc"
   * @param field - sort field, e.g. 'id' ...
   * @returns {Promise}
   */
  getClinicianAccountsInfo(pageNumber, pageSize, sort, field, searchTerm) {
    let path = `${clinicianAccountPath}?page=${pageNumber}&size=${pageSize}&sort=${sort}&field=${field}`
    if (searchTerm != "") {
      path = path + `&searchTerm=${searchTerm}`
    }
    return Api.get(path)
  },

  getPatients(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${clinicianAccountPath}/${encodedUsername}/patient`
    return Api.get(path)
  },

  getPendingInvitations(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${clinicianAccountPath}/${encodedUsername}/invite`
    return Api.get(path)
  },

  getPreviousInvitations(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${clinicianAccountPath}/${encodedUsername}/invitehistory`
    return Api.get(path)
  },

  deletePatient(clinicianUsername, patientUsername) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername) // in case there are any awkward characters in the username
    const encodedPatientUsername = encodeURIComponent(patientUsername) // in case there are any awkward characters in the username
    const path = `${clinicianAccountPath}/${encodedClinicianUsername}/patient/${encodedPatientUsername}`
    return Api.delete(path)
  },

  resendVerificationEmail(email) {
    return Api.post(resendVerificationEmailPath, {email: email})
  },

  verifyEmail(email, token) {
    return Api.post(verifyPath, {email: email, token: token})
  },

  isAccountPending(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${verificationPendingStatuspath}/${encodedUsername}`
    return Api.get(path)
  },

  acceptInvite(clinicianUsername, patientUsername) {
    const encodedCliniciaUsername = encodeURIComponent(clinicianUsername)
    const encodedPatientUsername= encodeURIComponent(patientUsername)
    const path = `${clinicianAccountPath}/${encodedCliniciaUsername}/invite/${encodedPatientUsername}/accept`
    return Api.post(path)
  },

  declineInvite(clinicianUsername, patientUsername) {
    const encodedCliniciaUsername = encodeURIComponent(clinicianUsername)
    const encodedPatientUsername= encodeURIComponent(patientUsername)
    const path = `${clinicianAccountPath}/${encodedCliniciaUsername}/invite/${encodedPatientUsername}/reject`
    return Api.put(path)
  },

  sendInstructionsToPatient(clinicianUsername, patientEmail ) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername)
    const encodedPatientEmail = encodeURIComponent(patientEmail)
    const path = `${clinicianAccountPath}/${encodedClinicianUsername}/send_invite_instructions/${encodedPatientEmail}`
    return Api.post(path)
  },

  getUnreadNews(clinicianUsername) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername)
    const path = `${clinicianAccountPath}/${encodedClinicianUsername}/unread_news`
    return Api.get(path)
  },

  markNewsAsRead(clinicianUsername) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername)
    const path = `${clinicianAccountPath}/${encodedClinicianUsername}/mark_news_as_read`
    return Api.put(path)
  },


  getActiveSubscription(clinicianUsername) {
    const encodedUsername = encodeURIComponent(clinicianUsername)
    const path = `${clinicianAccountPath}/${encodedUsername}/subscription`
    return Api.get(path)
  },

  getSubscriptionPayments(clinicianUsername) {
    const encodedUsername = encodeURIComponent(clinicianUsername)
    const path = `${clinicianAccountPath}/${encodedUsername}/subscriptionpayment`
    return Api.get(path)
  },

  // called to put the user on a free plan only used when first created, or when downgrading from a
  // premium account
  postFreeSubscriptionPlan(clinicianUsername, subsPlanName) {
    const encodedUsername = encodeURIComponent(clinicianUsername)
    const encodedPlanName = encodeURIComponent(subsPlanName)
    const data = {
      username: encodedUsername,
      subscriptionPlanName: encodedPlanName,
      subscriptionPlanDisplayName: "not used",
      status: "active"
    }
    const path = `${clinicianAccountPathPublic}/subscription`
    return Api.post(path, data)
  },

  // called when the user is on a premium plan and we want to change to another premium plan
  updatePremiumSubscriptionPlan(username, newSubsPlanName) {
    const encodedUsername = encodeURIComponent(username)
    const encodedPlanName = encodeURIComponent(newSubsPlanName)
    const data = {
      username: encodedUsername,
      newSubscriptionPlanName: encodedPlanName,
      passthrough: `{"username": "${username}", "plan_name": "${newSubsPlanName}"}`
    }
    const path = `${clinicianAccountPath}/updatesubscription`
    return Api.post(path, data)
  },

  // called when the user is on a premium plan and wants to go to the default free plan
  cancelPremiumSubscriptionPlan(username) {
    const encodedUsername = encodeURIComponent(username)
    const path = `${clinicianAccountPath}/${username}/cancelsubscription`
    return Api.post(path)
  }

}
