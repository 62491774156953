/**
 * encodeName - utility function to prepare an real name for storing in localStorage
 * @param name
 * @returns {string}
 */
export default {

  saveRealNameToLocalStorage(patientUsername, patientRealName) {
    // obfuscate the name before storing
    const encodedRealName = this.encodeName(patientRealName)
    localStorage.setItem(patientUsername, encodedRealName) // expiry an arbitrarily large num
  },

  getRealNameFromLocalStorage(patientUsername) {
    const encodedRealName = localStorage.getItem(patientUsername)
    const decodedRealName = encodedRealName ? this.decodeName(encodedRealName) : ""
    return decodedRealName
  },

  encodeName(name) {
    // remove characters that would interfere with storing as a cookie e.g. ';'
    const encodedName = encodeURIComponent(name)

    // obfuscate the name
    const bcoded = btoa(encodedName)
    return bcoded
  },

  decodeName(name) {
    const bdecoded = atob(name)
    const decodedName = decodeURIComponent(bdecoded)
    return decodedName
  }
}

