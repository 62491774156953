import Api from './api'

const path = 'private/uianalytics'

export default {

  logUIAnalyticsEvent(uiEventPayload) {
    const payloadEncoded = encodeURIComponent(uiEventPayload)
    // const usernameEncoded = encodeURIComponent(username)
    const fullPath = `${path}?qry=${payloadEncoded}`
    return Api.get(fullPath)
  },

}
