<!--suppress JSUnresolvedVariable -->
<template>
  <div>
    <v-select
        v-bind:value="profession"
        :items="professionOptions"
        :rules="[requiredRule]"
        label="Profession"
        required
        :success="!!profession"
        @change="clearProfessionOther"
        v-on:input="$emit('update:profession', $event)"
    ></v-select>
    <div v-if="showProfessionOther">
      <p>Please specify:</p>
      <v-text-field
          v-if="showProfessionOther"
          v-bind:value="professionOther"
          :rules="[len64Rule]"
          label="Profession (other)"
          :success="!!professionOther"
          v-on:input="$emit('update:professionOther', $event)"
      ></v-text-field>
    </div>
    <v-select
        v-bind:value="title"
        :items="titleOptions"
        :rules="[requiredRule]"
        label="Title"
        required
        :success="!!title"
        @change="clearTitleOther"
        v-on:input="$emit('update:title', $event)"
    ></v-select>
    <div v-if="showTitleOther">
      <p>Please specify:</p>
      <v-text-field
          v-bind:value="titleOther"
          :rules="[len64Rule]"
          label="Title (other)"
          :success="!!titleOther"
          v-on:input="$emit('update:titleOther', $event)"
      ></v-text-field>
    </div>
    <v-text-field
        v-bind:value="firstName"
        :rules="[requiredRule, len64Rule]"
        label="First Name"
        :success="!!firstName"
        v-on:input="$emit('update:firstName', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="lastName"
        :rules="[requiredRule, len64Rule]"
        label="Last Name"
        :success="!!lastName"
        v-on:input="$emit('update:lastName', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="practiceName"
        :rules="[requiredRule, len64Rule]"
        label="Practice Name"
        :success="!!practiceName"
        v-on:input="$emit('update:practiceName', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="website"
        :rules="[optLen128Rule]"
        label="Website"
        :success="!!website"
        v-on:input="$emit('update:website', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="addressLine1"
        :rules="[requiredRule, len64Rule]"
        label="Address"
        :success="!!addressLine1"
        v-on:input="$emit('update:addressLine1', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="addressLine2"
        :rules="[len64Rule]"
        label="Address Line 2"
        :success="!!addressLine2"
        v-on:input="$emit('update:addressLine2', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="city"
        :rules="[requiredRule, len64Rule]"
        label="City"
        :success="!!city"
        v-on:input="$emit('update:city', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="state"
        :rules="[requiredRule, len64Rule]"
        label="State / Province"
        :success="!!state"
        v-on:input="$emit('update:state', $event)"
    ></v-text-field>
    <v-text-field
        v-bind:value="postcode"
        :rules="[requiredRule, len64Rule]"
        label="Postal / Zip Code"
        :success="!!postcode"
        v-on:input="$emit('update:postcode', $event)"
    ></v-text-field>
    <v-autocomplete
        v-bind:value="country"
        :items="countryOpts"
        :rules="[requiredRule, len64CountryRule]"
        label="Country"
        required
        :success="!!country"
        :menu-props="{allowOverflow:false}"
        v-on:input="$emit('update:country', $event)"
    >
      append-outer-icon="help"
      <v-tooltip slot="append-outer" bottom>
        <v-icon
            slot="activator"
            light
        >help
        </v-icon>
        <span>
                            Due to regulatory restrictions, MySymptoms Clinic is only available in a limited number of countries.
                        </span>
      </v-tooltip>
    </v-autocomplete>
  </div>
</template>

<script>
import CountryCodes from "../utils/country-codes";
import {len64Rule, optLen128Rule, requiredRule} from "../utils/rules";

const DieticianOpt = 'Registered Dietician'
const DoctorOpt = 'Medical Doctor'
const NutrionistOpt = 'Registered Nutritionist'
const TrainerOpt = 'Physical Trainer'
const OtherOpt = 'Other'

export default {
  name: "ClinicianCommonFields",
  props: [
    'profession',
    'professionOther',
    'title',
    'titleOther',
    'firstName',
    'lastName',
    'practiceName',
    'website',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postcode',
    'country'
  ],
  data() {
    return {
      professionOptions: [DieticianOpt, DoctorOpt, NutrionistOpt, TrainerOpt, OtherOpt],
      titleOptions: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', OtherOpt],
      countryOpts: CountryCodes.countryCodesArray()
    }
  },
  methods: {
    clearProfessionOther(e) {
      if (e !== OtherOpt) {
        this.professionOther = ''
        this.$emit('update:professionOther', '')
      }
    },

    clearTitleOther(e) {
      if (e !== OtherOpt) {
        this.titleOther = ''
        this.$emit('update:titleOther', '')
      }
    },

    requiredRule: requiredRule,

    len64Rule: len64Rule,
    optLen128Rule: optLen128Rule,

    len64CountryRule(v) {
      return !!v && (v.length <= 64 || "Must be less than 64 characters.")
    }
  },
  computed: {
    showTitleOther() {
      return this.title === OtherOpt
    },

    // when the profession is selected from the dropdown, make sure the Profession Other field is cleared
    showProfessionOther() {
      return this.profession === OtherOpt
    },

  }
}
</script>

