import {EventBus} from "./event-bus"

export default {

  start() {
    EventBus.$emit('loader-start')
  },

  stop() {
    EventBus.$emit('loader-stop')
  }
}
