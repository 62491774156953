<template>
    <v-layout>
        <v-flex xs2 md4>
        </v-flex>
        <v-flex xs8 md4>
            <v-form ref="passwordResetForm" v-model="formValid" class="mysymptoms-form-container"
                    @submit="formSubmit">
                <h2 class="text-center">Reset Password</h2>
                <hr/>
                <v-text-field
                        name="email"
                        v-model="email"
                        type="email"
                        placeholder="Clinician Email Address"
                        autofocus
                        autocomplete="email"
                        hint="For Clinician accounts only. For mobile accounts please use the mobile app."
                        persistent-hint
                        :rules="[requiredRule, emailOKRule]"
                        :success="!!email"></v-text-field>
                <v-btn
                        block
                        @click="formSubmit"
                        color="primary"
                >
                    Submit
                </v-btn>
            </v-form>
        </v-flex>
        <v-flex xs2 md4></v-flex>
    </v-layout>
</template>

<script>
  import Logger from '../utils/logger-utils'
  import VError from 'verror'
  import AccountService from '../utils/account-service'
  import Loader from '../utils/loader-utils'
  import {requiredRule, emailOKRule} from "../utils/rules";


  export default {
    name: "ResetPasswordRequest",
    data() {
      return {
        email: '',
        formValid: false
      }
    },
    methods: {
      emailOKRule: emailOKRule,
      requiredRule: requiredRule,

      formSubmit(evt) {
        try {
          // stop the submit button click from refreshing the page
          evt.preventDefault()

          // only submit form the field validations have passed.
          const form = this.$refs['passwordResetForm']
          const isValid = form.validate()
          if (isValid) {
            Loader.start()
            AccountService.resetPasswordRequestPublic(this.email).then(() => {
              Loader.stop()
              Logger.info("If we recognise your address we will send an email with further instructions.")
              this.$router.push({name: 'signin'})
            }).catch(err => {
              Loader.stop()
              Logger.error(new VError(err, `Reset Password Request failed`))
            })
          }
        } catch (ex) {
          Logger.error(new VError(ex, `Resetpassword Request failed`))
        }
      },
    }
  }
</script>

