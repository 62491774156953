<template>
  <div class="mysymptoms-main-container">

    <!--    <v-layout>-->
    <!--      <v-flex xs8>-->
    <!--        <h1 class="display-1 pt-3">Patients</h1>-->
    <!--      </v-flex>-->
    <!--      <v-flex xs4>-->
    <!--        <v-text-field-->
    <!--            v-model="search"-->
    <!--            append-icon="search"-->
    <!--            label="Search"-->
    <!--            single-line-->
    <!--            hide-details-->
    <!--        ></v-text-field>-->
    <!--      </v-flex>-->
    <!--    </v-layout>-->
    <!--    <v-layout v-if="this.patientsLoaded && this.patients.length == 0">-->
    <!--      <p>New to mySymptoms Clinic? Check out our-->
    <!--        <a-->
    <!--            href="https://mysymptoms.zendesk.com/hc/en-us/articles/5655387160594-mySymptoms-Clinic-Getting-Started-Guide"-->
    <!--            target="_blank">-->
    <!--          Getting Started Guide-->
    <!--        </a>.-->
    <!--      </p>-->
    <!--    </v-layout>-->
    <v-layout>
      <v-flex xs8>
        <!--        <h1 class="display-1 pt-3">Invitations</h1><v-icon>refresh</v-icon>-->
        <v-card flat>
          <v-card-title class="pl-0">
            <h1 class="pt-3">Patients</h1>
            <v-btn
                icon
                @click="triggerPendingInvitationsLoad()"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                      medium
                      v-on="on"
                      color="grey darken-1">
                    refresh
                  </v-icon>
                </template>
                <span>Check for new patient invites</span>
              </v-tooltip>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs4>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-card>
      <!-- main data table -->
      <!-- Note, we are not using the v-data-table built in search. Instead, we are pre-filtering the items so we can
      search on the displayed Patient Since date string-->
      <!-- pagination https://github.com/vuetifyjs/vuetify/issues/442 -->
      <v-data-table
          :headers="headers"
          :items="filteredPatients"
          must-sort
          no-data-text="No patients found."
          no-results-text="No patients found."
          :custom-sort="sortTable"
          v-bind:pagination.sync="pagination"
          v-bind:rows-per-page-items="rowsPerPageItems"
      >
        <template slot="headerCell" slot-scope="props">
          <span v-if="!('tooltip' in props.header) || props.header.tooltip === ''">{{ props.header.text }}</span>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.header.text }}</span>
            </template>
            <span>{{ props.header.tooltip }}</span>
          </v-tooltip>
        </template>

        <template slot="items" slot-scope="props"
        >
          <!--          :to="{name: 'patient_diary', params: { patientUsername: props.item.username, clinicianUsername: clinicianUsername }}"-->
          <!-- if this is pending invite, disable the :to by setting a {} -->
          <router-link
              tag="tr"
              :to="props.item.pending ? {} : {name: 'patient_diary', params: { patientUsername: props.item.username, clinicianUsername: clinicianUsername }}"
              class="black--text cursor_pointer"
              v-bind:class="{disabled: isRowDisabled(filteredPatients.indexOf(props.item)),
                            pending: props.item.pending}"
          >
            <td>
              <!--              v-if="props.item.pending"-->
              <v-icon
                  color="red"
                  small
                  v-if="props.item.pending"
                  slot="activator"
              >
                lens
              </v-icon>
            </td>
            <td class="justify-left">
              {{ props.item.username }}
            </td>
            <td>
              {{ props.item.realName }}
            </td>
            <td>{{ props.item.patientSince }}</td>
            <td>{{ props.item.lastUpdated }}</td>
            <td class="justify-center px-24 enabled">
              <!--              only show the 'edit realname' button if the name wasn't got from the database (backwards compatibility)-->
              <v-dialog
                  v-if="!props.item.isRealNameSetFromDb"
                  width="400"
                  v-model="showEditRealNameDialogue[props.item.username]"
              >
                <v-btn icon slot="activator">
                  <v-tooltip bottom>
                    <v-icon slot="activator">
                      edit
                    </v-icon>
                    <span>Edit Real Name</span>
                  </v-tooltip>
                </v-btn>
                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    Edit Real Name
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-text-field
                          slot="input"
                          v-model="props.item.realName"
                          :rules="[max25chars]"
                          label="Name"
                          single-line
                          autofocus
                          @keyup.enter="saveRealName(props.item)"
                      >
                      </v-text-field>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        flat
                        @click="saveRealName(props.item)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!--              confirm delete patient-->
              <v-dialog
                  v-if="!props.item.pending"
                  v-model="confirmDeleteDialog[props.item.username]"
                  width="500"
              >
                <!-- note: clicking on the activator slot opens the encompassing v-dialog -->
                <v-btn icon slot="activator">
                  <v-tooltip bottom>
                    <v-icon slot="activator">
                      delete
                    </v-icon>
                    <span>Remove from patient list</span>
                  </v-tooltip>
                </v-btn>

                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    Confirm
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to remove {{ props.item.username }} from your patient list?
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        flat
                        @click="confirmDeleteDialog[props.item.username] = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                        color="primary"
                        flat
                        @click="deleteItem(props.item)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- accept / decline pending invitation buttons-->
              <div v-if="props.item.pending">
                <!-- Accept Invitation Button -->
                <v-btn icon
                       @click="acceptInvite(props.item.username)"
                >
                  <v-tooltip bottom
                  >
                    <v-icon
                        slot="activator"
                    >
                      check
                    </v-icon>
                    <span>Accept Patient Request</span>
                  </v-tooltip>
                </v-btn>

                <!-- Decline Invitation Button -->
                <v-dialog
                    v-model="showDialog[props.item.username]"
                    width="500"
                >
                  <v-btn icon slot="activator">

                    <v-tooltip bottom>
                      <v-icon
                          slot="activator"
                      >
                        close
                      </v-icon>
                      <span>Decline Patient Request</span>
                    </v-tooltip>
                  </v-btn>

                  <v-card>
                    <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                    >
                      Confirm
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to decline {{ props.item.username }}'s
                      invitation?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          flat
                          @click="showDialog[props.item.username] = false"
                      >
                        No
                      </v-btn>
                      <v-btn
                          color="primary"
                          flat
                          @click="declineInvite(props.item.username)"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <span class="pending-badge">Invitation Pending</span>
              </div>
            </td>
          </router-link>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showClientLimitPopup" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Client Limit</v-card-title>
        <v-card-text>
          You have reached the client limit for your plan. If you wish to upgrade your plan please click
          <router-link :to="{name: 'plans'}" target="_parent">here</router-link>
          to accept more clients.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="showClientLimitPopup=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import ClinicianAccountService from '../utils/clinician-account-service'
import Logger from '../utils/logger-utils'
import {checkProperties} from '../utils/helpers'
import Cookies from 'js-cookie'
import Loader from "../utils/loader-utils"
import moment from 'moment'
import VDialog from "vuetify/lib/components/VDialog/VDialog";
import RealNameHelpers from '../utils/real-name-helpers'
import VError from "verror";
import * as HttpStatus from "http-status-codes";
import {EventBus} from "../utils/event-bus";

export default {
  name: "PatientManagement",
  components: {VDialog},
  data() {
    return {
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      headers:
          [
            {text: 'Pending', value: 'pending', sortable: false, width: '1%'},
            {text: 'Patient Username', value: 'username', sortable: true},
            {text: 'Name', value: 'realName', sortable: true, searchable: true},
            // note we're sorting on the numeric version of the Patient Since but displaying the text version
            // note also that we're overload the Since field, for invites it's the date the invite was sent, for
            // accepted patients, in the date the patient was accepted
            {text: 'Since', value: 'patientSinceSortable', sortable: true},
            {text: 'Last Updated', value: 'lastUpdatedSortable', sortable: true},
            {text: 'Actions', value: 'actions', sortable: false},
          ],
      patients: [],
      patientsLoaded: false,
      search: '',
      pagination: {'sortBy': 'patientSinceSortable', 'descending': true, 'rowsPerPage': 25},
      rowsPerPageItems: [10, 25, 50, {text: 'All', value: -1}],
      confirmDeleteDialog: {},
      showEditRealNameDialogue: {},
      showClientLimitPopup: false,
      activeTab: '', // not actually used other than being needed for the v-tabs control
      previousInvitations: [],
      pendingInvitationsHeaders:
          [
            {text: 'Patient Username', value: 'username', sortable: true},
            {text: 'Name', value: 'patientRealName', sortable: true},
            {text: 'Requested', value: 'patientSinceSortable', sortable: true},
            {text: 'Actions', value: 'actions', sortable: false},
          ],
      // previousInvitationsHeaders:
      //     [
      //       {text: 'Patient Username', value: 'username', sortable: true},
      //       {text: 'Name', value: 'patientRealName', sortable: true},
      //       {text: 'Requested', value: 'requestDateSortable', sortable: true},
      //       {text: 'Accepted', value: 'accptedDateSortable', sortable: true},
      //       {text: 'Declined', value: 'rejectedDateSortable', sortable: true},
      //       {text: 'Deleted', value: 'deletedDateSortable', sortable: true},
      //     ],
      showDialog: {}
    }
  },

  props: {
    clinicianUsername: {
      type: String,
      required: true
    },
  },

  methods: {

    // we implement a custom sort so that the pending always appear at the top
    sortTable(items, index, isDescending) {
      const ascDesc = isDescending ? 'desc' : 'asc'
      const i = _.orderBy(items, ['pending', index], ['asc', ascDesc])
      return i
    },
    // when we're on a plan with a limited number clients, the clinician may have downgraded from a plan with
    // more clients. In that case disable access to a number of patients that exceed the limit for this plan
    // OR
    // if the account is paused, disable them all
    isRowDisabled(row_cnt) {
      if (this.$store.state.plan.status === 'paused') {
        return true
      } else if (!this.$store.state.plan.numClients) {
        // numClients not defined so we are on an unlimited plan
        return false
      } else {
        return row_cnt >= this.$store.state.plan.numClients
      }
    },

    saveRealName(patient) {
      RealNameHelpers.saveRealNameToLocalStorage(patient.username, patient.realName)
      this.showEditRealNameDialogue[patient.username] = false
    },

    deleteItem(patient) {
      this.confirmDeleteDialog[patient.username] = false
      Loader.start()
      ClinicianAccountService.deletePatient(this.$store.state.username, patient.username).then(() => {
        // remove the patient from the screen so we don't have to do a reload of the from the server
        const idx = this.patients.indexOf(patient)
        if (idx < 0) {
          throw new Error(`Could not find patient: ${JSON.stringify(patient)}`)
        }
        this.patients.splice(idx, 1)

        // remove the cookie containing the real name
        Cookies.remove(patient.username)
        Loader.stop()
      }).catch(err => {
        Logger.error(err)
        Loader.stop()
      })
    },

    removeReqFromPending(username) {
      const newReqList = this.pendingInvitations.filter(el => el.username !== username)
      this.$store.commit('setPendingPatientInvitations', {value: newReqList, broadcast: false})
    },

    acceptInvite(username) {
      Loader.start()
      ClinicianAccountService.acceptInvite(this.$store.state.username, username).then(() => {
        Loader.stop()
        // remove element from the array
        this.removeReqFromPending(username)
        Logger.info("Invitation accepted.")
        // Refresh the previous list so it displays this item.
        // NB this returns a promise which populates this.previousInvitations
        // return this.getPreviousInvitationsPromise(this.$store.state.username)
      }).then(() => {
        // need to refresh the patients list to include the just accepted patient, forcing a db reload is the lazy way to do it,
        // but we'll live with it
        this.getPatients()
      }).catch(err => {
        Loader.stop()
        const cause = VError.cause(err)
        if (err.name === HttpStatus.FORBIDDEN.toString() && (cause.response.data.code === "CLIENT_LIMIT_REACHED")) {
          this.showClientLimitPopup = true
        } else {
          Logger.error(err)
        }

      })
    },

    declineInvite(username) {
      this.showDialog[username] = false
      Loader.start()
      ClinicianAccountService.declineInvite(this.$store.state.username, username).then(() => {
        Loader.stop()
        // remove element from the array
        this.removeReqFromPending(username)
        Logger.info("Invitation Declined.")
        // Refresh the previous list so it displays this item.
        // NB this returns a promise which populates this.previousInvitations
        // return this.getPreviousInvitationsPromise(this.$store.state.username)
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    },

    /**
     * NB returns a Promise
     */
    // getPreviousInvitationsPromise(clinicianUsername) {
    //   return ClinicianAccountService.getPreviousInvitations(clinicianUsername).then(res => {
    //     this.previousInvitations = res.data.map(request => {
    //       checkProperties(request, ['patient', 'createdAt', 'acceptedDate', 'deletedDate', 'rejectedDate', 'requestDate'])
    //       const patientRealName = ((request.hasOwnProperty('patientFirstName') && request.patientFirstName !== null)) ?
    //           request.patientFirstName + " " + request.patientLastName : ""
    //       const requestDate = moment(request.requestDate)
    //       const acceptedDate = request.acceptedDate ? moment(request.acceptedDate) : null
    //       const deletedDate = request.deletedDate ? moment(request.deletedDate) : null
    //       const rejectedDate = request.rejectedDate ? moment(request.rejectedDate) : null
    //       // noinspection ES6ShorthandObjectProperty
    //       const patientUsername = request.patient
    //       return {
    //         patientUsername: patientUsername,
    //         patientRealName: patientRealName,
    //         acceptedDate: acceptedDate ? acceptedDate.format(dateFormat) : '',
    //         accptedDateSortable: acceptedDate ? acceptedDate.valueOf() : 0, // for sorting on
    //         deletedDate: deletedDate ? deletedDate.format(dateFormat) : '',
    //         deletedDateSortable: deletedDate ? deletedDate.valueOf() : 0, // for sorting on
    //         rejectedDate: rejectedDate ? rejectedDate.format(dateFormat) : '',
    //         rejectedDateSortable: rejectedDate ? rejectedDate.valueOf() : 0, // for sorting on
    //         requestDate: requestDate ? requestDate.format(dateFormat) : '',
    //         requestDateSortable: requestDate ? requestDate.valueOf() : 0, // for sorting on
    //       }
    //     })
    //   })
    // },

    // getPreviousInvitations() {
    //   Loader.start()
    //   // this is a little bit 'smoke and mirrors' - the Loader only shows for getting the Previous Invitations, we
    //   // assume that the pending requests have already loaded into the Store from the backgroudn thread running in App
    //   this.getPreviousInvitationsPromise(this.$store.state.username).then(() => {
    //     Loader.stop()
    //   }).catch(err => {
    //     Loader.stop()
    //     Logger.error(err)
    //   })
    // },

    triggerPendingInvitationsLoad() {
      EventBus.$emit('check-for-pending-invites')
    },

    getPatients() {
      Loader.start()
      ClinicianAccountService.getPatients(this.clinicianUsername).then(resp => {
        checkProperties(resp, 'data')

        const tempData = resp.data.map(patient => {

          checkProperties(patient, ['patient', 'patientSince'])

          // check to see if we have stored a name for this user in a cookie, note names are stored encoded
          // noinspection JSUnresolvedVariable
          const patientName = patient.patient

          // in v0.8: the real name is now coming from the database, but to maintain backwards compatibility, if they
          // have patients who shared their diary before v0.8 was released, their real name maybe stored in cookies
          const firstNameFromDb = patient.firstName == null ? "" : patient.firstName
          const lastNameFromDb = patient.lastName == null ? "" : " " + patient.lastName
          // a constraint of the diary sharing is that both first and last name fields must be populated
          const realNameFromDb = firstNameFromDb + lastNameFromDb;
          const isRealNameSetFromDb = !(realNameFromDb === "")
          // if the realNameFromDb is empty, then look to see if there is anything in local storage
          const realName = !isRealNameSetFromDb ? RealNameHelpers.getRealNameFromLocalStorage(patientName) :
              realNameFromDb

          const sinceDate = moment(patient.patientSince)
          const lastUpdated = patient.latestSyncObject ? moment(patient.latestSyncObject) : null

          return {
            username: patientName,
            realName: realName ? realName : '',
            isRealNameSetFromDb: isRealNameSetFromDb,
            patientSince: sinceDate.format("DD MMM YYYY"),
            patientSinceSortable: sinceDate.valueOf(), // we need a version of the 'since' field for sorting on
            lastUpdated: lastUpdated ? lastUpdated.format("DD MMM YYYY") : '',
            lastUpdatedSortable: lastUpdated ? lastUpdated.valueOf() : 0,
            readOnly: false
          }

        })
        // we need to make sure the data is sorted by the default sort order, since if some of rows are disabled we need
        // to make sure it is the first few rows are enable (note rows are disabled if the user has downgraded from
        // thei subscription plan and has too many patients for their current plan
        // this.patients = _.orderBy(tempData, ["patientSinceSortable", 'desc']);
        this.patients = _.orderBy(tempData, ["patientSinceSortable"], ['desc'])
        this.patientsLoaded = true
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(err)
      })
    },
    getSortByStorageKey() {
      const sortByStorageKey = `clientsSortBy${this.$store.state.username}`
      return sortByStorageKey
    }
  },

  computed: {

    pendingAndAcceptedPatients() {
      return _.concat(this.pendingInvitations, this.patients)
    },
    /**
     * filters the patient records by searching ** only ** the string fields of the row. The row is included if any
     * field value contains the search text (case insensitive)
     */
    filteredPatients() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        // return this.patients
        return this.pendingAndAcceptedPatients
      }

      // const ret = this.patients.filter(patient => {
      const ret = this.pendingAndAcceptedPatients.filter(patient => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(patient)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    },

    pendingInvitations() {
      return this.$store.state.pendingPatientInvitations
    },
    /**
     * filters the requests records by searching ** only ** the string fields of the row. The row is included if any
     * field value contains the search text (case insensitive)
     */
    filteredPendingInvitations() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        return this.pendingInvitations
      }

      const ret = this.pendingInvitations.filter(req => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(req)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    },
    filteredPreviousInvitations() {
      const search = this.search.toString().toLowerCase()
      if (search.trim() === '') {
        return this.previousInvitations
      }

      const ret = this.previousInvitations.filter(req => {
        // loop over each member of the object, return true as soon as we find a field that contains the search string
        for (let [, value] of Object.entries(req)) {
          if (typeof value === "string" && value.toLowerCase().includes(search)) {
            return true
          }
        }
        // we didn't find any occurrence of the search string so return false
        return false
      })
      return ret
    },
  },

  watch: {
    pagination: {
      handler() {
        // save sort order to local storage
        const storageKey = this.getSortByStorageKey()
        const toStore = {
          sortBy: this.pagination.sortBy,
          descending: this.pagination.descending,
        }
        const sortByString = JSON.stringify(toStore)
        localStorage.setItem(storageKey, sortByString)
      },
      deep: true,
    }
  },

  mounted() {

    // check if we've stored the previous pagination details in the local storage
    const storageKey = this.getSortByStorageKey()
    const sortByString = localStorage.getItem(storageKey)
    if (sortByString) {
      const sortBy = JSON.parse(sortByString)
      if (sortBy.sortBy) {
        this.pagination.sortBy = sortBy.sortBy
      }
      if (sortBy.descending) {
        this.pagination.descending = sortBy.descending
      }
    }

    this.getPatients()
    // this.getPreviousInvitations()

  },
}
</script>

<style scoped>
.cursor_pointer {
  cursor: pointer;
  height: 42px;
  width: 42px;
}

/*https://stackoverflow.com/questions/61396466/how-to-disable-a-vuejs-router-link*/
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.enabled {
  opacity: 1;
  pointer-events: auto;
}

.pending {
  /*background-color: lightgrey;*/
  background-color: var(--v-primary-lighten3);
  pointer-events: none;
  cursor: auto;
}

.pending-badge {
  border-radius: 10px;
  font-weight: bold;
  /*font-size: 70%;*/
  padding: 8px;
  margin-left: 5px;
  margin-bottom: 10px;
  background-color: var(--v-primary-base);
  color: white;
}

</style>
