<template>
    <div class="mysymptoms-main-container">
        <h1>Page Not Found</h1>
        <p>
            Sorry - the page you are looking for does not exist.
        </p>
        <p>
            <router-link :to="{name: 'home'}">Home</router-link>
        </p>
    </div>
</template>

<script>
  export default {
    name: "NotFound"
  }
</script>

