<template>
  <div class="mysymptoms-main-container" @blur="ga_log_link()">

    <v-layout>
      <v-flex xs8>
        <h1 class="pt-3">News</h1>
      </v-flex>
    </v-layout>

    <v-card v-for="news in newsItems" v-bind:key="news.id">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">{{news.title}}</h3>
          <div class="subheading">{{news.publishedDate.format('D MMM YYYY')}}</div>
        </div>
      </v-card-title>
      <v-card-text v-html="news.content">
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import ClinicianAccountService from '../utils/clinician-account-service'
import Loader from "@/utils/loader-utils";
import Logger from "@/utils/logger-utils";
import NewsService from "@/utils/news-service"
import _ from "lodash"
import moment from "moment"

export default {
  name: 'News',
  data() {
    return {
      newsItems: []
    }
  },
  methods: {
    ga_log_link(link) {
      this.$gtag.event('external_link', {event_label: `${link}`})
    }
  },
  created() {
    // let the server know that this user has read the news articles .. note: it's a crude way of tracking
    // it the news has been read, we're only really tracking if they have navigated to this page
    ClinicianAccountService.markNewsAsRead(this.$store.state.username)

    this.$store.commit('setUnreadNews', {broadcast: true, value: false})

    Loader.start()
    NewsService.getNews().then(res => {
      this.newsItems = _.map(res.data, x => {
        return {
          id: x.id,
          title: x.title,
          publishedDate: moment(x.publishedDate),
          content: x.content
        }
      })
      Loader.stop()
    }).catch(err => {
      Loader.stop()
      Logger.error(err)
    })
  },
}
;
</script>
