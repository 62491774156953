import Api from './api'
import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import {checkProperties} from "./helpers";
import _ from 'lodash'

const clinicianAccountPath = '/private/clinician'

export default {

  // returns firstname, lastname etc
  getPatientInfo(clinicianUsername, patientUsername) {
    const path = `${clinicianAccountPath}/${clinicianUsername}/patient/${patientUsername}`
    return Api.get(path)
  },

  getOutcomesForCharts(clinicianUsername, patientUsername, fromDate, toDate) {
    const fromDateFmt = fromDate.toISOString()
    const toDateFmt = toDate.toISOString()

    const outcomesPath = `${clinicianAccountPath}/${clinicianUsername}/patient/${patientUsername}/outcome_for_date_range?fromDate=${fromDateFmt}&toDate=${toDateFmt}`
    // use the timezone of the offset date as the basis for display dates returned from the server
    const utcOffset = fromDate.utcOffset();
    const outcomesPromise = Api.get(outcomesPath).then(res => {
      const processedOutcomes = this.processOutcomesForCharts(res.data, utcOffset)
      return processedOutcomes
    })

    return outcomesPromise
  },

  /**
   * Returns the latest diary events and outcomes before a specific date.
   * The results are sorted in time descending order
   *
   * @param {String} clinicianUsername
   * @param {String} patientUsername
   * @param {number} maxResults
   * @param {Moment-timezone} eventTimeOffset - return diary events leading up to ths date
   * @returns {Promise} - resolving to a object:
   *                      {
   *                        isMore: Boolean
   *                        data: results
   *                      }
   *
   *          data: time descending array of elements
   */
  getDiary(clinicianUsername, patientUsername, maxResults, eventTimeOffset) {

    // format the eventTimeOffset to ISO8061 - format() defaults to YYYY-MM-DD'T'HH:mm:ss.SSSZ
    const x = eventTimeOffset.toISOString(true)
    const y = eventTimeOffset.format()
    const fmtOffset = encodeURIComponent(x)

    // use the timezone of the offset date as the basis for display dates returned from the server
    const utcOffset = eventTimeOffset.utcOffset();


    // we get the diary events (i.e. food etc logged) and the outcomes (i.e. symptoms etc) on different api end-points
    // note the call may return more than maxResults as it 'rounds up' to make sure only whole days worth of data are
    // returned
    const eventsPath = `${clinicianAccountPath}/${clinicianUsername}/patient/${patientUsername}/eventV2?eventTimeOffset=${fmtOffset}&numResults=${maxResults}`
    const eventsPromise = Api.get(eventsPath)

    const outcomesPath = `${clinicianAccountPath}/${clinicianUsername}/patient/${patientUsername}/outcomeV2?eventTimeOffset=${fmtOffset}&numResults=${maxResults}`
    const outcomesPromise = Api.get(outcomesPath)

    // wait for both to complete
    const allPromise = Promise.all([eventsPromise, outcomesPromise])

    return allPromise.then(values => {
      const events = values[0]
      const outcomes = values[1]

      // convert the events and the outcomes into a common format for display
      const processedOutcomes = this.processOutcomesForDiary(outcomes.data.diaryEntries, utcOffset)
      const processedEvents = this.processEventsForDiary(events.data.diaryEntries, utcOffset)

      // join together
      const allEntries = processedOutcomes.concat(processedEvents)
      // sort by date descending, note the dates are moments
      // const allEntriesSorted = allEntries.sort((a, b) => b.date - a.date)
      const allEntriesSorted = _.orderBy(allEntries, [function (d) {
        return d.date.valueOf()
      }], ['desc'])

      // we need to take into account the fact that date range spanned by events and outcomes may not match ...
      // for example suppose we request maxResults=4, eventTimeOffset=30 Jan
      // we may get:
      //
      //          Events    Outcomes
      // 30 Jan   Food 1    Symptom 1
      //          Food 2    Symptom 2
      // 29 Jan   Food 3
      //          Food 4
      // 28 Jan             Symptom 3
      //                    Symptom 4
      //          more=true more=true
      //
      // We need to be careful about which days we display since 'more=true' for Events ... i.e.
      // In this case we only want to display results for 30th and 29th Jan, but not 28th. If we displayed the results for the 28th
      // also, this may be misleading. There may be Event items still in the database for the 28th that the api call
      // didn't return. In which case, the user would be mislead into thinking that there
      // were only Outcomes on the 28th.
      // Thus when a stream has returned events, and more=true, we define the 'last reliable day' to be the day for which we
      // know we have all the items up to.  If more=false, then lastReliableDay=infinity
      //
      // Note they are sorted in time descending so by the 'last' I mean the chronological earliest date, the furthest
      // away from eventTimeOffset going back in time.
      // In the above example, it would be 29 Jan for Events and 28 Jan for Outcomes
      const lastReliableOutcomeDay = processedOutcomes.length > 0 && outcomes.data.hasMore ?
        processedOutcomes[processedOutcomes.length - 1].date : null
      const lastReliableEventDay = processedEvents.length > 0 && events.data.hasMore ?
        processedEvents[processedEvents.length - 1].date : null

      // from these two 'lastReliableDay' take the one that's closest to the eventTimeOffset. In the above example it would be
      // 29th Jan.
      // either the outcomes or the events list might be empty, if so, the corresponding 'lastXDay' will be null.
      // take the latest non null lastDay
      let lastReliableDay = null;
      if (lastReliableOutcomeDay && lastReliableEventDay) {
        lastReliableDay = lastReliableEventDay.isBefore(lastReliableOutcomeDay) ? lastReliableOutcomeDay.clone() :
          lastReliableEventDay.clone()
      } else if (lastReliableOutcomeDay) {
        lastReliableDay = lastReliableOutcomeDay.clone()
      } else if (lastReliableEventDay) {
        lastReliableDay = lastReliableEventDay.clone()
      } else {
        // lastDay = null
      }

      // if we needed to have set a lastReliableDay, then take only the events up to that day
      // e.g. keep only the entries that are between e.g. 30th Jan and the start of day on 29th
      const results = lastReliableDay ? allEntriesSorted.filter(entry => {
        const lastDayStartOfDay = lastReliableDay.clone().startOf('day')
        const ret = entry.date.isAfter(lastDayStartOfDay)
        return ret
      }) : allEntriesSorted

      // to calculate if there are more to show, this is true if either
      // there more entries on the server still for either the outcomes or events, or we truncated the results
      // we received from the events or outcomes call
      const isMore = outcomes.data.hasMore || events.data.hasMore || (results.length < allEntries.length)

      return {
        isMore: isMore,
        data: results
      }
    })
  },

  // this is actully a private function, called only by getDiary, but it is placed here so it can be tested
  //
  // @param {Object[]} events
  processEventsForDiary(diaryEvents, timezone) {

    const ret = diaryEvents.map(diaryEvent => {
      const eventStr = this.processEventForDiary(diaryEvent, timezone)
      return eventStr
    })
    return ret
  },

  /**
   *
   * @param {object} diaryEvent
   * @param {string} timezone
   * @returns {{date: *, entry_name: *, dateString: *, detail: *, entry_type: string, entry_description: string}}
   */
  processEventForDiary(diaryEvent, timezone) {
    checkProperties(diaryEvent, ['content', 'notes', 'type'])

    // Logger.debug(JSON.stringify(diaryEvent))

    // if the event has content convert each one to a string with its quantity and serving size
    const contentStr = diaryEvent.content.map(contentItem => {
      // checkProperties(contentItem, 'syncItem') && checkProperties(contentItem.syncItem, 'name')

      const amount = contentItem.servingSizeDisplay === '' ? '' : ` [ ${contentItem.servingSizeDisplay} ]`

      // const name = contentItem.syncItem.name.replace('/,/g',' -')
      const name = this.formatName(contentItem.name)

      // convert and ingredients to a string
      let ingStr = this.ingredientsArrToString(contentItem.ingredients)

      // add all together - note amount and ingredients may be empty strings, but name will not be.
      const ret = name + amount + ingStr
      return ret
    }).join(', ')

    // convert intensity to string if set, -1 means not set
    const intensityStr = (diaryEvent.intensity === -1) ? '' : ` (Intensity: ${diaryEvent.intensity})`

    // duration - set if endTime is not null
    const eventTime = moment.parseZone(diaryEvent.eventTime).utcOffset(timezone);
    const endTime = diaryEvent.endTime ? moment.parseZone(diaryEvent.endTime).utcOffset(timezone) : null

    let durationStr = this.durationToString(eventTime, endTime)

    // add a notes component if it's not empty / null
    const notesStr = diaryEvent.notes ? `Notes: ${diaryEvent.notes}` : ''

    // to start to build the description, join the non empty content, intensity
    const desc1 = contentStr + intensityStr

    // if both of these elements are not empty, join them together with a new line
    const desc2 = this.joinNonEmpty([desc1, durationStr, notesStr], '<br/>')

    const ret = {
      date: eventTime,
      dateString: eventTime.format("ddd D MMM YYYY"), // debug
      entry_type: 'event',
      entry_name: diaryEvent.type,
      entry_description: desc2,
      detail: diaryEvent
    }
    return ret
  },


  // this is actually a private function, called only by getDiary, but it is placed here so it can be tested
  //
  // @param {Object[]} outcomes
  // @param {string} outcomes.id // e.g. "C221EB64-DED1-4E14-B014-0BA4B14D3E62",
  // @param {string} outcomes.username: // e.g. "ivorybeans92",
  // @param {long} outcomes.lastModified: // e.g. 1549618826123
  // @param {boolean} outcomes.deleted: // e.g. false,
  // @param {string} outcomes.type: // e.g. "Symptom",
  // @param {long} outcomes.startTime: // e.g. 1549618826123
  // @param {long} outcomes.endTime // e.g. 1549618826123
  // @param {long} outcomes.value // e.g 5 ??
  // @param {Object[]} outcomes.symptoms
  // @param {string} outcomes.symptoms.symptom // e.g. "Nausea",
  // @param {number} outcomes.symptoms.intensity // e.g. 6,
  // @param {number} outcomes.symptoms.urgency" // e.g. 0
  // @param {string} outcomes.notes // e.g. "Blah"
  // @param {string} timezone
  processOutcomesForDiary(outcomes, utcOffset) {

    const ret = outcomes.map(outcome => {
      const ret = this.processOutcomeForDiary(outcome, utcOffset)
      return ret
    })

    return ret
  },

  processOutcomeForDiary(outcome, utcOffset) {

    checkProperties(outcome, ['startTime', 'endTime', 'type', 'symptoms', 'notes', 'value'])

    // if we have notes, prepend a carriage return before append to the rest of the description

    // we are moving towards taking the level from the symptoms array, but for bowel movements from android
    // we are not currently using the symptoms array. Instead we take the intensity from the value attribute.
    let nameAndValue = ''

    if (outcome.symptoms.length === 0) {
      let valueStr = ''
      let name = ''
      if (outcome.type === 'Bowel Movement') {
        name = 'Bowel Movement'
        valueStr = outcome.value ? ` (Bristol Scale: ${outcome.value})` : ''
        nameAndValue = `${name}${valueStr}`
      } else if (outcome.type === 'Energy') {
        name = 'Energy'
        valueStr = outcome.value ? ` (Value: ${outcome.value})` : ''
      }
      nameAndValue = `${name}${valueStr}`
    }

    const notesStr = outcome.notes ? `Notes: ${outcome.notes}` : ''

    // concatinate the elements of the symptom array,
    const symptomsStr = outcome.symptoms.map(symptom => {
      // if this is a energy or bowel movement, interpret the intensity as value or bristol scale resp.
      let intensityUnit = ''
      switch (symptom.symptom) {
        case 'Bowel Movement':
          intensityUnit = 'Bristol Scale';
          break
        case 'Energy':
          intensityUnit = 'Value';
          break
        case 'Mood':
          intensityUnit = 'Value';
          break
        default:
          intensityUnit = 'Intensity';
      }

      const intensityStr = `${intensityUnit}: ${symptom.intensity}`

      // a legacy data bug in iOS Symptoms and Moods uses urgency == 0 to mean not set when it should be null
      const urgencyStr = (symptom.urgency === null || symptom.urgency === 0) ? '' : `Urgency: ${symptom.urgency}`
      const subDesc = this.joinNonEmpty([intensityStr, urgencyStr], ', ')
      const subDesc2 = subDesc === '' ? '' : ` (${subDesc})`
      const desc = `${symptom.symptom}${subDesc2}`
      return desc
    }).join('<br/>')

    // we have a duration when endTime different from startTime

    const startTime = moment.parseZone(outcome.startTime).utcOffset(utcOffset)
    const endTime = outcome.endTime ? moment.parseZone(outcome.endTime).utcOffset(utcOffset) : null

    const durationStr = this.durationToString(startTime, endTime)

    const description = this.joinNonEmpty([nameAndValue, symptomsStr, durationStr, notesStr], '<br/>')

    const outcomeName = this.getOutcomeName(outcome)

    const ret = {
      date: startTime,
      dateString: startTime.format("ddd D MMM YYYY"), // debug
      entry_type: 'outcome',
      entry_name: outcomeName,
      entry_description: description,
      detail: outcome
    }
    return ret
  },

  /* outcomes is an arroy of Symptom, Sleep Quality, Bowel Movement or Energy items:
  [
  {
    "id": "b43cec16-cd44-48cf-8265-7ae180cd48d9",
    "username": "dph003",
    "lastModified": 1645469860803,
    "deleted": false,
    "type": "Symptom",
    "startTime": 1645469839000,
    "endTime": 1645469839000,
    "value": null,
    "symptoms": [
      {
        "symptom": "Nausea",
        "intensity": 2,
        "urgency": null
      },
      {
        "symptom": "Eczema",
        "intensity": 6,
        "urgency": null
      },
      {
        "symptom": "Stomach pain",
        "intensity": 7,
        "urgency": null
      }
    ],
    "notes": ""
  },
  {
    "id": "94993a85-604a-48fc-b1a1-22d5d99f334b",
    "username": "dph003",
    "lastModified": 1645469720900,
    "deleted": false,
    "type": "Bowel Movement",
    "startTime": 1645469708000,
    "endTime": 1645469708000,
    "value": 1,
    "symptoms": [],
    "notes": ""
  },
   ...
   ]
   returns a grouped by type, flattened normalised list of outcomes, e.g.:
       //   "Nausea": [
    //     {
    //       "type": "Nausea",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 2
    //     },
    //     {
    //       "type": "Nausea",
    //       "start_time": "2022-02-21T18:55:08.000Z",
    //       "end_time": "2022-02-21T18:55:08.000Z",
    //       "intensity": 1
    //     },
    //   ],
    //   "Eczema": [
    //     {
    //       "type": "Eczema",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 6
    //     }
    //   ],
    //   "Stomach pain": [
    //     {
    //       "type": "Stomach pain",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 7
    //     },
    //     {
    //       "type": "Stomach pain",
    //       "start_time": "2022-02-21T17:56:04.000Z",
    //       "end_time": "2022-02-21T17:56:04.000Z",
    //       "intensity": 4
    //     },
    //   ],
   */
  processOutcomesForCharts(outcomes, utcOffset) {

    // each outcome could be either a Symptom, Sleep Quality, Bowel Movement or Energy.
    // A Symptom is structure containing an arbitrary number of arbitrary sub-symptoms e.g. nausea ... we need to split these out into individual
    // items
    const outcomeListOfLists = outcomes.map(outcome => {
      // process each outcome into a list ... for Symptoms this may have multiple entires, others outcome types will just
      // have one entry, but making them all lists makes it easier to merge them in the next step
      const outcomeList = this.processOutcomeForCharts(outcome, utcOffset)
      return outcomeList
    })

    // convert the list of lists into one long list
    const flattenedOutcomeList = _.flatten(outcomeListOfLists)

    // flattenedOutcomeList looks like:
    // [
    //   {
    //     "type": "Nausea",
    //     "start_time": "2022-02-21T18:57:19.000Z",
    //     "end_time": "2022-02-21T18:57:19.000Z",
    //     "intensity": 2
    //   },
    //   {
    //     "type": "Eczema",
    //     "start_time": "2022-02-21T18:57:19.000Z",
    //     "end_time": "2022-02-21T18:57:19.000Z",
    //     "intensity": 6
    //   },
    //   {
    //     "type": "Stomach pain",
    //     "start_time": "2022-02-21T18:57:19.000Z",
    //     "end_time": "2022-02-21T18:57:19.000Z",
    //     "intensity": 7
    //   },
    //   {
    //     "type": "Nausea",
    //     "start_time": "2022-02-21T18:55:08.000Z",
    //     "end_time": "2022-02-21T18:55:08.000Z",
    //     "intensity": 1
    //   },
    //   {
    //     "type": "Stomach pain",
    //     "start_time": "2022-02-21T17:56:04.000Z",
    //     "end_time": "2022-02-21T17:56:04.000Z",
    //     "intensity": 4
    //   },
    //   ... etch
    // ]
    //

    // now group these by type:
    const outcomesGroupedByType = _.groupBy(flattenedOutcomeList, "type")

    // outcomesGroupedByType looks like:
    //
    // {
    //   "Nausea": [
    //     {
    //       "type": "Nausea",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 2
    //     },
    //     {
    //       "type": "Nausea",
    //       "start_time": "2022-02-21T18:55:08.000Z",
    //       "end_time": "2022-02-21T18:55:08.000Z",
    //       "intensity": 1
    //     },
    //   ],
    //   "Eczema": [
    //     {
    //       "type": "Eczema",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 6
    //     }
    //   ],
    //   "Stomach pain": [
    //     {
    //       "type": "Stomach pain",
    //       "start_time": "2022-02-21T18:57:19.000Z",
    //       "end_time": "2022-02-21T18:57:19.000Z",
    //       "intensity": 7
    //     },
    //     {
    //       "type": "Stomach pain",
    //       "start_time": "2022-02-21T17:56:04.000Z",
    //       "end_time": "2022-02-21T17:56:04.000Z",
    //       "intensity": 4
    //     },
    //   ],
    // }

    // sort the members of the outcomes object
    const sortedOutcomesGroupedByType = this.sortOutcomes(outcomesGroupedByType)

    return sortedOutcomesGroupedByType
  },

  // +--------------+
  // |outcome_type  |
  // +--------------+
  // |Symptom       |
  // |Sleep Quality |
  // |Bowel Movement|
  // |Energy        |
  // +--------------+
  processOutcomeForCharts(outcome, utcOffset) {

    checkProperties(outcome, ['startTime', 'endTime', 'type', 'symptoms', 'notes', 'value'])

    // if we have notes, prepend a carriage return before append to the rest of the description

    // we are moving towards taking the level from the symptoms array, but for bowel movements from android
    // we are not currently using the symptoms array. Instead we take the intensity from the value attribute.
    let nameAndValue = ''

    // is this Symptom vs Bowel Movement / Energy / Sleep
    // const outcomeType = outcome.symptoms.length === 0 ? outcome.type : null
    // if (outcome.symptoms.length === 0) {
    //   // let valueStr = ''
    //   // let name = ''
    //   if (outcome.type === 'Bowel Movement') {
    //     name = 'Bowel Movement'
    //     // valueStr = outcome.value ? ` (Bristol Scale: ${outcome.value})` : ''
    //     // nameAndValue = `${name}${valueStr}`
    //   } else if (outcome.type === 'Energy') {
    //     // name = 'Energy'
    //     // valueStr = outcome.value ? ` (Value: ${outcome.value})` : ''
    //   }
    //   // nameAndValue = `${name}${valueStr}`
    // }

    // const notesStr = outcome.notes ? `Notes: ${outcome.notes}` : ''

    // concatenate the elements of the symptom array,
    // const symptomsStr = outcome.symptoms.map(symptom => {
    //   // if this is a energy or bowel movement, interpret the intensity as value or bristol scale resp.
    //   let intensityUnit = ''
    //   switch (symptom.symptom) {
    //     case 'Bowel Movement':
    //       intensityUnit = 'Bristol Scale';
    //       break
    //     case 'Energy':
    //       intensityUnit = 'Value';
    //       break
    //     case 'Mood':
    //       intensityUnit = 'Value';
    //       break
    //     default:
    //       intensityUnit = 'Intensity';
    //   }
    //
    //   const intensityStr = `${intensityUnit}: ${symptom.intensity}`
    //
    //   // a legacy data bug in iOS Symptoms and Moods uses urgency == 0 to mean not set when it should be null
    //   const urgencyStr = (symptom.urgency === null || symptom.urgency === 0) ? '' : `Urgency: ${symptom.urgency}`
    //   const subDesc = this.joinNonEmpty([intensityStr, urgencyStr], ', ')
    //   const subDesc2 = subDesc === '' ? '' : ` (${subDesc})`
    //   const desc = `${symptom.symptom}${subDesc2}`
    //   return desc
    // }).join('<br/>')

    // we have a duration when endTime different from startTime

    const startTime = moment.parseZone(outcome.startTime).utcOffset(utcOffset)
    const endTime = outcome.endTime ? moment.parseZone(outcome.endTime).utcOffset(utcOffset) : null

    // const durationStr = this.durationToString(startTime, endTime)

    // const description = this.joinNonEmpty([nameAndValue, symptomsStr, durationStr, notesStr], '<br/>')

    // const outcomeName = this.getOutcomeName(outcome)

    let ret;
    if ( outcome.type === 'Symptom') {
      ret = outcome.symptoms.map(symptom => {
        // as symptom can be user created, make sure it is capitalised for display
        const type = symptom.symptom.charAt(0).toUpperCase() + symptom.symptom.slice(1)
        return {
          type: type,
          start_time: startTime,
          end_time: endTime,
          intensity: symptom.intensity
        }
      })
    } else {
      ret = [{
        type: outcome.type,
        start_time: startTime,
        end_time: endTime,
        intensity: outcome.value
      }]
    }

    // const ret = {
    //   date: startTime,
    //   dateString: startTime.format("ddd D MMM YYYY"), // debug
    //   entry_type: 'outcome',
    //   entry_name: outcomeName,
    //   entry_description: description,
    //   detail: outcome
    // }
    return ret
  },


// Join the non empty elements of a array
// e.g. joinNonEmpty(['', 'hello', '', 'bob'], ', ') returns 'hello, bob'
//
// @param {String[]} arr - array of string
// @param {String} joinStr - array of string
  joinNonEmpty(arr, joinStr) {
    let ret = ''

    if (arr.length !== 0) {
      ret = arr.reduce((acc, val) => {
        if (val === '') {
          return acc
        } else if (acc === '') {
          return val
        } else {
          return `${acc}${joinStr}${val}`
        }
      })
    }

    return ret
  }
  ,

  // calculates the duration between two times and formats as a string
  // there's an inconsistency between events and outcomes,
  //  - in events, if there is no duration the endTime is nll
  //  - in outcomes, if there is no duration endTime === startTime
  //                  note there is a legacy bug in android mood events in that startTime is set to > endTime to
  //                  mark no duration set
  // @param {object} startTime - moment
  // @param {object} endTime - moment
  durationToString(startTime, endTime) {
    let durationStr = ""
    if (endTime !== null && endTime.isAfter(startTime)) {
      // const endTimeMom = moment(endTime)
      // const startTimeMom = moment(startTime)
      // const eventDuration = moment.duration(endTimeMom.diff(startTimeMom))
      const eventDuration = moment.duration(endTime.diff(startTime))

      const hrsText = (eventDuration.hours() === 1) ? 'hr' : 'hrs'
      const minsText = (eventDuration.minutes() === 1) ? 'min' : 'mins'
      const daysText = (eventDuration.days() === 1) ? 'day' : 'days'
      const fmtStr = (eventDuration.minutes() === 0) ? `d [${daysText}] h [${hrsText}]` :
        `d [${daysText}] h [${hrsText}] m [${minsText}]`

      durationStr = `Duration: ${eventDuration.format(fmtStr)}`
    }
    return durationStr
  }
  ,


// this is actually a private function, called only by getDiary, but it is placed here so it can be tested
// Creates a displayable string such as 'Cacoa Mass [50 g]'
// If this ingredient has sub ingredients, the function is called recursively to produce a string such as
// 'Chocolate Powder [100 g] (Cocao Mass [50 g], Sugar [10 g])
// We set the depth of recursion to be 3 - the avoids infinite loops, in previous versions of the mobile app it was
// possible to set a parent ingredient as a child.
//
// @param {Object[]} ingredients - an array of ingredient objects, see ingredientToString for a description
// of the ingredient object
  ingredientsArrToString(ingredientsArr) {
    return this.ingredientsArrToStringRecur(ingredientsArr, 1)
  },

  ingredientsArrToStringRecur(ingredientsArr, depth) {

    let ingStr = ''
    if (ingredientsArr.length !== 0) {
      // convert the array of ingredient objects into an array of strings
      const ingredientsStrArr = ingredientsArr.map((ingredient) => {
        return this.ingredientToStringRecur(ingredient, depth)
      })
      // concatinate the array of strings into one comma separated string
      ingStr = ` (${ingredientsStrArr.join(', ')})`
    }
    return ingStr

  }
  ,

//
// const ingredient = {
//   "childItem": {
//     "id": "6b520c14-d785-4315-a609-b5f414d4198e",
//     "username": "ivorybeans92",
//     "lastModified": 1579260026704,
//     "deleted": false,
//     "name": "Cocoa mass",
//     "editable": true,
//     "barcode": null,
//     "recipe": false,
//     "brand": null,
//     "category": 2,
//     "baseItem": "be7af980-99ee-4523-af5c-b8ac1d8ba9c4",
//     "ingredients": [],
//     "ingredientNames": "null",
//     "ingredientCount": 0,
//     "countryCode": "GB",
//     "languageCode": "en"
//   },
//   "parentUsername": "ivorybeans92",
//   "quantity": 50,
//   "servingSize": "f4d0b849-b457-4832-8e1d-b44f721800d1"
// }
  ingredientToStringRecur(ingredient, depth) {

    const maxRecursionDepth = 5

    // construct  quantities string if it set, e.g. [ 50 g ]
    // const servingSizeText = this.servingSizeToText(ingredient.servingSize)
    // const qty = ingredient.quantity === 0 ? '' : ` [ ${ingredient.quantity} ${servingSizeText} ]`
    const amount = ingredient.servingSizeDisplay === '' ? '' : ` [ ${ingredient.servingSizeDisplay} ]`

    // append the quantities string to the name e.g. Chocolate Powder [ 50 g ]
    const name = this.formatName(ingredient.name)
    const ingrStr = name + amount;

    let subIngr = ''
    if (!(depth >= maxRecursionDepth)) {
      // if there are further ingredients call this function recursively on each ingredien
      subIngr = this.ingredientsArrToStringRecur(ingredient.ingredients, depth + 1)
    } else {
      subIngr = ' *** truncated ***'
    }

    // if the sub ingredient string is not empty, append it
    const ret = ingrStr + subIngr
    return ret
  },

// we should just be able to get the outcome name from the type field, but there is an error in the Mood event
// in that the type is set as 'symptom'. So, we need to check that this is not an erroneously labelled Mood event
//
// @param {Object} outcomeEntry
//
  getOutcomeName(diaryEntry) {

    const moodEventType = 'Mood'

    let name = diaryEntry.type
    if (diaryEntry.symptoms.length > 0) {
      // we just need to check the first one, if it's a mood symptom, then this is a misnamed mood symptom
      if (diaryEntry.symptoms[0].symptom === moodEventType) {
        name = moodEventType
      }
    }
    return name
  },

  // some food items have ',' in the name, e.g. 'Eggs, chicken', this confuses our display as we concatinate
  // items together with ',' E.g. bread and eggs, chicken would display as 'Bread, Eggs, Chicken' making it look.
  // So, we'll replace the ',' with a ' - ' to hopefully make things clearer
  //
  // @param {String} name
  formatName(name) {
    const newName = name.replace(/,/g, ' -')
    return newName
  },

  // {
  //   "Nausea": [
  //     {
  //       "type": "Nausea",
  //       "start_time": "2022-02-21T18:57:19.000Z",
  //       "end_time": "2022-02-21T18:57:19.000Z",
  //       "intensity": 2
  //     },
  //     {
  //       "type": "Nausea",
  //       "start_time": "2022-02-21T18:55:08.000Z",
  //       "end_time": "2022-02-21T18:55:08.000Z",
  //       "intensity": 1
  //     },
  //   ],
  //   "Eczema": [
  //     {
  //       "type": "Eczema",
  //       "start_time": "2022-02-21T18:57:19.000Z",
  //       "end_time": "2022-02-21T18:57:19.000Z",
  //       "intensity": 6
  //     }
  //   ],
  //   "Stomach pain": [
  //     {
  //       "type": "Stomach pain",
  //       "start_time": "2022-02-21T18:57:19.000Z",
  //       "end_time": "2022-02-21T18:57:19.000Z",
  //       "intensity": 7
  //     },
  //     {
  //       "type": "Stomach pain",
  //       "start_time": "2022-02-21T17:56:04.000Z",
  //       "end_time": "2022-02-21T17:56:04.000Z",
  //       "intensity": 4
  //     },
  //   ],
  // }

  sortOutcomes(outcomes) {
    const sortedKeys = Object.keys(outcomes).sort((a,b)=>a[0].localeCompare(b[0]))
    const sortedOutcomes = {}
    sortedKeys.forEach(key => {
      sortedOutcomes[key] = outcomes[key]
    })
    return sortedOutcomes
  }

}
/*
data: [
          {
            date: '2018-10-23T10:25:43.511Z',
            entry_type: "outcome",
            entry_name: "Bowel Movement",
            entry_description: "Level 3"
          },
          {
            date: '2018-10-23T12:25:43.511Z',
            entry_type: "outcome",
            entry_name: "Symptom",
            entry_description: "Stomach pain (Intensity: 2), Back pain (lower) (Intensity: 1)"
          },
          {
            date: '2018-10-23T14:25:43.511Z',
            entry_type: "event",
            entry_name: "Breakfast",
            entry_description: "Museli, Milk, Coffee"
          },
          {
            date: '2018-10-24T10:25:43.511Z',
            entry_type: "outcome",
            entry_name: "Bowel Movement",
            entry_description: "Level 3"
          },
          {
            date: '2018-10-24T12:25:43.511Z',
            entry_type: "outcome",
            entry_name: "Symptom",
            entry_description: "Stomach pain (Intensity: 2), Back pain (lower) (Intensity: 1)"
          },
          {
            date: '2018-10-24T18:25:43.511Z',
            entry_type: "event",
            entry_name: "Breakfast",
            entry_description: "Museli, Milk, Coffee"
          }
        ]
      })
 */
