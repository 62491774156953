import Api from './api'

const clinicianPath = '/private/clinician'
const newsPath = `${clinicianPath}/news`

export default {
  getNews() {
    return Api.get(newsPath)
  }
}
