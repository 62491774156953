<template>
  <div>
    <!-- pop-up to let user know they are about to close the payment window without completing -->
    <v-container fluid>
      <div class="text-xs-center">
        <v-dialog v-model="showCancelPremiumSubsWindow" persistent width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Subscription Cancelled
            </v-card-title>
            <v-card-text>
              <p>

                You are about to move from a premium plan to a free plan. Your subscription will remain active until
                your next billing date, after which you will be switched to the free plan.
              </p>
              <p>
                Once on the free plan, if the number of your clients exceeds the limit for the plan, you will only have
                access to the most recently accepted clients.
              </p>
              Do you wish to continue?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="on_primary" flat @click="handlePremiumCancelWarningNo">No</v-btn>
              <v-btn color="primary" flat @click="handlePremiumCancelWarningYes">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="showSurvey" persistent width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Feedback
            </v-card-title>
            <v-card-text>
              <p>
                We are sorry to see you go!
              </p>

              <p>
                We would appreciate your feedback. Please could you take a couple of minutes to tell us why you are
                cancelling your subscription?
              </p>
              <p>Please click <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScFQF3zfKHmK8GiE5SR8IubBMqzu885SgzOLdVE71Z78ac2cg/viewform?usp=sf_link"
                  target="_blank">here</a> to visit our survey page.
                <!--                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScFQF3zfKHmK8GiE5SR8IubBMqzu885SgzOLdVE71Z78ac2cg/viewform?embedded=true" width="500"  frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>-->
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="() => this.showSurvey = false">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>

<script>

import Logger from '../utils/logger-utils'
import Loader from "@/utils/loader-utils";
import PaddleUtils from "@/utils/paddle-utils"
import {EventBus} from "@/utils/event-bus";
import ClinicianAccountService from "@/utils/clinician-account-service";

export default {
  name: "CancelPremiumSubs",
  props: {
    showCancelPremiumSubsWindow: {type: Boolean, required: true},
    // username: {type: String, required: true},
    // email: {type: String, required: false},
    // country: {type: String, required: false},
    // postcode: {type: String, required: false},
    // paddleId: {type: Number, required: false,},
    // planName: {type: String, required: false},
    // referrerSuccessCallback: {type: Function, required: false},
    // referrerCloseCallback: {type: Function, required: false}
  },
  data:
      () => ({
        // showPremiumCancelWarning: false, // controls a pop-up warning user they are about to cancel a premium plan
        showSurvey: false,
      }),
  methods: {
    handlePremiumCancelWarningNo() {
      // this.showCancelPremiumSubsWindow = false
      this.$emit('update:showCancelPremiumSubsWindow', false)
    },

    handlePremiumCancelWarningYes() {
      // Loader.start()
      const that = this
      ClinicianAccountService.cancelPremiumSubscriptionPlan(this.$store.state.username).then(() => {
        // note we don't actually change the plan at this point, as the current plan remains active until
        // the end of the billing period
        // that.pendingNewPlan = ''
        // that.pendingNewPlanId = ''
        // that.showCancelPremiumSubsWindow = false
        this.$emit('update:showCancelPremiumSubsWindow', false)
        // Logger.debug("setting timer for updating subs plan")
        // wait for second before update the global subs plan data
        setTimeout(() => {
          // Logger.debug("emitting event for updating subs plan")
          EventBus.$emit('update-subscription-plan')
        }, 3000)
        // Loader.stop()
        this.showSurvey = true
      }).catch(err => {
        // that.showCancelPremiumSubsWindow = false
        this.$emit('update:showCancelPremiumSubsWindow', false)
        // that.pendingNewPlan = ''
        // that.pendingNewPlanId = ''
        // Loader.stop()
        Logger.error(err)
      })
    },
  },

  computed: {},

  created() {
  },

  watch: {
    showCancelPremiumSubsWindow(newVal) {
      if (newVal) {
        // this.checkoutPremium(this.username, this.email, this.country, this.postcode, this.paddleId, this.planName, this.successCallback)
      }
    }
  },
  mounted() {

  }
}

</script>
