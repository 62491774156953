<template>
  <div>
    <p>
      Scatter Chart
    </p>
    <!--    <canvas id="myChart2a" height="200" width="400" style="border:1px solid #000000;"></canvas>-->
    <!--    <canvas id="myChart4" height="200" width="400" style="border:1px solid #000000;"></canvas>-->

    <v-layout>
      <v-flex xs1></v-flex>
      <v-flex xs10>
        <!--        <canvas id="myChart2a" height="200" width="400" style="border:1px solid #000000;"></canvas>-->
        <!--        <canvas id="myChart2a" style="border:1px solid #000000;"></canvas>-->
        <!--        <canvas id="myChart2a" height="400" style="border:1px solid #000000;"></canvas>-->
        <div>
          <canvas id="myChart2a" style="border:1px solid #000000;" v-bind:height="canvasHeight"> </canvas>
        </div>
      </v-flex>
      <v-flex xs1></v-flex>
    </v-layout>
  </div>
</template>

<script>
import {
  ScatterChart,
  // LineChart
} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation"
import moment from "moment-timezone"

Chart.register(annotationPlugin, ...registerables);

export default {
  name: "PatientDiary",
  props: ['patientUsername', 'numResultsProp'],
  components: {
    // ScatterChart,
    // LineChart
  },

  data() {
    return {
      canvasHeight: 150,
      chartData1: {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      },
      chartOptions1: {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        }
      },
      chartData2: {
        datasets: [
          {
            "backgroundColor": "#f87979",
            "label": "headache",
            "data": [
              {
                "x": "2022-02-21T18:55:08.000Z",
                "y": 2
              },
              {
                "x": "2022-02-22T14:55:28.000Z",
                "y": 4
              }
            ]
          },
          // {
          //   type: 'line',
          //   label: 'Line Dataset',
          //   data: [5, 5, 5, 5],
          //   fill: false,
          //   borderColor: 'rgb(54, 162, 235)'
          // }
        ]
      },
      chartOptions2: {
        // "maintainAspectRatio": false,
        // "aspectRatio": 2,
        layout: {
          padding: {
            // top: 30,
            // bottom: 300,
            // left: 100
          }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": true
          },
        },
        "legend": {
          "display": false
        },
        "scales": {
          "x": {
            "type": "time",
            "time": {
              "unit": "day",
              "displayFormats": {
                "day": "DD MMM"
              }
            },
            "min": "2022-02-20T00:00:00.000Z",
            "max": "2022-02-25T11:04:44.598Z"
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
        }
      },
      chartData3: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [{
          label: 'My First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      chartOptions3: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              box1: {
                // Indicates the type of annotation
                type: 'box',
                xMin: 1,
                xMax: 2,
                yMin: 50,
                yMax: 70,
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
              }
            }
          }
        }
      },
      foodData: [
        // {
        //   name: 'Eggs',
        //   dates: ['2022-02-21T00:00:00.000Z', '2022-02-22T00:00:00.000']
        // },
        // {
        //   name: 'Cheese',
        //   dates: ['2022-02-21T13:00:00.000Z', '2022-02-22T13:00:00.000']
        // }
      ]
    }
  },
  mounted() {
    // const i = this.$refs.scatterRef
    // const image = this.$refs.scatterRef.chartInstance.toBase64Image()
    const numOfFoods = this.foodData.length;
    const laneHeight = 50 // px
    const laneAreaPaddingTop = 50 // px
    const laneAreaPaddingBottom = 50
    const maxFoodNameLength = 100 // used for adding padding to the left for food names
    const paddingBottom = laneAreaPaddingTop + (numOfFoods * laneHeight) + laneAreaPaddingBottom
    this.chartOptions2.layout.padding.bottom = paddingBottom
    this.chartOptions2.layout.padding.left = maxFoodNameLength

    this.canvasHeight = this.canvasHeight + paddingBottom
    console.log(`canvas height: ${this.canvasHeight}`)

    const ctx = document.getElementById('myChart2a')
    const plugin = {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart, args, options) => {
        // const ctx = chart.canvas.getContext('2d');
        // ctx.save();
        // ctx.globalCompositeOperation = 'destination-over';
        // ctx.fillStyle = 'lightGreen';
        // ctx.fillRect(0, 0, chart.width, chart.height);
        // ctx.restore();
        const {ctx, chartArea: {top, right, bottom, left, width, height}, scales: {x, y}, canvas} = chart;
        ctx.save();
        // ctx.strokeStyle = 'blue'
        // ctx.fillStyle = 'blue'
        // get the start and end times of the x-axis
        // canvas.height = 500
        const minX = moment.unix(x.min / 1000)
        const maxX = moment.unix(x.max / 1000)
        const timeSpanOfXAxis = maxX.diff(minX)
        // dist between bottom of chart and bottom of canvas area
        const chartHeight = chart.height
        const startOfLaneX = bottom
        // const laneZoneHeight = chartHeight - startOfLaneX

        // ctx.strokeRect(0,chart.height, 200, chart.height)
        // const foodCount = 0
        this.foodData.forEach((foodItem, count) => {
          ctx.fillStyle = this.getRandomColour()
          // this.foodData[0].dates.forEach(pointStr => {
          const nameTextWidth = ctx.measureText(foodItem.name)
          // if the name is too long for the padding space, truncate it
          const truncatedName = foodItem.name.substring(0,)
          // ctx.fillText(foodItem.name, left - nameTextWidth, bottom + laneAreaPaddingTop + (count * laneHeight) + laneHeight/2)
          ctx.fillText(foodItem.name, left - maxFoodNameLength, bottom + laneAreaPaddingTop + (count * laneHeight) + laneHeight / 2)

          foodItem.dates.forEach(pointStr => {
            const point = moment(pointStr)
            // proportional distance between point and start of X axis
            const timeDiffFromX0ToPoint = point.diff(minX)
            const timeDiffFromX0ToPointPerCent = timeDiffFromX0ToPoint / parseFloat(timeSpanOfXAxis)
            const XpixelOfPoint = left + (width * timeDiffFromX0ToPointPerCent)
            ctx.fillRect(XpixelOfPoint, bottom + laneAreaPaddingTop + (count * laneHeight), 10, 50)
          })

          // ctx.strokeStyle = 'red';
          // ctx.beginPath()
          // ctx.lineTo(chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
          // ctx.lineTo(chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y + 100)
          // ctx.lineTo(left,bottom+100)
          // ctx.stroke()

          // this.chartData2.datasets.forEach(dataset => {
          // for getting pixel points of data elements https://www.youtube.com/watch?v=Rt_aw9zxRSI
          chart.getDatasetMeta(0).data.forEach(data => {
            ctx.strokeStyle = 'red';
            ctx.setLineDash([10, 10])
            ctx.beginPath()
            ctx.lineTo(data.x, data.y)
            ctx.lineTo(data.x, bottom + laneAreaPaddingTop + (this.foodData.length * laneHeight))
            ctx.stroke()
          })
          // })

        })


        ctx.restore();
      }
    };
    const myChart2a = new Chart(ctx, {
      type: 'scatter',
      data: this.chartData2,
      options: this.chartOptions2,
      plugins: [plugin]
    })
    myChart2a;
    // const myChart4 = new Chart(ctx, {
    //   type: 'scatter',
    //   data: this.chartData4,
    //   options: this.chartOptions4,
    //   plugins: [plugin]
    // })
    // myChart2;
  },
  methods: {
    getRandomColour() {
      var r = (Math.round(Math.random() * 127) + 127).toString(16);
      var g = (Math.round(Math.random() * 127) + 127).toString(16);
      var b = (Math.round(Math.random() * 127) + 127).toString(16);
      return '#' + r + g + b;
    }
  }
}
</script>
