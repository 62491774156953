<template>
  <v-layout>
    <v-flex xs3>
    </v-flex>
    <v-flex class="mysymptoms-main-container" xs6>
      <h1>Email Verification</h1>
      <p>
        We have sent you an email to verify your email address. Please check your email inbox for further instructions.
      </p>

      <p>
        If you do not receive a message from us within 5 minutes, please check your Spam folder or click
        <router-link :to="{name: 'resend_verification_email'}">here</router-link>
        to resend the email.
      </p>
      <p>
        Please keep a record of your username, password and the email address used for this account.
      </p>

    </v-flex>
    <v-flex xs3></v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "RegistrationSuccess",
  props: ['email', 'token'],
  computed: {}
}
</script>

<style scoped>

</style>
