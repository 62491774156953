import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import "./mysymptoms.css"
import "pure-css-loader/dist/css-loader.css"
import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store, initialiseStore} from "./store/store"
import colors from 'vuetify/es5/util/colors'
import Vuetify from 'vuetify'
import VueGtag from "vue-gtag"
import Toasted from 'vue-toasted';
import 'material-design-icons/iconfont/material-icons.css'
import Logger from "./utils/logger-utils";
import VError from "verror";
import { Chart, registerables } from "chart.js";
// import zoomPlugin from "chartjs-plugin-zoom";
import VComp from "@vue/composition-api"; // needed for Chartjs 3

// noinspection JSUnresolvedVariable
Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  iconfont: 'md',
  // useful tool: https://www.hexcolortool.com
  // https://material.io/design/color/the-color-system.html#tools-for-picking-colors
  theme: {
    primary: {
      base: '#5a81d4', // 89,128,212
      lighten1: '#8da4e0',
      lighten2: '#bcc7ec',
      lighten3: '#e4e9f7',
      darken1: '#2465cc',
      darken2: '#004cc0',
      darken3: '#0044b6',
    },
    secondary: {
      base: '#504b53',
      lighten1: '#837E86',
      lighten2: '#B6B1B9',
      lighten3: '#E9E4EC',
      darken1: '#37323A',
      darken2: '#1D1820',
      darken3: '#040007'
    },
    tertiary: {
      base: '#E63B05', // the mysymptoms orange, used sparingly for highlights
      lighten1: '#ff4912',
      lighten2: '#ff6c3c',
      lighten3: '#ffaa8e',
      darken1: '#ce2900'
    },
    on_primary:'#504b53',
    on_secondary:'#000000',
    error: colors.red.darken4,
    error_background: '#f0a1a1',
    on_error_background: '#000000'
    // error: '#f0a1a1'
  }
})

const gaEnabled = process.env.VUE_APP_GA_ENABLED === "true" || false
// adding the router into the VueGtag config automatically tracks pages, but we also manually track
// some events. Search for this.$gtag in the code base.
Vue.use(VueGtag, {
  config: {id: 'UA-52504611-7'},
  enabled: gaEnabled,
}, router)
Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  // log to console as a 'belt and braces approach' in case we are in a catastrophic situation and the Logger isn't
  // working
  console.error(`errorHandler: `, err)
  Logger.error(new VError(err, 'Uncaught error'))
}
//
// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` is the component hierarchy trace
//   console.warn(`warnHandler: ${msg}`)
//   debugger
// }

Vue.use(Toasted, {
    iconPack: 'material'
  }
)

// Chartjs 3 uses Api Composition
Chart.register(...registerables);
Vue.use(VComp);

// work in progress
//    add dependency to "@dansmaculotte/vue-zendesk": "^0.4.4",
// import Zendesk from '@dansmaculotte/vue-zendesk'
// Vue.use(Zendesk, {
//   key: '067e9ff3-4e6a-4069-8c46-75054fb746d6',
//   disabled: false,
//   hideOnLoad: false,
//   settings: {
//     webWidget: {
//       color: {
//         theme: '#78a300'
//       }
//     }
//   }
// })

initialiseStore().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    renderError(h, err) {
      return h('pre', {style: {color: 'red'}}, err.stack)
    },
  }).$mount('#app')
}).catch(err => {
  // if we haven't been able to get this far, all we do is log to console
  console.log(`failed to initialise store: ${err.stack}`)
})
