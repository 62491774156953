<template>
    <v-layout pt3>
        <v-flex xs2 md3/>
        <v-flex xs8 md6 class="mysymptoms-main-container">
            <h1>Beta Trial Information</h1>
            <p>
                Thank you for signing up for the mySymptoms Clinic beta trial.
<!--                This page contains some important information on how the trial is going to run.-->
            </p>
            <p>
                We are looking forward to hearing
                what you like and dislike, along with any suggestions you might have for improvements that would add
                value for you and your patients.
            </p>
            <p>
                This initial version of the app provides secure online access to your patients' mySymptoms diaries in
                real
                time, along with text search for trigger foods/items. But this is only the beginning of our development
                roadmap which includes all the features needed to support a full 'Telehealth' experience.
            </p>

            <p>To send us your thoughts, either use the Feedback tool on each page, email us at <a
                    href="mailto:clinic@mysymptoms.net">clinic@mysymptoms.net</a> or use the survey form that we
                will send you at the end of the trial.</p>
            <p>
                The particular areas we would like feedback on are:
            </p>

            <ul>
                <li>
                    <b>Utility</b>: Does the app provide benefit to you and your patients?
                </li>
                <li>
                    <b>Usability</b>: Is the app intuitive? Does the layout work for your needs?
                </li>
                <li>
                    <b>Feature requests</b>:
                    <ul>
                        <li>
                            Are there any features missing that should be in the first version?
                        </li>
                        <li>
                            What are your feature priorities for future versions?
                        </li>
                    </ul>
                </li>
                <li>
                    Any problems you encounter.
                </li>
            </ul>

            <p>
                The trial runs from now until the end of July 2020.
            </p>

            <p>
                To help you get started, we’ve created a short <a
                    href="https://youtu.be/_DSaUBt-blM" target="_blank">video</a> to introduce you to mySymptoms Clinic
                .
                To accompany this, we also have a written a
                <a href="https://mysymptoms.zendesk.com/hc/en-us/articles/360013113360-Beta-Trial-Getting-Started-Guide"
                   target="_blank">Getting Started
                    Guide</a>.
            </p>


            <p>
                Once you're familiar with the app, please feel free to start using it with your own patients.
            </p>
            <p>
                During the beta trial, mySymptoms Clinic will be free to use with your patients. After the trial ends,
                you can
                continue to use the app for free until we formally launch it as a paid service. Additionally, in
                exchange for
                your participation in the trial, and on completing our survey at the end of the trial, we would like to
                offer you 6 months free subscription once the app becomes a paid service.
            </p>
            <p>
                You can come back to this page at any time from the menu Help -> Beta Trial Info.
            </p>

            <p>
                Thank you once again for participating. We look forward to receiving your feedback and suggestions.
                In the meantime, if you have any questions please email us at <a
                    href="mailto:clinic@mysymptoms.net" target="_blank">clinic@mysymptoms.net</a>.
            </p>

            <div class="text-xs-center">
                <v-btn name='ok'
                       v-if="fromRegPage"
                       @click="backToRegistration"
                       color="primary"
                >
                    Continue
                </v-btn>
            </div>
        </v-flex>
        <v-flex xs2 md3/>
    </v-layout>
</template>

<script>
  export default {
    name: "ClinicianRegistrationBetaInfo",
    props: {
      email: {
        type: String,
        required: false
      },
      token: {
        type: String,
        required: false
      },
    },
    computed: {
      fromRegPage() {
        return this.$route.query.fromRegPage === "true" || false
      }
    },
    methods: {
      backToRegistration() {
        this.$router.push({
          path: `/clinician/create/${this.email}/${this.token}`,
          query: {
            seenBetaInfo: "true"
          }
        })
      }
    },
  }
</script>

<style scoped>
    ul {
        list-style: none
    }
</style>
