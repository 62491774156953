<template>
  <div>
    <div class="mysymptoms-main-container">
      <div>
        <v-layout>
          <v-flex xs3 pt-3>
            <router-link
                :to="{name: 'feedback'}"
            >
              Do you like the new diary and charts page?
            </router-link>
          </v-flex>
          <v-flex xs7></v-flex>
          <v-flex xs2>
            <div class="pt-3">
              <v-autocomplete
                  v-model="displayTimezoneOffset"
                  :items="allTimezoneOffsets"
                  label="Display Timezone"
                  @change="onTimezoneUpdate"
              >
                <v-tooltip slot="append-outer" bottom>
                  <span>Select the patient's timezone. Note: This currently defaults to the timezone of the clinician's browser. A future version of the software will automatically set it to the patient's timezone.</span>
                  <v-icon slot="activator">help</v-icon>
                </v-tooltip>
              </v-autocomplete>
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs0 sm2>
          </v-flex>

          <v-flex text-xs-center xs10 sm8>
            <h4>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon color="black" @click="moveViewWindow(-viewPeriodInDays)" v-on="on">
                    <v-icon>chevron_left</v-icon>
                  </v-btn>
                </template>
                <span>Previous time period</span>
              </v-tooltip>

              {{ fromDateFormatted }} - {{ toDateFormatted }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon color="black" @click="moveViewWindow(viewPeriodInDays)" v-on="on">
                    <v-icon>chevron_right</v-icon>
                  </v-btn>
                </template>
                <span>Next time period</span>
              </v-tooltip>

            </h4>
          </v-flex>
          <v-flex xs4 sm2 style="margin-top: 0">
            <v-radio-group v-model="viewPeriod" row @click="changeViewPeriod">
              <v-radio label="week" value="week"></v-radio>
              <v-radio label="month" value="month"></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs0 sm2>
            <v-layout row wrap>
              <v-flex xs12>
                <v-combobox
                    v-model="selectedOutcomeComboEntries"
                    :items="outcomeComboEntries"
                    label="Select outcomes"
                    multiple
                    chips
                    deletable-chips
                    @change="onSelectedOutcomesUpdated"
                ></v-combobox>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-combobox
                    v-model="selectedIngredientComboEntries"
                    :items="ingredientsComboEntries"
                    label="Select items"
                    multiple
                    chips
                    deletable-chips
                    @change="onSelectedIngredientsUpdated"
                ></v-combobox>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm10>
            <div style="padding-left: 25px; height:300px">
              <canvas id="theChartV2"> Your browser does not support the canvas element.</canvas>
            </div>
          </v-flex>
          <v-flex xs0 sm0></v-flex>
        </v-layout>
        <v-layout align-end>
          <v-flex xs2>
<!--            <v-select-->
<!--                class="pt-5"-->
<!--                v-model="sortOrder"-->
<!--                :items="sortOptions"-->
<!--                label="Sort"-->
<!--                @change="changeSortOrder"-->
<!--            ></v-select>-->
<!--            <span>Sort:</span>-->
            <v-btn flat icon @click="changeSortOrderIcon">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon v-on="on" v-if="sortOrder=='asc'">arrow_upward</v-icon>
                  <v-icon  v-on="on" v-else>arrow_downward</v-icon>
                </template>
                <span>Change Sort Order</span>
              </v-tooltip>
            </v-btn>
            <span>Sort: {{sortOrder === 'asc' ? 'Ascending' : 'Descending'}}</span>
          </v-flex>
          <v-flex xs7></v-flex>
          <v-flex xs3>
            <v-text-field
                class="pt-5"
                v-model="searchText"
                append-icon="search"
                label="Search, e.g. cheese,milk,nausea"
                hint="Separate multiple items with a comma"
                persistent-hint
                single-line
                @blur="addItemToSearchHistory"
            >
              <v-menu
                  slot="prepend"
                  offset-y
                  v-model="historyMenuOpen"
                  :close-on-content-click="false"
              >
                <v-icon slot="activator">{{ historyMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}</v-icon>
                <v-list>
                  <v-list-tile
                      v-for="(item, i) in getSearchHistory"
                      :key="i"
                  >
                    <v-list-tile-title style="cursor: pointer" @click="onSearchHistoryItemSelected(i)">
                      {{
                        item
                      }}
                    </v-list-tile-title>
                    <v-icon @click="deleteSearchItem(i)"
                            class="align-right pl-3">delete
                    </v-icon>
                  </v-list-tile>
                  <v-list-tile v-if="searchHistoryLength === 0">
                    <v-list-tile-title>
                      No search history
                    </v-list-tile-title>

                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-tooltip slot="append-outer" bottom>
                <span>Tip: use a comma between words to search for multiple items.</span>
                <v-icon slot="activator">help</v-icon>
              </v-tooltip>
            </v-text-field>
          </v-flex>
        </v-layout>
        <div style="height: 1500px; overflow:scroll">
          <table v-for="(dayData, day) in diaryDataForDateRange" v-bind:key="day" class="mysymptoms-patient-diary">
            <thead>
            <tr>
              <th colspan="3">{{ formatDayForDisplay(day, displayTimezoneOffset) }}</th>
            </tr>
            </thead>
            <tbody class="diarybody">
            <tr v-for="diaryEvent in dayData" v-bind:key="diaryEvent.id" class="<%=rowClass%>">
              <td class="mysymptoms-diary-time">{{ formatTime(diaryEvent.date) }}</td>
              <td class="mysymptoms-diary-event">
                <!--                    get the right icon for this entry , note passing the symptom detail is a bit of a hack
                                        It's only needed for moods, since we have different mood icons for different mood levels-->
                <img alt="event type" :src="getIconName(diaryEvent.entry_name, diaryEvent.detail)" width="20px"
                     height="20px"/>
                <!-- highlight the found search terms in the entry type, note: only use the user's manually typed text  -->
                <span style="padding-left: 5px"
                      v-html="$options.filters.highlightSearchResults(diaryEvent.entry_name, diaryEvent.entry_name, searchText)">
                  </span>
              </td>
              <!-- highlight the found search terms in the entry details, note: use the user's manually typed text + the
               search entries derived from the selected search items-->
              <td class="mysymptoms-diary-content"
                  v-html="$options.filters.highlightSearchResults(
                        diaryEvent.entry_name,
                    diaryEvent.entry_description,
                    userAndSelectedSearchTerms)">
              </td>
            </tr>
            </tbody>
          </table>
          <p v-if="isDiaryEmpty">No diary entries in date range</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientDiaryService from '../utils/patient-diary-serviceV2'
import UiAnalyticsService from "../utils/ui-analytics-service";
import Logger from '../utils/logger-utils'
import _ from 'lodash'
import Loader from '../utils/loader-utils'
import VError from 'verror'
import RealNameUtils from '../utils/real-name-helpers'
import TimezoneUtils from '../utils/timezone-utils'
import {defineComponent} from "@vue/composition-api"
import 'chartjs-adapter-luxon'
import {ScatterChart} from 'vue-chart-3'
import {Chart, registerables} from "chart.js"
import {DateTime, Interval} from "luxon"

const numResultsDefault = 25
const localStorageHistoryKey = 'searchHistory'

const numWeekDays = 7
const numMonthDays = 31
const maxNumberSelectedIngredients = 5
const maxNumberSelectedOutcomes = 5
const maxFoodNameLength = 30


/**
 // * converts  DateTime date of a diary Event object to sortable string containing just the day e.g  2018-09-01
 * gets the start of date for a given date in ISO formatted string
 * @param {Object} diaryEvent - containing a date field as a DateTime date
 * @returns {String} ISO formatted date string
 */
function getStartOfDay(diaryEvent) {
  const startOfDay = diaryEvent.date.startOf('day')
  const s = startOfDay.toISO()
  return s;

}

Chart.register(...registerables);

export default defineComponent({
  name: "PatientDiaryV2",
  // props: ['patientUsername', 'clinicianUsername'],
  props: ['patientUsername'],
  components: {
    ScatterChart
  },

  data() {
    return {
      activeTab: 0,
      /**
       *  diaryData contains the data for the diary, grouped by day, has the format ...
       * {
       *  "2020-07-12": [
       *      {
       *          "date": "2020-07-12T04:00:18.000Z",
       *          "dateString": "Sun 12 Jul 2020",
       *          "entry_type": "event",
       *          "entry_name": "Drink",
       *          "entry_description": "Notes: 11th 5:00",
       *          "detail": {
       *              "id": "9e3b5715-7a56-4405-aef5-d8396f380dcb",
       *              "username": "dph201",
       *              "lastModified": "2020-07-13T22:20:19.833+0000",
       *              "eventTime": "2020-07-12T04:00:18.000+0000",
       *              "endTime": null,
       *              "type": "Drink",
       *              "content": [],
       *              "notes": "11th 5:00",
       *              "intensity": -1
       *          }
       *      },
       *  ]
       * "2020-07-11": [
       * ... etc
       *      ]
       * }
       */
      diaryData: {},
      displayTimezoneOffset: [], // "in offset format, e.g. ["-5:00","EST"], note it's an array rather than object to
      // we split the search text into two bits: from what the user manually types and what the user selects
      searchText: '', // from what the user manually types in
      searchTextFromSelectedItems: '', // from the selected food items / outcomes
      searchHistory: [], // array of previous search strings
      historyMenuOpen: false,
      // matches: [],
      patientRealName: '',
      // allOutcomeDatasets: [],
      // a basic set of options from this copy for options for each outcome set
      // showToDatePickerMenu: false,
      // showFromDatePickerMenu: false,

      // the date between which to show the Chart and Diary data, use the 'half-open' convention,
      // >= fromDate, <toDate
      fromDate: '',
      toDate: '',
      // sortOrder: 'asc', // 'asc' or 'desc'
      viewPeriod: 'week', // 'week' or 'month'
      // colours for the ingredient bars and symptom chart point
      // https://www.tableau.com/about/blog/2016/7/colors-upgrade-tableau-10-56782
      colours: [
        'rgb(78 121 167)',
        'rgb(242 142 42)',
        'rgb(224 87 89)',
        'rgb(118 183 178)',
        'rgb(89 161 78)',
        'rgb(237 201 73)',
        'rgb(176 122 162)',
        'rgb(251 157 167)',
        'rgb(156 117 95)',
        'rgb(186 176 172)'
      ],
      // for symptom chart symbols
      pointStyles: ['cicle', 'rect', 'triangle', 'rectRot', 'rectRounded'],
      /*
        ingredients:
      [
        {
          "name": "Dph test 3",
          "item": [
            {
              "eventTime": "2022-07-04T15:23:13.000+0000",
              "ingredient": {
                "id": "4ffff46c-31dd-462a-a611-210c5b7e0e74",
                "username": "dph002",
                "name": "Dph test 3",
                "servingSizeDisplay": "",
                "ingredients": [
                  {
                    "id": "3434c50b-67a3-42ef-a4b1-4aae51475467",
                    "username": "dph002",
                    "name": "Dph test 2",
                    "servingSizeDisplay": "",
                    "ingredients": [
                      {
                        "id": "79d1daa5-44bb-4e84-ab85-8410d691d749",
                        "username": "dph002",
                        "name": "Dph test 1",
                        "servingSizeDisplay": "",
                        "ingredients": []
                      }
                    ]
                  }
                ]
              }
            },
          ]
        }
      ]
      */
      //  e.g. [ sandwich: [cheese, [bread: [flour, salt]]] -> [ sandwich, cheese, bread, flour, salt]
      flattenedIngredients: [], // the raw data
      // [Sleep Quality, Symptom: [nausea, headache, cramps] -> [Sleep Quality, Nausea, headache, cramps]
      flattenedOutcomes: [], // the raw data
      selectedIngredientComboEntries: [],
      selectedIngredientComboEntriesPrevious: [],
      selectedOutcomeComboEntries: [],
      selectedOutcomeComboEntriesPrevious: [],

      chartDataV2: {
        // datasets[] of the format
        // {
        //   "backgroundColor": "#f87979",
        //   "label": "headache",
        //   "data": [
        //     {
        //       "x": "2022-07-03T18:55:08.000Z",
        //       "y": 2
        //     },
        //     {
        //       "x": "2022-07-04T14:55:28.000Z",
        //       "y": 4
        //     }
        //   ]
        // },
        datasets: []
      },
      chartOptionsV2: {
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        layout: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        plugins: {},
        legend: {
          display: false
        },
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "d MMM"
              },
            },
            adapters: {
              date: {
                zone: 'UTC',
              },
            },
            min: "",
            max: ""
          },
          y: {
            title: {
              display: true,
              text: "Intensity"
            },
            min: 0,
            max: 10,
            ticks: {
              stepSize: 1
            }
          },
        }
      },
      originalParentDivHeight: 0,
      // record the colours and point styles we use for the events and outcomes as we select them
      eventColourMappings: {},
      outcomeColourMappings: {},
      outcomePointStyleMappings: {},
      sortOptions: [
        {text: 'Ascending', value: 'asc'},
        {text: 'Descending', value: 'desc'},

      ],
      sortOrder: 'asc' // default
    }
  },

  computed: {

    foodBarWidth() {
      return (this.viewPeriod === 'month') ? 3 : 5
    },

    userAndSelectedSearchTerms() {
      let ret;
      if (this.searchText !== "" && this.searchTextFromSelectedItems !== "") {
        ret = this.searchText + ',' + this.searchTextFromSelectedItems
      } else if (this.searchText === "") {
        ret = this.searchTextFromSelectedItems
      } else {
        ret = this.searchText
      }

      return ret
    },

    // select the days from the diaryData that lies >= fromDate and < toDate
    diaryDataForDateRange() {

      const ret = {}

      for (const day in this.diaryData) {
        // if (this.fromDate <= DateTime.fromISO(day) < this.toDate) {
        if (Interval.fromDateTimes(this.fromDate, this.toDate).contains(DateTime.fromISO(day))) {
          ret[day] = this.diaryData[day]
        }
      }

      return ret;
    },

    isDiaryEmpty() {
      return Object.entries(this.diaryDataForDateRange).length === 0
    },

    viewPeriodInDays() {
      switch (this.viewPeriod) {
        case 'week':
          return numWeekDays
        case 'month':
          return numMonthDays
        default:
          Logger.error(new VError(`unknown viewPeriod: ${this.viewPeriod}`), {})
          return undefined
      }
    },

    /**
     * @returns
     * {
     *   "name": "White rice",
     *   "comboText": "White rice (8)",
     *   "itemsInViewWindow": 8,
     *   "eventTimes": [
     *     "2019-03-17T23:43:31.000+01:00",
     *     "2019-03-16T16:35:16.000+01:00",
     *     .....
     *   ]
     * }
     * {
     *   "name": "Bacon",
     *   "comboText": "Bacon (8)",
     *   "itemsInViewWindow": 8,
     *   "eventTimes": [
     *     "2019-03-17T13:20:45.000+01:00",
     *     "2019-03-16T15:07:12.000+01:00",
     *     "2019-03-15T12:42:06.000+01:00",
     *     .....
     *   ]
     * }
     */
    groupedIngredients() {

      const ingredientsGroupedByName = _.groupBy(this.flattenedIngredients, ingredient => {
        return ingredient.ingredient.name
      })
      const ingredientsGroupedByNameAsObj = _.map(ingredientsGroupedByName, (value, key) => ({name: key, item: value}))

      const ingredientsWithComboText = _.map(ingredientsGroupedByNameAsObj, ingredientGroup => {
        // loop over the item dates and count how many are in our view window ... remember that the flattenedIngredients
        // may contain items from preview view windows if we've moved around
        const filteredItems = _.filter(ingredientGroup.item, item => {
          // return item.eventTime.isBetween(this.fromDate, this.toDate)
          return Interval.fromDateTimes(this.fromDate, this.toDate).contains(item.eventTime)
        })

        const trunctedName = this.truncateName(ingredientGroup.name)
        // ingredient.comboText = ingredient.name + ` (${filteredItems.length})`
        ingredientGroup.comboText = trunctedName + ` (${filteredItems.length})`
        ingredientGroup.itemsInViewWindow = filteredItems.length

        // delete the ingredient component from the item, all we need is the name
        ingredientGroup.eventTimes = []
        _.forEach(ingredientGroup.item, i => ingredientGroup.eventTimes.push(i.eventTime))
        delete ingredientGroup.item

        return ingredientGroup
      })
      // sort so that the frequent are at the top of the list
      const ingredientsWithComboTextSorted = _.sortBy(ingredientsWithComboText, i => -i.itemsInViewWindow)

      return ingredientsWithComboTextSorted
    },


    /*
      transforms
      [
        {
          "outcomeName": "Sleep Quality",
          "startTime": "2022-07-05T18:29:37.000+01:00",
          "value": 5
        },
        {
          "outcomeName": "Bowel Movement",
          "startTime": "2022-07-05T10:29:09.000+01:00",
          "value": 7
        },
        ....
      ]
      into
      [
        {
          "name": "Sleep Quality",
          "comboText": "Sleep Quality (2)",
          "itemsInViewWindow": 2
          "item": [
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-07-05T18:29:37.000+01:00",
              "value": 5
            },
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-06-30T08:37:10.000+01:00",
              "value": 5
            }
          ],
        },
        ...
      ]
     */
    groupedOutcomes() {
      const outcomesGroupedByName = _.groupBy(this.flattenedOutcomes, outcome => {
        return outcome.outcomeName
      })

      /*
        convert back to object with 'name' and 'item' fields in prep for use in the combo drop-down
       [
        {
          "name": "Sleep Quality",
          "item": [
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-07-05T18:29:37.000+01:00",
              "value": 5
            },
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-06-30T08:37:10.000+01:00",
              "value": 5
            }
          ]
          ...
        },
       */
      const outcomesGroupedByNameAsObj = _.map(outcomesGroupedByName, (value, key) => ({name: key, item: value}))

      /*
      add in the fields:
        'comboText' - the text shown the combo dropdown
        'itemsInViewWindow' - number of this type of item shown in this time window, used to sort the items in the drop down
      [
        {
          "name": "Sleep Quality",
          "comboText": "Sleep Quality (2)",
          "itemsInViewWindow": 2
          "item": [
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-07-05T18:29:37.000+01:00",
              "value": 5
            },
            {
              "outcomeName": "Sleep Quality",
              "startTime": "2022-06-30T08:37:10.000+01:00",
              "value": 5
            }
          ],
        },
        ...
      ]
       */
      const outcomesWithComboText = _.map(outcomesGroupedByNameAsObj, outcomeGroup => {
        // loop over the item dates and count how many are in our view window ... remember that the outcome
        // may contain items from preview view windows if we've moved around
        const filteredItems = _.filter(outcomeGroup.item, item => {
          // return item.startTime.isBetween(this.fromDate, this.toDate)
          return Interval.fromDateTimes(this.fromDate, this.toDate).contains(item.startTime)
        })

        const truncatedName = this.truncateName(outcomeGroup.name)

        outcomeGroup.comboText = truncatedName + ` (${filteredItems.length})`
        outcomeGroup.itemsInViewWindow = filteredItems.length
        return outcomeGroup
      })

      // sort so that the most frequent are at the top of the list
      const outcomesWithComboTextSorted = _.sortBy(outcomesWithComboText, i => -i.itemsInViewWindow)

      return outcomesWithComboTextSorted
    },

    selectedIngredients() {
      // for each item in the combo list find the corresponding ingredient
      const selectedIngredients = this.selectedIngredientComboEntries.map(selectedIngredient => {

        const foundIng = _.find(this.groupedIngredients, ing => ing.name === selectedIngredient.value)

        // filter out the date points outside of the view window
        const foundIngFilteredDates = _.filter(foundIng.eventTimes, i => {
          return Interval.fromDateTimes(this.fromDate, this.toDate).contains(i)
        })

        foundIng.eventTimes = foundIngFilteredDates
        return foundIng
      })

      // for each of these ingredients
      return selectedIngredients;
    },

    selectedOutcomes() {
      // from the outcome names selected in the combo list, find the corresponding outcomes in the outcomes data array
      const selectedOutcomes = this.selectedOutcomeComboEntries.map(selectedOutcomeEntry => {
        const foundOutcome = _.find(this.groupedOutcomes, o => o.name === selectedOutcomeEntry.value)

        // filter out the date points outside of the view window
        const foundOutcomeFilteredDates = _.filter(foundOutcome.item, o => {
          return Interval.fromDateTimes(this.fromDate, this.toDate).contains(o.startTime)
        })

        foundOutcome.item = foundOutcomeFilteredDates

        return foundOutcome
      })
      return selectedOutcomes
    },

    ingredientsComboEntries() {
      return this.groupedIngredients.map(x => {
        return {text: x.comboText, value: x.name}
      })
    },

    outcomeComboEntries() {
      const comboEntries = this.groupedOutcomes.map(x => {
        return {text: x.comboText, value: x.name}
      })
      return comboEntries
    },

    toDateFormatted() {
      return this.toDate ? this.toDate.toFormat('d LLL kkkk') : ''
    },

    fromDateFormatted() {
      return this.fromDate ? this.fromDate.toFormat('d LLL kkkk') : ''
    },

    getSearchHistory() {
      return this.searchHistory
    },

    searchHistoryLength() {
      const len = this.searchHistory.length
      return len
    },

    userDisplayName() {
      const realName = this.patientRealName
      const displayName = realName === "" ? this.patientUsername : realName
      return displayName
    },

    numResults() {
      const res = this.numResultsProp ? this.numResultsProp : numResultsDefault
      return res
    },

    allTimezoneOffsets() {
      const all = TimezoneUtils.allTimezoneOffsets();
      const offsetsForDisplay = _.map(all, x => {
        return {
          text: `${x.offset}  ${x.name}`,
          value: [x.offset, x.name]
        }
      })
      return offsetsForDisplay
    },
  },

  filters: {

    /**
     * highlightSearchResults - searches through a string for a search terms. Any occurrences of any of the search
     * terms are wrapped in a <span> with highlighting.
     * The search is case-insensitive and matches all occurrences. White space is trimmed from the search terms
     *
     * eventType {string} - e.g. Lunch, Drink, Symptom, etc ... used to determine the color to highlight.
     *                      Outcome types ('Symptom','Bowel movement','Energy','Sleep quality') are coloured differently to input type
     * words {string} - the words in which to search
     * searchTerm {string} - a comma separated lists of words to search for
     */
    highlightSearchResults(eventType, words, searchTerms) {
      // highlightSearchResults(words, searchTerms) {
      let ret = ''

      // hightlight outcome events differently from input events
      const highlightColour = _.includes(['Symptom', 'Bowel Movement', 'Energy', 'Sleep Quality'], eventType) ? "rgba(255,60,0,0.31)" : "rgba(162,255,0,0.57)"

      if (searchTerms !== '') {

        // escape special regexp chars ..
        const escapedTerms = searchTerms.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')

        // parse the search terms and trim white space
        const searchTermsArray = escapedTerms.split(',').map(x => x.trim())

        // create an 'or' regex term
        const regEx = searchTermsArray.join('|')

        const re = new RegExp(regEx, "gi")
        ret = words.replace(re, (match) => `<span style="background-color:${highlightColour}">${match}</span>`
        )
      } else {
        ret = words
      }
      return ret
    }
  },

  methods: {

    getSortOrderStorageKey() {
      const sortByStorageKey = `diarySortOrder${this.$store.state.username}`
      return sortByStorageKey
    },

    // flip sort order
    changeSortOrderIcon() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      const sortOrderKey = this.getSortOrderStorageKey()
      localStorage.setItem(sortOrderKey, this.sortOrder)
      this.changeSortOrder()
    },

    changeSortOrder() {
      UiAnalyticsService.logUIAnalyticsEvent( `{name: diary_sort_order; client: ${this.patientUsername}; value: "${this.sortOrder}"}`);
      Loader.start()
      this.loadDiaryEntriesV2(this.$store.state.username, this.patientUsername, this.fromDate, this.toDate, this.sortOrder).then(() => {
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get diary entries for ${this.patientUsername}`), {})
      })
    },

    getTimezoneForChart() {
      const tz = this.displayTimezoneOffset ? this.displayTimezoneOffset[0] : ''
      return `UTC${tz}`
    },

    updateToAndFromDatesForTimeZoneChange(newTZ) {
      this.fromDate = this.fromDate.setZone(`UTC${newTZ}`, {keepLocalTime: true})
      this.toDate = this.toDate.setZone(`UTC${newTZ}`, {keepLocalTime: true})
    },

    truncateName(name) {
      // if the name is too long for the padding space, truncate it
      const truncatedName = name.substring(0, maxFoodNameLength)
      const truncatedNameWithEllipsis = truncatedName +
          ((truncatedName.length < name.length) ? "..." : "")
      return truncatedNameWithEllipsis
    },

    // toggleSortOrder() {
    //   if (this.sortOrder === 'asc') {
    //     this.sortOrder = 'desc'
    //   } else {
    //     this.sortOrder = 'asc'
    //   }
    // },

    // change between month to week
    changeViewPeriod() {
      this.fromDate = this.toDate.minus({days: this.viewPeriodInDays})

      Loader.start()
      this.loadDiaryEntriesV2(this.$store.state.username, this.patientUsername, this.fromDate, this.toDate, this.sortOrder).then(() => {
        // check to see if there were any more diary entries found ...
        this.updateComboEntries()
        this.updateChartSymptomDataSets()
        this.drawChart()
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get diary entries for ${this.patientUsername}`), {})
      })
    },

    // move the diary view back or forward a week or month
    moveViewWindow(numDays) {

      // first we need to check if there are more events in the direction the user wants to move
      this.fromDate = this.fromDate.plus({days: numDays})
      this.toDate = this.toDate.plus({days: numDays})

      Loader.start()
      this.loadDiaryEntriesV2(this.$store.state.username, this.patientUsername, this.fromDate, this.toDate, this.sortOrder).then(() => {
        // check to see if there were any more diary entries found ...
        this.updateComboEntries()
        this.updateChartSymptomDataSets()
        this.drawChart()
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get diary entries for ${this.patientUsername}`), {})
      })
    },

    // the combo text gets updates (e.g. nausea (3) -> nausea (4) ) when we move the view window, so we need to update
    // the already selected items
    updateComboEntries() {
      // for each of teh selected outcomes, we replace with the corresponding item from the comboEntries
      const newSelectedComboEntries = this.selectedOutcomeComboEntries.map(selectedComboEntry => {
        const found = this.outcomeComboEntries.find(e => e.value === selectedComboEntry.value)
        return found
      })

      this.selectedOutcomeComboEntries = newSelectedComboEntries

      const newSelectIngredientEntries = this.selectedIngredientComboEntries.map(selectedIngredientEntry => {
        const found = this.ingredientsComboEntries.find(i => i.value === selectedIngredientEntry.value)
        return found
      })
      this.selectedIngredientComboEntries = newSelectIngredientEntries
    },

    createChartDataSet(outcomeType, occurrence) {
      return {
        datasets: [
          {
            backgroundColor: '#f87979',
            data: occurrence.map(occurence => {
              return {
                x: occurence.start_time,
                y: occurence.intensity
              }
            })
          }
        ]
      }
    },

    onSearchHistoryItemSelected(idx) {
      this.searchText = this.searchHistory[idx]
      this.historyMenuOpen = false
    },

    // add the text to the search history. The text is added to the top. If the item is already in the search
    // history it is deleted from its current position and added again at the first position.
    // The length of the history is limited to 10
    addItemToSearchHistory() {
      const searchTerm = this.searchText
      if (searchTerm !== "" && searchTerm !== null) {
        // if this item already exists in the search history, remove it
        const idx = this.searchHistory.indexOf(searchTerm)
        if (idx > -1) {
          this.searchHistory.splice(idx, 1)
        }

        // add item to top of history
        this.searchHistory.unshift(searchTerm)

        // limit the number of items
        this.searchHistory = this.searchHistory.slice(0, 10)

        // write to storage so other tabs get the changes, and this tab reloads with the same values
        localStorage.setItem(localStorageHistoryKey, JSON.stringify(this.searchHistory))

        // register this as an event with Google Analytics
        const params = {event_label: searchTerm}
        this.$gtag.event('search', params)
      }
    }
    ,

    deleteSearchItem(idx) {
      this.searchHistory.splice(idx, 1)
      // write to storage so other tabs get the changes, and this tab reloads with the same values
      localStorage.setItem(localStorageHistoryKey, JSON.stringify(this.searchHistory))
    },

    getIconName(entryName, detail) {
      let image = '';

      // Outcomes: 'Symptom','Bowel movement','Energy','Sleep quality'
      // Events: 'Dinner','Snack','Lunch','Breakfast','Supplements','Drink','Medication','Stress','Period','Other'
      if (entryName === 'Symptom') {
        image = 'warning'
      } else if (entryName === 'Bowel Movement') {
        image = 'toilet'
      } else if (entryName === 'Energy') {
        image = 'battery'
      } else if (entryName === 'Sleep Quality') {
        image = 'sleep'
      } else if (entryName === 'Dinner' || entryName === 'Snack' || entryName === 'Lunch' || entryName === 'Breakfast') {
        image = 'food'
      } else if (entryName === 'Supplements') {
        image = 'vitamins'
      } else if (entryName === 'Drink') {
        image = 'drinks'
      } else if (entryName === 'Medication') {
        image = 'medicine'
      } else if (entryName === 'Stress') {
        image = 'cloud'
      } else if (entryName === 'Exercise') {
        image = 'runner'
      } else if (entryName === 'Environment') {
        image = 'globe'
      } else if (entryName === 'Period') {
        image = 'period'
      } else if (entryName === 'Mood') {
        // to get the right mood icon, need to map the inensity onto a 1 - 5 scale e.g. 1 -> 1, 3 -> 2, 5 -> 3, 7 -> 4
        // 9 -> 5
        const iconNum = Math.ceil(detail.symptoms[0].intensity / 2)

        image = 'mood' + iconNum
      } else if (entryName === 'Other') {
        image = 'other'
      } else {
        Logger.error(new VError(`unknown event type when selecting icon: ${entryName}.`), {})
        // this will result in a 'missing image' icon in the browser, hopefully alerting someone to report it to support
        // Chose not to log as an error as might result in a barrage of snack-bars
        image = 'not_found'
      }

      if (image !== '' && image !== 'not_found') {
        // https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
        return require(`../assets/diary/${image}.png`)
      }
    }
    ,

    /**
     * Formats the time component of the "HH:mm"
     *
     * @param {DateTime} time : a luxon date time
     * @returns {string}  in the format of "HH:mm"
     */
    formatTime(time) {
      const ret = time.toFormat("HH:mm")
      return ret
    }
    ,

    /**
     * Takes a iso string representation of a day e.g. '2020-07-01' and converts into Formats the day component of the
     * moment-timezone date to 'Thu 8 Nov 2018'
     *
     * @param {String} day - an iso string date
     * @param {String[]} timezoneOffset - timezone in which to display the date in the format e.g. "-4:00 EDT"
     * @returns {String} in the format of  Thu 8 Nov 2018
     */
    formatDayForDisplay(day, timezoneOffset) {
      const ret = DateTime.fromISO(day).setZone(`UTC${timezoneOffset[0]}`).toFormat("ccc d LLL yyyy")
      return ret
    },

    getTimezoneCookieKey(username) {
      return "timezone_" + username
    },

    onTimezoneUpdate() {
      const cookieKey = this.getTimezoneCookieKey(this.patientUsername)
      localStorage.setItem(cookieKey, this.displayTimezoneOffset)
      this.chartOptionsV2.scales.x.adapters.date.zone = this.getTimezoneForChart()
      this.updateToAndFromDatesForTimeZoneChange(this.displayTimezoneOffset[0])
      // need to delete the currently stored data so we can rewrite with new timezone
      Loader.start()
      this.diaryData = []
      this.flattenedIngredients = []
      this.flattenedOutcomes = []
      this.chartDataV2.datasets = []

      this.loadDiaryEntriesV2(this.$store.state.username, this.patientUsername, this.fromDate, this.toDate, this.sortOrder).then(() => {
        this.updateChartSymptomDataSets()
        this.drawChart()
        // this.updateChart()
        Loader.stop()
      }).catch(err => {
        Loader.stop()
        Logger.error(new VError(err, `failed to get diary entries for ${this.patientUsername}`), {})
      })
    },

    // returns a promise on a boolean - true if we found entries, false if not
    loadDiaryEntriesV2(webUsername, patientUsername, fromDate, toDate, sortOrder) {

      // e.g. the timezone to display the dates in as an offset from UTC "+02:00"
      const eventTimeOffset = this.displayTimezoneOffset[0]

      return PatientDiaryService.getDiaryV2(webUsername, patientUsername, fromDate, toDate, eventTimeOffset, sortOrder).then(res => {

        const ingredientEventsForCharts = res[0]

        const outcomeEventsForCharts = res[1]
        // event ingredient occurrences, grouped by ingredient name
        /*
        [
              {
                "eventTime": "2022-07-04T15:23:13.000+0000",
                "ingredient": {
                  "id": "4ffff46c-31dd-462a-a611-210c5b7e0e74",
                  "username": "dph002",
                  "name": "Eggs Sandwich",
                  "servingSizeDisplay": "",
                  "ingredients": [
                    {
                      "id": "3434c50b-67a3-42ef-a4b1-4aae51475467",
                      "username": "dph002",
                      "name": "Bread",
                      "servingSizeDisplay": "",
                      "ingredients": [
                        {
                          "id": "79d1daa5-44bb-4e84-ab85-8410d691d749",
                          "username": "dph002",
                          "name": "Wheat",
                          "servingSizeDisplay": "",
                          "ingredients": []
                        }
                      ]
                    }
                  ]
                }
              },
              {
                "eventTime": "2022-07-04T10:52:15.000+0000",
                "ingredient": {
                  "id": "4ffff46c-31dd-462a-a611-210c5b7e0e74",
                  "username": "dph002",
                  "name": "Dph test 3",
                  "servingSizeDisplay": "",
                  "ingredients": [
                    {
                      "id": "3434c50b-67a3-42ef-a4b1-4aae51475467",
                      "username": "dph002",
                      "name": "Dph test 2",
                      "servingSizeDisplay": "",
                      "ingredients": [
                        {
                          "id": "79d1daa5-44bb-4e84-ab85-8410d691d749",
                          "username": "dph002",
                          "name": "Dph test 1",
                          "servingSizeDisplay": "",
                          "ingredients": []
                        }
                      ]
                    }
                  ]
                }
              }
            ]
         */
        // add the new ingredients to the existing array, note we might have moved or expanded our time window
        // each time we do, add the ingredients to the existing ones - this enables us to keep the previous items in
        // the ingredients list. We need to check that we've not seen this ingredient before with this timestamp

        const newEventsToAdd = _.filter(ingredientEventsForCharts, ingredientEvent => {
          // search for this new item in the existing ingredients
          const res = _.find(this.flattenedIngredients, i => {
            return (i.eventTime.ts === ingredientEvent.eventTime.ts && i.ingredient.name === ingredientEvent.ingredient.name)
          })
          // if we don't find it, return true, so the filter includes it
          return res === undefined
        })
        this.flattenedIngredients = this.flattenedIngredients.concat(newEventsToAdd)

        // Logger.debug("flattened outcomes: " + this.flattenedOutcomes)
        const newOutcomesToAdd = _.filter(outcomeEventsForCharts, outcomeEvent => {
          // search for this new item in the existing outcomes
          const res = _.find(this.flattenedOutcomes, o => {
            return (o.startTime.ts === outcomeEvent.startTime.ts && o.outcomeName === outcomeEvent.outcomeName)
          })
          // if we don't find it, return true, so the filter includes it
          return res === undefined
        })
        this.flattenedOutcomes = this.flattenedOutcomes.concat(newOutcomesToAdd)

        const allEntriesForDiary = res[2]

        // group the events by day (we assume they are sorted by day) for the diary
        const allEntriesGroupedByDay = _.groupBy(allEntriesForDiary, getStartOfDay)

        this.diaryData = allEntriesGroupedByDay

        // let the calling function know we found diary entries
        return true;
      })
    },


    /**
     * on selecting an ingredient ...
     *  - check we've not exceeded the limit for max number selected
     *  - update the search text
     *  - if it's an addition:
     *    - assign a colour
     *  - if it's a deletion:
     *    - free up the colour
     */
    onSelectedIngredientsUpdated() {

      // if we've exceeded the limit, rollback to previous combo entries
      if (this.selectedIngredientComboEntries.length > maxNumberSelectedIngredients) {
        Logger.info("maximum number exceeded")
        this.selectedIngredientComboEntries = [...this.selectedIngredientComboEntriesPrevious]
      } else {
        // comboBox doesn't tell you which item was selected or deselected, so we need to work it out by comparing
        // with the history
        if (this.selectedIngredientComboEntries.length < this.selectedIngredientComboEntriesPrevious.length) {
          // an item has been removed ... find out which one by looping over the previous entires looking for one
          // that doesn't appear in the current list
          const removedItem = this.selectedIngredientComboEntriesPrevious.find(x =>
              this.selectedIngredientComboEntries.findIndex(y => x.value === y.value) === -1)
          const removedColour = this.eventColourMappings[removedItem.value]
          this.colours.push(removedColour) // add this colour to the end of the list to be used again later
          delete this.eventColourMappings[removedItem.value]
        } else {
          // an item has been added
          // assign the new item the next colour from the list
          const lastAdded = this.selectedIngredientComboEntries[this.selectedIngredientComboEntries.length - 1]
          this.eventColourMappings[lastAdded.value] = this.colours.shift()
        }

        // populate the search text from the selected ingredients and outcomes
        this.searchTextFromSelectedItems = _.map(this.selectedIngredientComboEntries, ing => ing.value)
            .concat(this.selectedOutcomeComboEntries.map(o => o.value))
            .join(',')

        // also log this to UI analytics
        UiAnalyticsService.logUIAnalyticsEvent(
            `{name: selected_items; client: ${this.patientUsername}; value: "${this.searchTextFromSelectedItems}"}`);

        this.drawChart()

        // keep a copy of the state of these combo entries for use later
        this.selectedIngredientComboEntriesPrevious = [...this.selectedIngredientComboEntries]
      }
    },

    onSelectedOutcomesUpdated() {
      //         chartDataV2: {
      //   datasets: [
      //     {
      //       "backgroundColor": "#f87979",
      //       "label": "headache",
      //       "data": [
      //         {
      //           "x": "2022-07-03T18:55:08.000Z",
      //           "y": 2
      //         },
      //         {
      //           "x": "2022-07-04T14:55:28.000Z",
      //           "y": 4
      //         }
      //       ]
      //     },

      // if we've exceeded the limit, rollback to previous combo entries
      if (this.selectedOutcomeComboEntries.length > maxNumberSelectedOutcomes) {
        Logger.info("maximum number exceeded")
        this.selectedOutcomeComboEntries = [...this.selectedOutcomeComboEntriesPrevious]
      } else {
        // comboBox doesn't tell you which item was selected or deselected, so we need to work it out by comparing
        // with the history
        if (this.selectedOutcomeComboEntries.length < this.selectedOutcomeComboEntriesPrevious.length) {
          // an item has been removed ... find out which one by looping over the previous entires looking for one
          // that doesn't appear in the current list
          const removedItem = this.selectedOutcomeComboEntriesPrevious
              .find(x => this.selectedOutcomeComboEntries.findIndex(y => x.value === y.value) === -1)
          const removedColour = this.outcomeColourMappings[removedItem.value]
          this.colours.push(removedColour) // add this colour to the end of the list to be used again later
          delete this.outcomeColourMappings[removedItem.value]
          const removedPointStyle = this.outcomePointStyleMappings[removedItem.value]
          this.pointStyles.push(removedPointStyle)
          delete this.outcomePointStyleMappings[removedItem.value]
        } else {
          // an item has been added
          // assign the new item the next colour from the list
          const lastAdded = this.selectedOutcomeComboEntries[this.selectedOutcomeComboEntries.length - 1]
          this.outcomeColourMappings[lastAdded.value] = this.colours.shift()
          this.outcomePointStyleMappings[lastAdded.value] = this.pointStyles.shift()
        }

        this.updateChartSymptomDataSets()

        this.updateChart()

        this.searchTextFromSelectedItems = _.map(this.selectedIngredientComboEntries, ing => ing.value)
            .concat(this.selectedOutcomeComboEntries.map(o => o.value)).join(',')

        // also log this to UI analytics
        UiAnalyticsService.logUIAnalyticsEvent(
            `{name: selected_items; client: ${this.patientUsername}; value: "${this.searchTextFromSelectedItems}"}`);

        // keep a copy of the state of these combo entries for use later
        this.selectedOutcomeComboEntriesPrevious = [...this.selectedOutcomeComboEntries]
      }
    },

    updateChartSymptomDataSets() {
      this.chartDataV2.datasets = _.map(this.selectedOutcomes, selectedOutcome => {
            const outcome = _.find(this.groupedOutcomes, x => x.name === selectedOutcome.name);
            const colour = this.outcomeColourMappings[selectedOutcome.name]
            const pointStyle = this.outcomePointStyleMappings[selectedOutcome.name]

            return {
              backgroundColor: colour,
              borderColour: colour,
              label: selectedOutcome.name,
              pointRadius: 5,
              pointStyle: pointStyle,
              data: _.map(outcome.item, o => {
                return {
                  x: o.startTime,
                  y: o.value
                }
              })
            }
          }
      )
    },

    updateChart() {
      const chart = Chart.getChart("theChartV2")
      chart.update();
    },

    drawChart() {

      const numOfFoods = this.selectedIngredients.length;
      const laneHeight = 40 // px
      const laneAreaPaddingTop = 40 // px
      const laneAreaPaddingBottom = 0
      const lanePaddingBottom = 3
      const lanePaddingTop = 3
      // const foodBarWidth = (this.viewPeriod === 'month') ? 5 : 0
      const foodBarHeight = laneHeight - lanePaddingTop - lanePaddingBottom
      const leftPadding = 160 // this is approximately calculated to be the enough screen space for maxFoodNameLength
      const textRightPadding = 3

      // calculate the amount of space needed for the food swimlanes ...
      const bottomPadding = numOfFoods > 0 ? laneAreaPaddingTop + (numOfFoods * laneHeight) + laneAreaPaddingBottom : 0
      this.chartOptionsV2.layout.padding.top = 0
      this.chartOptionsV2.layout.padding.bottom = bottomPadding

      let chartStatus = Chart.getChart("theChartV2"); // <canvas> id
      if (chartStatus !== undefined) {
        chartStatus.destroy();
      }

      const canvas = document.getElementById('theChartV2')
      const ctx = canvas.getContext('2d');
      const newHeight = this.originalParentDivHeight + bottomPadding
      canvas.parentNode.style.height = `${newHeight}px`

      // find the selected ingredient with the longest name ... note there's a bit of an approximation here: we find
      // the name with the greatest number of characters, if there are more than one with the longest number of
      // characters then arbitrarily take the first.
      // However, this might not be the one that takes up the most screen space ... we'll live with it.
      const longestName = _.reduce(this.selectedIngredients, (acc, x) => {
        const accLen = ctx.measureText(acc.name)
        const xLen = ctx.measureText(x.name)
        if (accLen.width < xLen.width) {
          return x
        } else {
          return acc
        }
      })
      // add left padding to the chart to make space on the canvas for the longest name
      let leftPaddingCalc
      if (longestName) {
        const truncatedName = this.truncateName(longestName.name)
        leftPaddingCalc = ctx.measureText(truncatedName).width // 20 is an approx calc of the distance to the origin
      } else {
        leftPaddingCalc = 0
      }
      // Logger.debug("longest name: " + (longestName ? longestName.name : "") + ", padding: " + leftPaddingCalc)
      this.chartOptionsV2.layout.padding.left = leftPaddingCalc

      const plugin = {
        id: 'custom_canvas_background_color',

        afterDraw: (chart) => {
          // Logger.debug("afterDraw")
          // left, bottom: coords of the origin of the chart in the larger canvas
          const {ctx, chartArea: {bottom, left, width}, canvas} = chart;

          ctx.save();
          const timeSpanOfXAxis = this.toDate.diff(this.fromDate, 'seconds')

          // create a swim lane for each ingredient
          this.selectedIngredients.forEach((ingredient, count) => {
            const foodItemName = ingredient.name

            // add grey background to swim lane
            ctx.fillStyle = 'rgba(240,240,240,0.5)' // grey
            const yTopOfSwimLane = bottom + laneAreaPaddingTop + (count * laneHeight)
            if (count % 2 === 1) {
              ctx.fillRect(0, yTopOfSwimLane, canvas.width + leftPadding, laneHeight)
            }

            // add the name of the food item to the start of the swim lane
            ctx.fillStyle = this.$vuetify.theme.on_primary
            const truncatedName = this.truncateName(foodItemName)
            // want to right justify the food names ..
            const nameTextWidth = ctx.measureText(truncatedName)
            const XStartCoord = left - nameTextWidth.width - textRightPadding
            // this.chartOptionsV2.layout.padding.left = XStartCoord
            ctx.fillText(truncatedName, XStartCoord, bottom + laneAreaPaddingTop + (count * laneHeight) + laneHeight / 2)

            ctx.fillStyle = this.eventColourMappings[foodItemName]
            // for each occurrence of the food, create a rectangle on the swim lane

            ingredient.eventTimes.forEach(eventTime => {
              const timeDiffFromX0ToPoint = eventTime.diff(this.fromDate, 'seconds')
              const timeDiffFromX0ToPointPerCent = timeDiffFromX0ToPoint.seconds / parseFloat(timeSpanOfXAxis.seconds)
              const XpixelOfPoint = left + (width * timeDiffFromX0ToPointPerCent)
              ctx.fillRect(XpixelOfPoint, yTopOfSwimLane + lanePaddingTop, this.foodBarWidth, foodBarHeight)
            })
          })
          // for each symptom point drop a line down through all the swim lanes if there are any events
          if (this.selectedIngredients.length > 0) {
            const metas = chart.getSortedVisibleDatasetMetas()
            metas.forEach(meta => {
              ctx.strokeStyle = 'red';
              ctx.setLineDash([1, 5])
              meta.data.forEach(point => {
                ctx.beginPath()
                ctx.lineTo(point.x, point.y)
                ctx.lineTo(point.x, bottom + laneAreaPaddingTop + (this.selectedIngredients.length * laneHeight))
                ctx.stroke()
              })
            })
          }
          ctx.restore();
        }
      }

      const myChartV2 = new Chart(ctx, {
        type: 'scatter',
        data: this.chartDataV2,
        options: this.chartOptionsV2,
        plugins: [plugin]
      })
      // myChartV2;
    },

    loadData() {
      Logger.debug("loading DiaryV2ComponentData");

      if (typeof this.patientUsername === 'undefined') {
        Logger.error(new VError("patientUsername undefined"), {})
      } else {

        // see if the patient's real name is set in the database, if not, look to see if we're storing it in local
        // storage
        // PatientDiaryService.getPatientInfo(this.$store.state.username, this.patientUsername).then(res => {
        // note we're passing in the username of the person logged in, not neccessarily the patient's clinician
        PatientDiaryService.getPatientInfo(this.$store.state.username, this.patientUsername).then(res => {
          if (res.data.hasOwnProperty('firstName') && res.data.firstName !== null) {
            // assume if firstName is set, so is lastName ... enforced at the database level
            this.patientRealName = res.data.firstName + " " + res.data.lastName
          } else {
            this.patientRealName = RealNameUtils.getRealNameFromLocalStorage(this.patientUsername);
          }
        }).catch(err => {
          Logger.error(new VError(err, `failed to get patient info for ${this.$store.state.username} / ${this.patientUsername}`), {})
          this.patientRealName = "<error>"
        })

        // for ths charts
        Loader.start()

        // set the date range as the week leading up to the last date in the user's diary
        PatientDiaryService.getLatestDiaryEntry(this.$store.state.username, this.patientUsername).then(res => {

          if (res.data !== "") {
            const lastDateInUtc = DateTime.fromISO(res.data)
            const lastDateInDisplayTZ = lastDateInUtc.setZone(`UTC${this.displayTimezoneOffset[0]}`)
            const toDateInDisplayTZ = lastDateInDisplayTZ.endOf('day')
            const fromDateInDisplayTZ = toDateInDisplayTZ.startOf('day').minus({days: (this.viewPeriodInDays - 1)})
            this.fromDate = fromDateInDisplayTZ
            this.toDate = toDateInDisplayTZ
            return this.loadDiaryEntriesV2(this.$store.state.username, this.patientUsername, this.fromDate, this.toDate, this.sortOrder)
          } else {
            // user doesn't have any diary entries so just return an empty promise, and set date range from today
            this.toDate = DateTime.now().endOf('day').setZone(`UTC${this.displayTimezoneOffset[0]}`)
            this.fromDate = this.toDate.startOf('day').plus({days: -(this.viewPeriodInDays - 1)})
            return Promise.resolve()
          }
        }).then(() => {
          // the diary entries have been stored in this.diaryData etc
          this.drawChart()

          // populate the event and outcome selectors with the top choices
          if (this.outcomeComboEntries.length > 0) {
            this.selectedOutcomeComboEntries = [this.outcomeComboEntries[0]]
            this.onSelectedOutcomesUpdated()
          }

          Loader.stop()
        }).catch(err => {
          Loader.stop()
          Logger.error(new VError(err, `failed to get diary entries or chart outcomes for ${this.patientUsername}`), {})
        })
      }

    },
  },

  getRandomInt() {
    return Math.floor(Math.random() * (50 - 5 + 1)) + 5
  },

  // note data is loaded by manually calling 'loadData()'
  mounted() {
    try {

      const ctx = document.getElementById('theChartV2')
      this.originalParentDivHeight = parseInt(ctx.parentNode.style.height)

      // timezone is stored in a cookie as a string in the format "-5:00,EST"
      const cookieKey = this.getTimezoneCookieKey(this.patientUsername)
      const retrievedTimezone = localStorage.getItem(cookieKey)

      // if it's set, split it into an array for use, else use the timezone from the local machine
      this.displayTimezoneOffset = retrievedTimezone ? _.split(retrievedTimezone, ',') : TimezoneUtils.currentOffset()

      this.chartOptionsV2.scales.x.adapters.date.zone = this.getTimezoneForChart();

      // initialise the search history from local storage if it exists
      const searchHistory = localStorage.getItem(localStorageHistoryKey)
      if (searchHistory !== null) {
        const searchHistoryParsed = JSON.parse(searchHistory)
        this.searchHistory = searchHistoryParsed
      }

      // listen for search history updates from other tabs
      window.addEventListener('storage', (e) => {
        if (e.key === localStorageHistoryKey) {
          const newHistory = JSON.parse(e.newValue)
          this.searchHistory = newHistory
        }
      })


      const sortOrderKey = this.getSortOrderStorageKey()
      const storedSortOrder = localStorage.getItem(sortOrderKey)
      this.sortOrder = storedSortOrder ? storedSortOrder : 'asc'

    } catch (err) {
      Logger.error(new VError(err), {})
    }
  }
  ,
  watch: {
    fromDate() {
      this.chartOptionsV2.scales.x.min = this.fromDate.toString()
    }
    ,

    toDate() {
      this.chartOptionsV2.scales.x.max = this.toDate.toString()
    }
  }
})
</script>

<style scoped>
/* Patient diary table */
.mysymptoms-patient-diary {
  width: 100%;
  margin-top: 20px;
}

.mysymptoms-patient-diary > thead > tr > th {
  padding: 5px;
  background-color: var(--v-primary-lighten1);
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
}

.mysymptoms-patient-diary > tbody > tr {
  border-width: 1px;
  border-style: solid;
  border-color: #dcdcdc;
}

.mysymptoms-patient-diary > tbody > tr > td {
  padding: 5px;
}

.mysymptoms-diary-time {
  width: 10%;
  vertical-align: top;
}

.mysymptoms-diary-event {
  width: 20%;
  vertical-align: top;
}

.mysymptoms-diary-content {
  width: 70%;
  vertical-align: top;
}
</style>

<style lang="stylus">
$color-pack = false

@import '~vuetify/src/stylus/main'
</style>

