
// a four digit number string used as an idea for this instance. Used to differentiate between log messages from
// different tabs and to identify message source in cross tab messaging.
// Although the id is not guaranteed to be unique, the chances and consequences of a clash are so small
// that it is OK fo our purposes

// NB implements the Singleton pattern https://www.dofactory.com/javascript/singleton-design-pattern
const TabId = (function () {

  let instance

  function createInstance() {
    const id = Math.random().toString().substring(2,6)
    return id
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    }
  };
})()

export default TabId
