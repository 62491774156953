<template>
  <div>
    <p>
      Scatter Chart
    </p>
    <!--    <ScatterChart-->
    <!--        :chartData="chartData1"-->
    <!--        :options="chartOptions1"-->
    <!--    />-->
    <!--    <ScatterChart-->
    <!--        ref="scatterRef"-->
    <!--        :chartData="chartData2"-->
    <!--        :options="chartOptions2"-->
    <!--        style="border:1px solid #000000;"-->
    <!--    />-->
    <ScatterChart
        ref="scatterRef"
        :chartData="chartData5"
        :options="chartOptions5"
        style="border:1px solid #000000;"
    />
    <!--    <LineChart-->
    <!--        :chartData="chartData3"-->
    <!--        :options="chartOptions3"-->
    <!--      />-->
  </div>
</template>

<script>
import {
  ScatterChart,
  // LineChart
} from 'vue-chart-3';

import {Chart, registerables} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation"
import 'chartjs-adapter-luxon';
import {DateTime, FixedOffsetZone, Interval} from "luxon";

Chart.register(annotationPlugin, ...registerables);

export default {
  name: "PatientDiary",
  props: ['patientUsername', 'numResultsProp'],
  components: {
    ScatterChart,
    // LineChart
  },

  data() {
    return {
      chartData1: {
        datasets: [{
          label: 'Scatter Dataset',
          data: [{
            x: -10,
            y: 0
          }, {
            x: 0,
            y: 10
          }, {
            x: 10,
            y: 5
          }, {
            x: 0.5,
            y: 5.5
          }],
          backgroundColor: 'rgb(255, 99, 132)'
        }]
      },
      chartOptions1: {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom'
          }
        }
      },
      chartData2: {
        datasets: [
          {
            "backgroundColor": "#f87979",
            "label": "my label",
            "data": [
              {
                "x": "2022-02-21T18:55:08.000Z",
                "y": 2
              },
              {
                "x": "2022-02-22T14:55:28.000Z",
                "y": 4
              }
            ]
          },
          {
            type: 'line',
            label: 'Line Dataset',
            data: [50, 50, 50, 50],
            fill: false,
            borderColor: 'rgb(54, 162, 235)'
          }
        ]
      },
      chartOptions2: {
        "maintainAspectRatio": false,
        "aspectRatio": 2,
        layout: {
          padding: {
            top: 0,
            bottom: 30,
          }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": false
          },
          annotation: {
            annotations: {
              box1: {
                // Indicates the type of annotation
                type: 'box',
                xMin: "2022-02-21T00:00:00Z",
                xMax: "2022-02-23T00:00:00Z",
                yMin: -2,
                yMax: -1,
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
              }
            }
          }
        },
        "legend": {
          "display": false
        },
        "scales": {
          "x": {
            "type": "time",
            "time": {
              "unit": "day",
              "displayFormats": {
                "day": "DD MMM"
              }
            },
            "min": "2022-02-20T00:00:00.000Z",
            "max": "2022-02-25T11:04:44.598Z"
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
          // annotation: {
          //   annotations: [
          //     {
          //       type: 'box',
          //       xMin: 1,
          //       xMax: 2,
          //       yMin: 50,
          //       yMax: 70,
          //       backgroundColor: 'rgba(255, 99, 132, 0.25)'
          //     }
          //   ]
          // }
        }
      },
      chartData3: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [{
          label: 'My First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      chartOptions3: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              box1: {
                // Indicates the type of annotation
                type: 'box',
                xMin: 1,
                xMax: 2,
                yMin: 50,
                yMax: 70,
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
              }
            }
          }
        }
      },
      chartData4: {
        datasets: [
          {
            "backgroundColor": "#f87979",
            "label": "my label",
            "data": [
              {
                "x": DateTime.now(),
                "y": 2
              }
            ]
          }
        ]
      },
      chartOptions4: {
        "maintainAspectRatio": false,
        "aspectRatio": 2,
        layout: {
          padding: {
            top: 0,
            bottom: 30,
          }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": false
          },
        },
        "legend": {
          "display": false
        },
        "scales": {
          "x": {
            "type": "time",
            "time": {
              "unit": "day",
              // "displayFormats": {
              //   "day": "DD MMM"
              // }
            },
            // "min": DateTime.now().toString(),
            "min": DateTime.now().minus({days: 2}).toString(),
            "max": DateTime.now().plus({days: 2}).toString()
            // "min": DateTime.fromISO('2022-07-20'),
            // "max": DateTime.fromISO('2022-07-29')
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
        },
      },
      chartData5: {
        datasets: [
          {
            "backgroundColor": "#f87979",
            "label": "my label",
            // data: [
            //   {
            //
            //   }
            // ]
            // "data": [
            //   {
            //     // "x": DateTime.now().setZone(`UTC-5:00`),
            //     // "x": DateTime.fromISO("2022-08-03T07:00:00.000-5:00"),
            //     "x": DateTime.fromISO("2022-08-03T07:00-5:00"),
            //     // 2022-07-30T00:00:00.000+01:00
            //     "y": 2
            //   }
            // ]
            data: this.getChartData5()
          }
        ]
      },
      chartOptions5: {
        "maintainAspectRatio": false,
        "aspectRatio": 2,
        layout: {
          padding: {
            top: 0,
            bottom: 30,
          }
        },
        "plugins": {
          "title": {
            "display": true,
            "text": "",
            "font": {
              "size": 14
            },
          },
          "legend": {
            "display": false
          },
        },
        "legend": {
          "display": false
        },
        "scales": {
          "x": {
            adapters: {
              date: {
                zone: 'UTC-12:00',
              },
            },
            type: "time",
            time: {
              "unit": "day",
              // "displayFormats": {
              //   "day": "DD MMM"
              // }
            },
            min: this.getChartMin(),
            max: this.getChartMax(),
            // "min": DateTime.now().setZone(`UTC-5:00`).startOf('day').minus({days: 2}).toString(),
            // "max": DateTime.now().setZone(`UTC-5:00`).endOf('day').plus({days: 2}).toString()
            // "min": DateTime.fromISO('2022-07-20'),
            // "max": DateTime.fromISO('2022-07-29')
          },
          "y": {
            "title": {
              "display": true,
              "text": "Intensity"
            },
            "min": 0,
            "max": 10,
            "ticks": {
              "stepSize": 1
            }
          },
        },
      },
    }
  },
  methods: {
    getChartMin() {
      const x = DateTime.fromISO("2022-08-01T00:00:00-12:00",{setZone: true}).toString()
      return x
    },
    getChartMax() {
      const x = DateTime.fromISO("2022-08-07T23:59:59-12:00",{setZone: true}).toString()
      return x
    },
    getChartData5() {
      // const x = DateTime.now().setZone(`UTC-5:00`)
      const x = DateTime.fromISO("2022-08-03T13:00:00-12:00",{setZone: true});

      return [
          {
            "x": x,
        //     // "x": DateTime.fromISO("2022-08-03T07:00:00.000-5:00"),
        //     "x": DateTime.fromISO("2022-08-03T07:00-5:00"),
        //     // 2022-07-30T00:00:00.000+01:00
            "y": 2
          }
      ]
    }
  }
      // mounted() {
      //   // const i = this.$refs.scatterRef
      //   // const image = this.$refs.scatterRef.chartInstance.toBase64Image()
      // }
}
</script>
