// ISO3166 country codes mapped to ISO 4217 currency codes
// at the moment we just have EU countries, UK, and US
export default {
  CountryToCurrency : {
    // AU: // 'Australia',
    AT: 'EUR', // 'Austria',
    BE: 'EUR', // 'Belgium',
    BG: 'BGN', // 'Bulgaria',
    // CA: // 'Canada',
    HR: 'HRK', // 'Croatia',
    CY: 'EUR', // 'Cyprus',
    CZ: 'CZK', // 'Czech Republic',
    DK: 'DKK', // 'Denmark',
    EE: 'EUR', // 'Estonia',
    FI: 'EUR',// 'Finland',
    FR: 'EUR',// 'France',
    DE: 'EUR',// 'Germany',
    GR: 'EUR',// 'Greece',
    HU: 'HUF', // 'Hungary',
    IE: 'EUR', // 'Ireland',
    IT: 'EUR', // 'Italy',
    LV: 'EUR', // 'Latvia',
    LT: 'EUR', // 'Lithuania',
    LU: 'EUR', // 'Luxembourg',
    MT: 'EUR', // 'Malta',
    NL: 'EUR', // 'Netherlands',
    PT: 'EUR', // 'Portugal',
    // RE: // 'Reunion',
    RO: 'RON', // 'Romania',
    SK: 'RON', // 'Slovakia',
    SI: 'RON', // 'Slovenia',
    ES: 'RON', // 'Spain',
    SE: 'SEK', // 'Sweden',
    GB: 'GBP',
    US: 'USD'
  }
}
