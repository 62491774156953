import Api from './api'

const privateAccountPath = '/private/account'
const publicAccountPath = '/public/account'
const publicResetPasswordRequestPath = `${publicAccountPath}/resetpasswordrequest`
const resetPasswordPathPublic = `${publicAccountPath}/resetpassword`
const policyDocsPathPart = 'policy_docs'

export default {

  /**
   *
   * @param {String} emailAddress
   */
  // reset password when not signed in
  resetPasswordRequestPublic(emailAddress) {
    const data = {
      email: emailAddress
    }

    return Api.post(publicResetPasswordRequestPath, data)
  },

  resetPasswordPublic(resetToken, newPassword) {
    const data = {
      token: resetToken,
      password: newPassword
    }
    return Api.post(resetPasswordPathPublic, data)
  },

  resetPasswordPrivate(username, newPassword, oldPassword) {
    const data = {
      newPassword: newPassword,
      oldPassword: oldPassword
    }
    const encodedUsername = encodeURIComponent(username)
    const resetPasswordPathPrivate = `${privateAccountPath}/${encodedUsername}/resetpassword`
    return Api.post(resetPasswordPathPrivate, data)
  },

  getAccountInfo(username) {
    const encodedUsername = encodeURIComponent(username) // in case there are any awkward characters in the username
    const path = `${privateAccountPath}/${encodedUsername}`
    return Api.get(path)
  },

  getPolicyDocsToAccept(clinicianUsername) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername)
    const path = `${privateAccountPath}/${encodedClinicianUsername}/${policyDocsPathPart}`
    return Api.get(path)
  },

  acceptPolicyDocs(clinicianUsername, docs) {
    const encodedClinicianUsername = encodeURIComponent(clinicianUsername)
    const path = `${privateAccountPath}/${encodedClinicianUsername}/${policyDocsPathPart}`
    return Api.put(path, docs)
  },

}
