<template>
  <div>
    <!-- pop-up to let user know they are about to close the payment window without completing -->
    <v-container fluid>
      <div class="text-xs-center">
        <v-dialog v-model="showPaymentClosedWarning" persistent width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>Close Payment Window
            </v-card-title>
            <v-card-text>
              You are about to close the payment window without completing the payment. Are you sure?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" flat @click="handlePaymentClosedWarningNo">No</v-btn>
              <v-btn color="primary" flat @click="handlePaymentClosedWarningYes">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>

<script>

import Logger from '../utils/logger-utils'
import Loader from "@/utils/loader-utils";
import PaddleUtils from "@/utils/paddle-utils"

export default {
  name: "PaddleCheckout",
  props: {
    showCheckout: {type: Boolean, required: true},
    username: {type: String, required: true},
    email: {type: String, required: false},
    country: {type: String, required: false},
    postcode: {type: String, required: false},
    paddleId: {type: Number, required: false,},
    planName: {type: String, required: false},
    referrerSuccessCallback: {type: Function, required: false},
    referrerCloseCallback: {type: Function, required: false},
    overrideURL: {type: String, required: false},
  },
  data:
      () => ({
        showPaymentClosedWarning: false,
      }),
  methods: {
    checkoutPremium(username, email, country, postcode, paddleId, planName, successCallback) {

      // this is a bit of kludge, but it seems to work ... the Paddle purchase page takes a second or two to open
      // let's run a loader while it's opening
      Loader.start()
      setTimeout(() => {
        Loader.stop()
      }, 2000)

      // use is moving from a free to premium plan, need to get them to enter their payment details
      const props = {
        product: paddleId,
        email: email,
        passthrough: `{"username": "${username}", "plan_name": "${planName}"}`,
        country: country,
        postcode: postcode,
        disableLogout: true,
        successCallback: successCallback,
        closeCallback: () => {
          this.showPaymentClosedWarning = true
        },
      }

      // if the override url is set, add it in to the props object
      if (this.overrideURL) {
        props.override = this.overrideURL
      }

      window.Paddle.Checkout.open(props)
      // Logger.debug("launched paddle checkout")
    },

    handlePaymentClosedWarningNo() {
      this.showPaymentClosedWarning = false
      // have another go ..
      this.checkoutPremium(this.username, this.email, this.country, this.postcode, this.paddleId, this.planName, this.successCallback)
    },

    successCallback() {
      this.referrerSuccessCallback()
    },

    handlePaymentClosedWarningYes() {
      this.showPaymentClosedWarning = false
      this.referrerCloseCallback()
    }
  },

  computed: {},

  created() {
  },

  watch: {
    showCheckout(newVal) {
      if (newVal) {
        this.checkoutPremium(this.username, this.email, this.country, this.postcode, this.paddleId, this.planName, this.successCallback)
      }
    }
  },
  mounted() {

    if (typeof Paddle !== 'undefined') {
      PaddleUtils.setupPaddle();
    } else {
      // needed to put this in because if a user navigates straight to http://localhost:8080/#/clinician/create
      // the Paddle setup hasn't yet been called in App.vue. Don't completely understand why, but this seems to fix it
      // Got inspiration from https://stackoverflow.com/questions/37098229/vue-js-how-to-call-function-after-page-loaded
      document.onreadystatechange = () => {
        if (document.readyState == "complete") {
          PaddleUtils.setupPaddle();
        }
      }
    }
  }
}

</script>
