import moment from "moment"
import {v4 as uuidv4} from "uuid"
import TabId from "../utils/tab-id";

export default {
  state: {
    username: '',
    password: '',
    displayName: '',
    authorities: [],
    plan: {},
    // hideYBugOnSensitivePages: true, // need to hide YBug for US customers on certain pages as it's not HIPAA compliant
    oauthPublicToken: '',// as received from the server
    oauthPrivateToken: '', // as received from the server
    oauthRefreshToken: '', // as received from the server
    oauthPrivateTokenExpiresIn: null, // relative number of seconds from sign in ... TBC can we remove?
    oauthPrivateTokenExpiresAt: null, // absolute time, calculated after sign in
    tokenRequestQueue: [], // first in first out

    // addId, tabId, and msgCnt as used to tag http requests as a debugging aid
    appId: uuidv4(), // used for debugging on the server, helps us identify all msgs come from this client
    // it's initialised here, but will be overwritten if we receive a state initialisation
    // message from other tabs that are already open.
    tabId: TabId.getInstance(), // if a tab is reloaded, it gets a new tabId
    msgCnt: 0, // this increments for each tabId .. reset to 0 when page reloads

    // indicating if the user signedIn on the front end. This is semi-independent of whether we are signed into the
    // server. When the user first signs in, the front end sign in status is contingent of successfully signing into the
    // server, but from them on that the server session may time out, but we stay signed in at the front end.
    // The front end session stays signed in until the user explicitly signs out or is forcibly sigend out after inactivity.
    isSignedIn: false,

    isInitialisedFromAnotherTab: false,
    // we store the pending patient requests in the store as there is a background thread that periodically checks for
    // updates and adds a msg in the side navbar
    pendingPatientInvitations: [],

    unreadNews: false,

    // for keeping track of whether the user is idle
    lastActivityTime: moment().toISOString(),

    // an 4 digit id used in broadcast messages to other tabs, used for debugging, there is a potential for value clashes
    // the chances and cost is so small that it's not worth worrying about
    // appId: Math.random().toString().substring(2, 6),
    // a counter for broadcast messages to other tabs, used for debugging
  },
  getters: {},
  /**
   * We use store.subscribe to monitor mutation events. Whenever a mutation occurs we broadcast the changes to
   * other tabs except for when the mutation occurs as a result of another tab broadcasting its state change.
   *
   * All mutations have same parameters:
   *
   * @param state
   * @param payload.value {any}
   * @param payload.broadcast [Boolean] - default true. If true broadcast the state change to other tabs.
   *
   *  Note that `broadcast` value is not used in the mutator directly but is used later in store.subscribe which is
   *  called on mutation events.
   */
  mutations: {

    // append an item to the end of the queue
    pushTokenRequestQueue(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.tokenRequestQueue.push(payload.value)
    },
    // remove the head of the queue
    shiftTokenRequestQueue(state) {
      state.tokenRequestQueue.shift()
    },
    setPrivateTokenInfo(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.oauthPrivateToken = payload.value.privateToken
      state.oauthRefreshToken = payload.value.refreshToken
      // state.oauthTokenExpires = payload.value.tokenExpiresAt,
      state.oauthPrivateTokenExpiresIn = payload.value.privateTokenExpiresIn
      state.oauthPrivateTokenExpiresAt = payload.value.privateTokenExpiresAt
      // state.tokenExpired = payload.value.tokenExpired
      // state.isSignedIn = payload.value.isSignedIn

      // set a timer to set tokenExpired flag. App.showTimeoutWarning is watching this field an prompts the user
      // to stay logged in.
      // if (payload.value.privateTokenExpiresAt !== '' ) {
      //   setTimerToExpireToken(payload.value.privateTokenExpiresAt)
      // }
    },
    clearPrivateTokenInfo(state) {
      state.oauthPrivateToken = ''
      state.oauthRefreshToken = ''
      state.oauthPrivateTokenExpiresIn = ''
      state.oauthPrivateTokenExpiresAt = ''
    },
    setPublicToken(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.oauthPublicToken = payload.value
    },
    // setTokenExpired(state, payload) {
    //   if (typeof payload.value === "undefined") {
    //     throw new Error("payload.value undefined")
    //   }
    //   state.tokenExpired = payload.value
    // },
    setUsername(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.username = payload.value
    },
    setPassword(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.password = payload.value
    },
    setIsInitialisedFromAnotherTab(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.isInitialisedFromAnotherTab = payload.value
    },
    setDisplayName(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.displayName = payload.value
    },
    // setHideYBugOnSensitivePages(state, payload) {
    //   if (typeof payload.value === "undefined") {
    //     throw new Error("payload.value undefined")
    //   }
    //   state.hideYBugOnSensitivePages = payload.value
    // },
    setPendingPatientInvitations(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.pendingPatientInvitations = payload.value
    },
    setAuthorities(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.authorities = payload.value
    },
    incMsgCnt(state) {
      state.msgCnt = state.msgCnt + 1
    },
    setLastActivityTime(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.lastActivityTime = payload.value
    },
    setIsSignedIn(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.isSignedIn = payload.value
    },
    setUnreadNews(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.unreadNews = payload.value
    },
    setPlan(state, payload) {
      if (typeof payload.value === "undefined") {
        throw new Error("payload.value undefined")
      }
      state.plan = payload.value
    },
  },
}
