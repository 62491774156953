<template>

    <v-layout>
        <!-- left padding to centre the sign-in form -->
        <v-flex xs1 md4></v-flex>

        <v-flex xs10 md4 class="mysymptoms-main-container">

            <h1>Email verification</h1>
            <p v-if="verificationInProgress">Please wait while your email is being verified.</p>
            <div v-if="!verificationInProgress && verificationOK">
                <p>Your account has been verified.</p>
                <p>Click here to
                    <router-link :to="{name: 'signin'}">Sign In</router-link>
                    .
                </p>
            </div>
            <div v-if="!verificationInProgress && !verificationOK">
                <p>Account verification has failed.</p>
                <p>Click here to
                    <router-link :to="{name: 'contact_us'}">Contact Support</router-link>
                    .
                </p>
            </div>
        </v-flex>
        <v-flex xs1 md4></v-flex>
    </v-layout>
</template>

<script>
  import ClinicianAccountService from '../utils/clinician-account-service'
  import Logger from '../utils/logger-utils'
  import * as HttpStatus from 'http-status-codes'
  import VError from 'verror'

  export default {
    name: "VerifyEmail",
    props: ['email', 'token'],
    data() {
      return {
        verificationInProgress: true,
        verificationOK: false
      }
    },
    created() {
      ClinicianAccountService.verifyEmail(this.email, this.token).then(() => {
        this.verificationInProgress = false
        this.verificationOK = true
      }).catch(err => {
          this.verificationInProgress = false
          this.verificationOK = false
          const cause = VError.cause(err)
          // Check for failure from either token being invalid so we give a custom err msg
          if (err.name === HttpStatus.NOT_FOUND.toString() && Boolean(cause.response.data.code === "INVALID_TOKEN")) {
            Logger.error("The link may have expired. Please try registering again.")
          } else {
            // for other errors log a stack trace
            Logger.error(err)
          }
        }
      )
    }
  }
</script>
