<template>
    <v-layout pt-3>
        <v-flex xs2 md3/>
        <v-flex xs8 md6 class="mysymptoms-main-container">
            <div>
                <h1>Support</h1>
                <p>If you can't find an answer to your question in <a
                        href="https://mysymptoms.zendesk.com/hc/en-us/categories/360002243960-mySymptoms-Clinic-User-Guide"
                        target="_blank">user guide</a>,
                    please email us at <a href="mailto:clinic@mysymptoms.net"
                                          target="_blank">clinic@mysymptoms.net</a> and we will get back to you as
                    soon as we can.</p>
                <p>The office is closed on UK public Holidays.</p>
            </div>
        </v-flex>
        <v-flex xs2 md3/>
    </v-layout>

</template>

<script>
  export default {
    name: "ContactUs"
  }
</script>

<style scoped>
    td {
        padding-right: 10px;
        padding-bottom: 5px;
        vertical-align: top;
    }
</style>
