<!--
    System wide logging utility for displaying logged messages.

    The component listens for error and info events on the message bus.

        EventBus.$on('logger-error-event', this.handleErrorEvent)
        EventBus.$on('logger-info-event', this.handleInfoEvent)

    The info event is expected to be a String and is displayed in a green Snackbar.

    The error event can be either a VError or String. For both VError and Strings the message is displayed in a red Snackbar.
    (Note VError is a class that allows you to chain Error's)

    For VError events the full stack trace (i.e. chained) is also displayed in the console.

    The Logger component is used in the App component.

    The info and error events are sent from Logger util in the partner file, logging-utils.js.
    See logger-utils.js for details on how to use.
-->
<template>
  <div>
  </div>
</template>

<script>
import {EventBus} from "../utils/event-bus.js"
import VError from 'verror'
import Vue from 'vue'

// https://github.com/shakee93/vue-toasted
const defaultConfig = {
  position: "top-center",
  duration: 4000,
  keepOnHover: true,
  action: {
    icon: 'close',
    class: 'my-toasted-action',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
}

// function getMsgId() {
//   return Math.random().toString(36).substr(2, 10)
// }

export default {
  name: "LoggerComponent",
  data() {
    return {}
  },
  methods: {
    /**
     * @param {VError|Anything else} error :
     *        VError - the result of a thrown error, log the message to the snack bar and display to full stacktrace in the
     *                    console.
     *                    Note: - the VError could be a chain of VErrors
     *                          - the VError could have an info object populated, at the discretion of the throwing func.
     *                            - all Errors are converted to VErrors before they are logged
     *        All other types - log the message to the snack bar only. In practice it would probably only make sense to send strings.
     */
    handleErrorEvent(error, opts) {
      let toastedMsg = ''

      if (error instanceof VError) {
        const fullStack = VError.fullStack(error)
        console.error(fullStack)
        const info = VError.info(error)
        console.error(info)
        toastedMsg = error.message;
      } else if (error instanceof Error) {
        toastedMsg = error.message;
        const fullStack = error.stack
        console.error(fullStack)
      } else {
        toastedMsg = error
        console.error(toastedMsg)
      }

      // log to google analytics - note I think we should be logging as an exception
      // but it didn't seem to work (DH)
      this.$gtag.event('log_error', {
        'event_category': 'error',
        'event_label': toastedMsg
      })

      let config = {};
      Object.assign(config, defaultConfig);

      if (typeof opts !== 'undefined') {
        // if (typeof opts.persistMsg !== 'undefined') {
        //   if (opts.persistMsg) {
        Object.assign(config, defaultConfig, opts)
        // }
        // }
      }
      this.$toasted.error(toastedMsg, config)
      // Vue.toasted.error(msg, config)
    },

    handleInfoEvent(msg) {
      this.$toasted.info(msg, defaultConfig)
    },
  },

  // on component creation, register the event handler
  created() {
    EventBus.$on('logger-error-event', this.handleErrorEvent)
    EventBus.$on('logger-info-event', this.handleInfoEvent)
    this.defaultConfig = defaultConfig; // used in unit testing
  },

  beforeDestroy() {
    EventBus.$off('logger-error-event')
    EventBus.$off('logger-info-event')

  }

}
</script>

<style scoped>

</style>
