import Api from './api'

const policyDocPathServer = '/public/policy_doc' // the server api end point
const policyDocPathLocal = '/policy_docs' // the webapp public folder for the policy doc content

export const CookiePolicyDocType = "cookie"
export const PrivacyPolicyDocType = "privacy"
export const TermsOfUseDocType = "terms"

export const policyDocService = {

  getCurrentPolicyDocMetaData(docType) {
    const encodedDocType = encodeURIComponent(docType)

    const path = `${policyDocPathServer}/current_policy_doc/${encodedDocType}`
    return Api.get(path)
  },

  getDocContent(docName) {
    const path = `${policyDocPathLocal}/${docName}`
    return Api.get(path)
  }
}
